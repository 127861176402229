// Mega Menu
.nav-mega-menu {
    display: flex;
    justify-content: center;
    background-color: white;
    position: absolute;
    display: flex;
    top: 110px;
    left: 0px;
    min-height: 294px;
    width: 100%;
    z-index: 6;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0.5s;
    padding: 0 4rem;
    box-shadow: 0 20px 25px 0px rgba(0, 0, 0, 0.2);

    .nav-mega-menu__container {
        display: flex;
        justify-content: space-between;
        max-width: 1092px;
        width: 100%;
        padding: 1rem 0 3rem;

        .nav-mega-menu__main {
            flex-basis: 66.67%;

            h4 {
                margin: 1rem 0 0.5rem;
                color: $truth-deep-purple;
                font-weight: 700;
                cursor: default;
                text-transform: uppercase;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0;
                    padding: 0;
                }

                a {
                    color: $truth-deep-purple;
                }
            }

            & > ul {
                display: flex;
                justify-content: flex-start;
                gap: 4rem;

                li {
                    margin: 0.2rem 0;
                }
            }
        }

        .nav-mega-menu__featured {
            flex-basis: 33.33%;
        }
    }
}

nav.open {
    // To accomodate Mega Menu hover behavior,
    // we need cms-links to grow to 100% height of header.
    .cms-link.has-children {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .nav-mega-menu {
        visibility: visible;
        opacity: 1;
        transition: all 0.2s;
        transition-delay: 0.2s;
    }
}
