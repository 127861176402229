/** Base container **/
.kalendae {
    display: inline-block;
    zoom: 1;
    *display: inline;
    background: $kalendae-bg;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    font-size: 11px;
    cursor: default;
    position: relative;
    width: 100%;

    @media only screen and (max-width: $screen-sm) {
        //min nav stacks, goes mobile
        left: 0 !important;
        .k-btn-close {
            top: 4px;
            right: 4px;
        }
    }

    @include breakpoint($screen-xs) {
        width: auto;
    }
}

/** Popup Container for Kalendae.Input **/
.kalendae.k-floating {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    margin: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
    width: 92%;

    @include breakpoint($screen-sm) {
        margin: 0;
        width: auto;
    }
}

/** Kalendae.Input's popup close button **/
.kalendae .k-btn-close {
    display: none;
}

/** Month Container **/
.kalendae .k-calendar {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
    width: 100%;

    @include breakpoint($screen-xs) {
        width: 48%;
    }

    @include breakpoint($screen-sm) {
        width: 155px;
    }
}

/** Month Separator **/
.kalendae .k-separator {
    display: inline-block;
    zoom: 1;
    *display: inline;
    width: 100%;
    vertical-align: top;
    background: $blackberry-80;
    height: 2px;

    @include breakpoint($screen-xs) {
        width: 2px;
        height: 155px;
        margin: 0px 10px;
    }
}

/** Month Title Row **/
.kalendae .k-title {
    text-align: center;
    white-space: nowrap;
    position: relative;
    height: 18px;
}

.kalendae .k-caption {
    font-size: 12px;
    line-height: 18px;
}

/** Month and Year Buttons **/
.kalendae .k-btn-previous-month,
.kalendae .k-btn-next-month,
.kalendae .k-btn-previous-year,
.kalendae .k-btn-next-year {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    top: -3px;
    color: $color-text;
    font-size: 26px;
    line-height: 18px;
    font-weight: 700;
    text-decoration: none;
}
.kalendae .k-btn-previous-year {
    left: 0;
}
.kalendae .k-btn-previous-month {
    left: 16px;
}
.kalendae .k-btn-next-month {
    right: 16px;
}
.kalendae .k-btn-next-year {
    right: 0;
}

.kalendae .k-btn-previous-month:after {
    content: "\2039";
}
.kalendae .k-btn-next-month:after {
    content: "\203A";
}

.kalendae .k-btn-previous-year:after {
    content: "\00AB";
}
.kalendae .k-btn-next-year:after {
    content: "\00BB";
}

.kalendae .k-btn-previous-month:hover,
.kalendae .k-btn-next-month:hover {
    color: $teal;
}

.kalendae .k-btn-previous-year:hover,
.kalendae .k-btn-next-year:hover {
    color: $teal;
}

/** Remove extra buttons when calendar shows multiple months **/
.kalendae .k-first-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-previous-month,
.kalendae .k-last-month .k-btn-previous-month,
.kalendae .k-first-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-previous-year,
.kalendae .k-last-month .k-btn-previous-year {
    display: none;
}

/** Disable year nav option **/
.kalendae .k-title.k-disable-year-nav .k-btn-next-year,
.kalendae .k-title.k-disable-year-nav .k-btn-previous-year {
    display: none;
}
.kalendae .k-title.k-disable-year-nav .k-btn-next-month {
    right: 0;
}
.kalendae .k-title.k-disable-year-nav .k-btn-previous-month {
    left: 0;
}

/** Force specific width for month container contents **/
.kalendae .k-title,
.kalendae .k-header,
.kalendae .k-days {
    display: block;
    overflow: hidden;
    width: 100%;

    @include breakpoint($screen-sm) {
        width: 154px;
    }
}

.kalendae .k-header {
    margin-left: 3%;

    @include breakpoint($screen-sm) {
        margin-left: 0;
    }
}

.kalendae .k-days {
    margin-left: 2%;

    @include breakpoint($screen-sm) {
        margin-left: 0;
    }
}

/** Hide unusable buttons **/
.kalendae.k-disable-next-month-btn .k-btn-next-month,
.kalendae.k-disable-previous-month-btn .k-btn-previous-month,
.kalendae.k-disable-next-year-btn .k-btn-next-year,
.kalendae.k-disable-previous-year-btn .k-btn-previous-year {
    display: none;
}

/** Week columns and day cells **/
.kalendae .k-header span,
.kalendae .k-days span {
    float: left;
    margin: 1px 1px;
}

.kalendae .k-header span {
    text-align: center;
    font-weight: 700;
    width: 13%;
    padding: 1px 0;
    color: $grayDark;

    @include breakpoint($screen-sm) {
        width: 20px;
    }
}

.kalendae .k-days span {
    text-align: right;
    width: 13%;
    height: 1.1em;
    line-height: 1em;
    padding: 1% 0.5%;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $grayMedium;

    @include breakpoint($screen-sm) {
        width: 20px;
        padding: 2px 3px 2px 2px;
    }
}

/** Today **/
.kalendae .k-today {
    text-decoration: underline;
}

/** Days inside of the month view **/
.kalendae .k-days span.k-in-month.k-active {
    border-color: $blackberry-80;
    background-color: $white;
    color: $grayDark2;
}
/** Days outside of the month view (before the first day of the month, after the last day of the month) **/
.kalendae .k-days span.k-out-of-month {
    color: $blackberry-80;
    font-weight: 700;
}

/** Selectable  **/
.kalendae .k-days span.k-active {
    cursor: pointer;
}

/** Selected day, when outside the selectable area **/
.kalendae .k-days span.k-selected {
    border-color: $smurf-blue;
    color: $smurf-blue;
}

/** Selected day, when inside the selectable area **/
.kalendae .k-days span.k-selected.k-active {
    background: $faded-blueberry;
    color: $white;
}

/** Days between the start and end points on a range, outside of the selectable area **/
.kalendae .k-days span.k-range {
    background: none;
    border-color: $kiddie-pool-cyan;
}

/** Days between the start and end points on a range, inside of the selectable area **/
.kalendae .k-days span.k-range.k-in-month {
    background: $washed-out-lavender;
    border-color: $electric-blue;
    color: $grayDark2;
}

/** Selectable day, hovered **/
.kalendae .k-days span.k-active:hover {
    border-color: $grayDark;
}

.kalendae {
    .k-days {
        span {
            //calendar numbers
            height: 27px;
            &.k-in-month.k-active.k-today {
                background: $teal;
                color: $white;
            }
            &.k-in-month.k-active.qdc-future-goldilocks {
                background: $blackberry-20;
                border: 1px solid $blackberry-80;
            }
            &.k-out-of-month.k-selected {
                border: 1px transparent solid;
                background-color: transparent;
                color: transparent;
                position: relative;
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
            }
            &.k-out-of-month.k-selected:after {
                content: "";
                background: url(/static/img/calendar-logo-truth.png);
                background-repeat: no-repeat;
                background-position-y: -3px;
                background-position-x: 0;
                background-size: 20px auto;
                color: transparent;
                opacity: 0.2;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
            }

            &.k-in-month.k-selected.k-active {
                color: transparent;
                background: url(/static/img/calendar-logo-truth.png);
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
                background-color: $blackberry-10;
                background-repeat: no-repeat;
                background-position-y: 3px;
                background-position-x: 0;
                background-size: 20px auto;
                border: 1px $green solid;
            }
        }
    }
}
