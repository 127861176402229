.main-footer {
    background-color: $site-footer-bg;
    text-align: center;
    padding: 1rem 0;

    @include breakpoint($screen-sm) {
        padding: 3rem 0;
    }

    p {
        color: $site-footer-text;
        font-size: $text-size-xs;

        span {
            display: block;
            margin-top: 0.25rem;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul.social-links {
        list-style: none;
        padding: 0;
        max-width: 400px;
        margin: auto;

        li {
            display: inline-block;
        }

        a {
            display: block;
            padding: 20px 10px;
            svg {
                width: 100%;
                height: auto;
                max-width: 50px;
                max-height: 50px;
                transition: fill 0.2s ease-in-out;
            }

            &:hover svg {
                fill: $teal;
            }
        }

        @include breakpoint($screen-sm) {
            a {
                padding: 20px;
            }
            a svg {
                max-width: 40px;
                max-height: 40px;
            }
        }
    }

    nav {
        padding-bottom: 0.5rem;
        ul {
            list-style: none;
            padding: 0;
            max-width: 720px;
            margin: auto;
        }

        li {
            @include breakpoint($screen-sm) {
                display: inline-block;
                padding: 0 1rem;
            }
        }

        a {
            width: 100%;
            display: block;
            padding: 0.75rem 0;
            margin-bottom: 0.25rem;
            color: $site-footer-text;
            font-size: $text-size-sm;
            font-weight: 700;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: $blackberry-80;
            }

            @include breakpoint($screen-sm) {
                display: inline;
                padding: 0;
                margin-bottom: 0;

                &:hover {
                    background-color: inherit;
                }
            }
        }
    }
}
/* old styles above can be removed when switching to redesign below */
.footer-content {
    max-width: 90rem;
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
}
.footer {
    background-color: $site-footer-bg;
    color: $color-text-light;
    letter-spacing: 0.8px;
    text-align: left;
    .footer-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding-bottom: 42px;
        padding-top: 54px;
        @include breakpoint($screen-sm) {
            flex-direction: row;
            align-items: center;
            .footer-credit-text {
                max-width: 288px;
            }
        }
    }
    .footer-logo-wrapper {
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: 2.5rem;
        @include breakpoint($screen-sm) {
            margin-bottom: 0;
            max-width: 546px;
        }
    }
    .footer-logo {
        fill: $color-text-light;
    }
    .footer-credit-text {
        line-height: normal;
        margin-bottom: 1.5rem;
    }
    .footer-cta-button {
        margin-bottom: 5px;
    }
    .footer-featured-links {
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: 2rem;
        @include breakpoint($screen-sm) {
            margin-bottom: 0;
            max-width: 520px;
        }
    }
    .footer-featured-links__link {
        color: $color-text-light;
        display: block;
        font-weight: 700;
        margin-bottom: 0.5rem;
        margin-left: 9px;
        margin-top: 1rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .footer-social-links {
        flex-grow: 1;
        flex-shrink: 0;
        @include breakpoint($screen-sm) {
            flex-grow: 0;
        }
    }
    .footer-social-links__subhead {
        font-size: $text-size-xl;
        font-weight: 600;
        margin-bottom: 27px;
    }
    .footer-social-links__icons {
        display: flex;

        justify-content: space-between;
        svg {
            fill: $color-text-light;
            height: 3rem;
            width: 3rem;
        }
    }
}
.sub-footer {
    background-color: $ex-black;
    color: $white;
    letter-spacing: 0.8px;
    text-align: left;
    .footer-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 30px;
        padding-top: 25px;
    }
    .sub-footer-links {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        @include breakpoint($screen-sm) {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 3rem;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
