#facebox[aria-describedby="check-in-container"] {
    width: 100%;
    @include breakpoint($screen-xs) {
        width: 32rem;
    }

    a.close {
        color: $color-text;
        height: 50px;
        width: 88px;
        padding-top: 15px;
        padding-left: 10px;
        svg {
            width: 14px;
            height: 14px;
            margin-bottom: 2px;
            use,
            path {
                stroke: black;
                stroke-width: 2px;
            }
        }
    }
    a.close:before {
        content: "Close";
        vertical-align: bottom;
        padding-right: 0.5em;
    }
}
#check-in-container {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    label {
        font-weight: 600;
        margin-bottom: 0px;
        padding-top: 10px;
    }
    .btn-group {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 1rem;
    }
    button {
        margin: 1.5rem 0;
    }
}
#facebox[aria-describedby="check-in-container"] table {
    border-top: $check-in-popup-border-top;
}
#check-in-body {
    font-weight: 600;
    font-size: 20px;
}
#check-in-title {
    font-weight: 700;
    font-size: 25px;
}
.check-in-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3ex;
    margin-top: -4px;
}
.check-in-icon-box {
    background-color: $truth-primary-variant;
    border-radius: 8em;
    height: 8em;
    width: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-check-in {
    height: 5em;
    width: 5em;
}
