progress {
    --completed: #{$blackberry};
    --remaining: #{$blackberry-20};

    -webkit-appearance: none;
    appearance: none;

    height: 0.3rem;
    width: 100%;
    background-color: var(--remaining);
    // clear border (for Firefox)
    border: none;
    margin-top: 1.5rem; // 24px
    margin-bottom: 1rem;
    @include breakpoint($screen-md) {
        margin-top: 2rem; // 32px
    }
}

progress::-webkit-progress-bar {
    background-color: var(--remaining);
}

// needed specifically for Firefox
progress::-moz-progress-bar {
    background-color: var(--completed);
}

progress::-webkit-progress-value {
    background-color: var(--completed);
}
