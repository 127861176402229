.secondary-nav-container {
    @extend .container;
}
.secondary-nav-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-left: -15px;
    @include breakpoint($screen-md) {
        margin-left: 4.5rem;
    }
}
.secondary-nav-item {
    height: 60px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $secondary-nav-text-color;
    fill: $secondary-nav-fill;
}
.secondary-nav-wrapper a {
    text-decoration: none;
    margin: 0 6px;
}
.secondary-nav-item span {
    font-size: 11px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
}
.secondary-nav-item:hover {
    color: $secondary-nav-active-color;
    box-shadow: inset 0 -2px 0 $secondary-nav-active-color;
    fill: $secondary-nav-active-color;
}
.secondary-nav-item.active {
    color: $secondary-nav-active-color;
    box-shadow: inset 0 -2px 0 $secondary-nav-active-color;
    fill: $secondary-nav-active-color;
}
.secondary-icon {
    display: block;
    margin: 0px auto;
    width: 25px;
    height: 25px;
}
@include breakpoint($screen-sm) {
    .secondary-nav-item {
        height: 40px;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        padding: 0 15px;
    }
    .secondary-nav-item span {
        font-size: 12px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
    }
    .secondary-icon {
        padding-left: 5px;
        order: 2;
    }
}
.secondary-nav-shadow {
    box-shadow: 0 2px 5px lightgrey;
}
