.overflow-hidden-mobile {
    overflow: hidden;

    @include breakpoint($screen-md) {
        overflow: initial;
    }
}

// General/Responsive
.navbar-unified {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    background-color: $nav-bg-color;
    overflow-y: auto;

    /* ---------- General/Responsive ---------- */

    // Only display mobile dropdown menu when .open & mobile screen
    @media (max-width: $screen-md) {
        &.open {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 5;
        }
    }

    .nav-flex-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: inherit;
        line-height: 30px;
        width: 100%;
        min-height: 0%;
        padding: 0 1rem;

        @media (min-width: $screen-sm) {
            padding: 0 4rem;
        }

        // Navigation toggle
        .nav-mobile {
            display: none;
        }

        // Only display mobile dropdown menu when .open & mobile screen
        @media (max-width: $screen-md) {
            &.mobile-open {
                height: 100%;
                .nav-mobile {
                    display: block;
                }
            }
        }

        // Display relevent content for mobile
        .nav-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: $nav-height-redesign;
            width: 100%;
            max-width: 1092px;

            .nav-right {
                .nav-links {
                    display: none;
                }
                .nav-end {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .ex-for-org,
                    .user-display,
                    .nav-search,
                    .logout {
                        display: none;
                    }
                }
                // .nav-button are only rendered when logged out
                // therefore .language-chooser is hidden only when logged out
                .nav-buttons ~ .language-chooser {
                    display: none;
                }
            }

            @include breakpoint($screen-md) {
                // Display relevant content for desktop
                .nav-right {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    height: 100%;

                    .nav-links {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;

                        a svg.nav-action-icon .desktop {
                            display: block;
                        }
                    }
                    .nav-end {
                        .ex-for-org,
                        .user-display,
                        .nav-search,
                        .nav-buttons,
                        .language-chooser,
                        .logout {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        // pushes .nav-end to the right
                        > div:first-of-type {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    /* ---------- Links ---------- */
    .cms-link,
    .block-link,
    .user-display,
    .language-chooser,
    .logout,
    .search-toggle,
    .sublink {
        margin-left: 16px;
        margin-right: 16px;
        white-space: nowrap;
        cursor: pointer;

        button,
        a {
            color: $nav-foreground-color;
            font-size: $nav-font-size;
            font-weight: 600;
            padding-top: 4px;
            border-bottom: 4px solid transparent;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;

            svg {
                fill: $nav-foreground-color;
                height: 24px;
                width: 24px;
            }
            svg.nav-external-link-icon {
                height: 14px;
                width: 14px;
                margin: 1px 0 0 6px;
            }

            svg.nav-action-icon {
                height: 12px;
                width: 12px;
                margin: 0 0 0 6px;

                .desktop,
                .mobile {
                    display: none;
                }
            }
        }

        &.active > a {
            border-bottom-color: $truth-purple-dark-10;
        }

        & > button:hover,
        & > a:hover,
        &.active-menu > a {
            border-bottom-color: $truth-primary;
        }
    }

    /* ---------- Logo ---------- */
    .ex-logo-white {
        .logo-truth {
            height: $nav-logo-height-mobile;
            width: $nav-logo-width-mobile;
            --ex-logo-primary: #{$nav-foreground-color};
            --ex-logo-secondary: #{$nav-foreground-color};
            @include breakpoint($screen-md) {
                height: $nav-logo-height-desktop;
                width: $nav-logo-width-desktop;
            }
        }
        @include breakpoint($screen-md) {
            margin-right: 1rem;
        }
    }

    /* ---------- Search ---------- */
    .search-box {
        position: relative;
        input[type="text"] {
            border: none !important;
            padding: 0.8125rem 3rem 0.8125rem 3rem !important;
            color: $nav-foreground-color;
            // covers google branding
            background: $blackberry-80 !important;
        }

        form {
            display: flex;
            align-items: center;
        }

        svg {
            fill: $nav-foreground-color;
            height: $svg-size;
            position: absolute;
        }
    }

    @include breakpoint($screen-md) {
        overflow-y: visible;

        .nav-search {
            display: flex;
            flex-direction: row-reverse;
        }
        .search-box {
            display: none;
            margin: auto 8px;

            input#search-box {
                height: 30px;
                line-height: 18px;
                padding: 0.5rem !important;
                background-image: none !important;
                outline: none;

                &::placeholder {
                    color: $nav-foreground-color !important;
                    font-size: $nav-font-size;
                }
            }

            &.active {
                display: block;
                flex-grow: 1;
                flex-basis: 2rem;
                max-width: 210px;

                input {
                    animation: 0.4s fadeIn;
                    animation-fill-mode: forwards;
                }
            }
        }

        .search-toggle {
            margin: 0;
            button {
                width: 32px;
                height: 38px;
                border-radius: unset;

                svg {
                    fill: $nav-foreground-color;
                }
            }
        }
    }

    // log-in/register btns
    .nav-buttons {
        display: flex;
        div {
            margin: 5px;
        }
    }

    /* ---------- Hamburger ---------- */
    .main-menu-toggle-btn {
        position: relative;
        float: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px;
        width: 50px;
        height: 50px !important;
        gap: 6px;
        max-height: 35px;
        max-width: 40px;
        // Pushes button right to align with nav-mobile content
        margin-right: -8px;

        .bar {
            display: block;
            border-radius: 2px;
            width: 100%;
            height: 0.3rem;
            left: 0.5rem;
            right: 0.5rem;
            background-color: $nav-foreground-color;
            transition: all 0.5s ease;
        }
        &.open {
            .bar:nth-child(1) {
                transform: rotate(45deg) translate(0.3rem, 0.3rem);
            }
            .bar:nth-child(2) {
                opacity: 0;
            }
            .bar:nth-child(3) {
                transform: rotate(-45deg) translate(0.4rem, -0.4rem);
            }
        }
        @include breakpoint($screen-md) {
            display: none;
        }
    }

    /* ---------- Mobile styles ---------- */
    @import "_components/navigation-mobile";

    /* ---------- Mega menu styles ---------- */
    @import "_components/navigation-mega-menu";
}
