.article-list {
    // kinda copied from home-section on homepage redesign
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: $desktop-max-width;

    &--padding-bottom {
        padding-bottom: 2.8rem;
    }
}

.card {
    &__type,
    &__author {
        font-size: $text-size-sm;
        font-weight: $font-weight-semi-bold;
        letter-spacing: 0.0875rem;
        text-transform: uppercase;
    }

    &__content {
        @include breakpoint($screen-sm) {
            display: flex;
            gap: 2rem;
            align-items: center;
        }
    }
}

.card--horizontal {
    background-color: $white;
    display: flex;
    border-radius: 1.25rem;
    border: 2px solid $truth-deep-purple;
    align-items: center;
    justify-content: space-between;
    transition: border-color ease-in-out 0.2s;
    max-width: 55rem;
    min-height: 11rem;

    h3,
    p {
        font-size: 1rem;
        margin: 0.5rem 0;
    }

    h3 {
        line-height: 1.25rem;
        font-weight: $font-weight-bold;
    }

    &:hover,
    &:active {
        border-color: $truth-primary;

        span.title {
            color: $truth-primary;
        }
    }

    a {
        display: flex;
        text-decoration: none;
        color: inherit;
        padding: 1.5rem;
        min-width: 21rem;
        flex: 0 0 100%;
        gap: 3rem;
        align-items: center;
    }

    .arrow {
        display: flex;
        margin-left: auto;
        padding-left: 0.8rem;
        align-items: center;

        svg,
        circle {
            fill: $truth-purple;
        }
    }
}
