// this is for some navigation buttons for scrolling to different sections on the same
// page
$height: 52px;

.local-navigation {
    .nav-container {
        @include container-fixed;

        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: $container-sm;
            width: 100%;
        }

        @media (min-width: $screen-md-min) {
            width: $container-md;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-lg;
        }
    }

    &:not(.fixedPosition) {
        margin-top: 2rem;
        margin-bottom: calc(-#{$height} + 1em);
    }

    button {
        flex-grow: 0;
        border: none;
        border-radius: 0;
        font-weight: 700;
        height: $height;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: center;
        transition: background-color 0.1s;

        &.current-location {
            background-color: $teal;
            opacity: 0.925;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $teal;
                opacity: 0.925;
            }
        }

        @include breakpoint($screen-sm) {
            flex-grow: 1;
            min-width: 30%;
        }

        @media (min-width: $screen-lg-min) {
            min-width: unset;
        }

        &.link-smooth-scroll {
            height: auto;
            min-height: 52px;
        }
    }

    @media only screen and (max-width: 768px) {
        position: static !important;
        height: auto;
        margin-bottom: 0 !important;

        button {
            display: block;
            width: 100%;
            margin: 0 auto 1em;
        }
    }
}
