// Mobile navigation styles
.language-chooser {
    .optional-text {
        display: none;
    }
}
.nav-mobile {
    overflow: hidden;
    width: 100%;

    div {
        margin: 0;
    }
    .search-box {
        margin: 16px 0px;
    }
    .log-in {
        margin-top: 32px;
        margin-bottom: 16px;
    }
    a {
        font-weight: 700;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
        }

        a {
            font-weight: 400;
        }
    }

    .cms-link a svg.nav-action-icon {
        height: 14px;
        width: 14px;
        margin: 0 0 0 6px;

        .mobile {
            display: block;
        }
    }

    .language-chooser a {
        display: inline-flex;
        gap: 8px;
        align-items: center;

        .optional-text {
            display: inline;
        }
    }

    .nav-mobile__drawer {
        display: flex;
        width: 200%;
        transition: all 0.2s;

        .nav-mobile__main {
            flex-basis: 100%;
        }

        .nav-mobile__side {
            height: calc(100vh - #{$nav-height-redesign});
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .nav-mobile__back-btn {
                a {
                    cursor: pointer;
                    border-bottom: 4px solid transparent;
                    text-decoration: none;
                    color: $sand;
                    display: inline-flex;
                    align-items: center;
                    font-weight: bold;
                    gap: 8px;

                    &:hover {
                        border-bottom-color: $truth-primary;
                    }

                    svg {
                        fill: $sand;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }

            .nav-mobile__subnav {
                overflow: auto;
                flex-grow: 1;

                h4 {
                    cursor: default;
                    color: $light-green;
                    margin: 16px 0 0 0;
                    font-size: $nav-font-size;
                    text-transform: uppercase;
                }
            }

            .nav-mobile__featured {
                // TODO: Placeholder for featured content styles
            }
        }

        &.open {
            transform: translateX(-50%);
        }
    }
}
