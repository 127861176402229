.brand-container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    max-width: fit-content;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    // overrides specifically for the mayo branding component to match design specs
    p {
        margin-top: 0px;
    }

    h2 {
        margin-bottom: 0.75rem;
    }

    &.custom-signup {
        h2 {
            font-size: $text-size-h1;
            color: $black;
        }
        @include breakpoint($screen-sm) {
            margin-left: 3.25rem;
        }
    }
    &.regular-signup {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.brand-container--column {
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.brand-container__logo {
    svg {
        width: 80px;
        height: 80px;
    }
}

.brand-container__text {
    flex: 1;
    padding-left: 1.125rem;
    max-width: 37.5rem;
}

.brand-container__text--column {
    max-width: 37.5rem;
}

#mayo-banner-section {
    background-color: $truth-purple;
    height: 120px;
    width: 100%;

    .hug {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: center;
        align-items: center;
    }
    svg {
        width: 80px;
        height: 80px;
        fill: $truth-deep-purple;
        margin-left: 30px;
    }
    p {
        font-size: $text-size-lg;
        font-weight: bold;
        padding-left: 30px;
    }

    @include breakpoint($screen-sm) {
        svg {
            margin-left: 0px;
        }
    }
}
