.icon-list-block {
    margin: 2rem auto 0 auto;
    padding: 1rem 0;
    background: $grayWhite;
    text-align: center;

    & + div.container {
        padding-top: 2rem;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    header {
        padding: 0 3rem;
    }
    footer {
        @include breakpoint($screen-md) {
            padding: 1rem 0;
        }
    }
    .icon-box {
        text-align: center;
        margin: 0 auto 0.5rem;
        max-width: 60%;
        font-weight: 700;

        svg {
            display: block;
            height: 110px;
            margin: 0 auto 0.5rem;
            max-width: 100%;
        }

        @include breakpoint($screen-md) {
            margin: 1rem 0;

            svg {
                //height: auto;
                margin: 0 auto 1rem;
            }
        }
    }
    .Wallop-pagination {
        top: 0;
        margin: 1rem 0;
    }

    .Wallop-list {
        @include clearfix;
        float: none;
    }
    .Wallop-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .icon-box {
            margin-left: 0;
            margin-right: 0;
        }

        @include breakpoint(max-width $screen-xs-max) {
            .block-icon_grid & {
                justify-content: center;
            }
        }
    }

    .cta-btn-wrapper {
        text-align: center;
    }
}

.icon-list-block-sidebar {
    @extend .icon-list-block;
    background: transparent;

    margin-top: 0;
    padding-top: 0;

    .icon-box {
        margin: 0 0 2rem;
    }
}

.icon-block {
    margin-bottom: 1rem;
    margin-top: 1rem;

    &:last-of-type {
        margin-bottom: 2rem;
    }

    .icon-feature {
        height: 130px;
        margin: 0 auto;
        width: 130px;
    }

    .icon-fill {
        text-align: center;
    }

    @include breakpoint($screen-xs) {
        .icon-feature {
            height: 160px;
            width: 160px;
        }
    }
    @include breakpoint($screen-sm) {
        display: flex;
        margin-bottom: 2rem;
        margin-top: 2rem;

        &:last-of-type {
            margin-bottom: 4rem;
        }

        .icon-fill {
            display: flex;
            flex-direction: column;
            justify-content: center;
            resize: vertical;
        }
        .icon-feature {
            height: 80%;
            min-height: 180px;
            width: 80%;
        }
    }
}
