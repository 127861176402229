/* ==========================================================================
Wallop Slider
========================================================================== */
.Wallop-pagination.dots {
    text-align: center;
    position: relative;
    top: -1rem;
    padding-bottom: 1rem;

    div {
        display: inline-block;
        margin: 0 0.25em;

        a {
            background-color: rgba($white, 0.2);
            border: 2px solid $white;
            border-radius: 50%;
            display: block;
            width: 0.85rem;
            height: 0.85rem;

            span {
                display: none;
            }
        }

        &.active a {
            background-color: $white;
        }
    }
    &.blue div {
        a {
            background-color: $white;
            border: 1px solid rgba($teal, 0.2);
        }
        &.active a {
            background-color: $teal;
        }
    }
    &.primary div {
        a {
            background-color: $wallops-pagination-dots-inactive;
            border: 1px solid rgba($truth-primary, 0.2);
        }
        &.active a {
            background-color: $truth-primary;
        }
    }
    &.yellow div {
        a {
            background-color: transparent;
            border: 1px solid rgba($teal, 0.4);
        }
        &.active a {
            background-color: $teal;
        }
    }
}
.Wallop-slider {
    @include breakpoint($screen-md) {
        padding: 0 2rem;
        position: relative;
    }
    a {
        color: $white;
        &:hover {
            text-decoration: underline;

            @include breakpoint($screen-md) {
                text-decoration: none;
            }
        }
    }

    .wallop-next-prev {
        display: none;
        position: absolute;
        top: 50%;
        margin-top: -1.25rem;
        left: 0;
        right: 0;

        button {
            width: 1.25rem;
            height: 2.5rem;

            &.icon-btn--round-primary {
                width: 2rem;
                height: 2rem;
                padding: 0 0.6875rem;

                svg {
                    fill: $white;
                }
            }
            &.Wallop-buttonNext {
                float: right;
                &.icon-btn--round-primary {
                    transform: translateX(50%);
                }
                svg {
                    margin-left: 2px;
                }
            }
            &.Wallop-buttonPrevious {
                &.icon-btn--round-primary {
                    transform: translateX(-50%);
                }
                svg {
                    margin-left: -2px;
                }
            }
        }
        @include breakpoint($screen-md) {
            display: block;
        }
    }
    &.icon-wrapper-slider {
        .dots {
            background-color: mix($white, $teal, 95%);
            padding: 2rem 0 2.5rem;
        }
        .icon-wrapper {
            margin-bottom: 0;
        }
    }
}
.Wallop-mobile {
    &:not(.initialized) {
        .dots {
            display: none;
        }
        .Wallop-item {
            visibility: visible;
            position: static;
        }
    }

    .initialized {
        .Wallop-item {
            width: 100%;
        }
    }
}
