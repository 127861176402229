body.homepage {
    background-color: $glowing-sand;

    #mainContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 1rem 0 1rem;

        @media (min-width: $screen-sm) {
            margin: 0 4rem 0 4rem;
        }
    }

    @mixin home-section {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 64px 0;
        width: 100%;
        max-width: $desktop-max-width;

        @include breakpoint($screen-md) {
            padding: 80px 0;
        }
    }

    .home-section {
        @include home-section;
    }

    .home-section--separation {
        @include home-section;
        border-bottom: 1.6px solid $truth-primary-variant;
        &.no-bottom-border {
            // we need to dynamically hide these sometimes
            border-bottom: inherit;
        }
    }

    h2 {
        color: $truth-primary-variant;
        font-size: 2.625rem;
        font-weight: 800;
        line-height: 130%;
        margin: 0;
    }

    h3 {
        color: $truth-primary-variant;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 130%;
        margin: 0;
    }

    .cta {
        max-width: $desktop-max-width;
        width: calc(100vw - 2rem);

        &--hero {
            margin: 0rem 1rem;
            margin-top: -2rem;
            position: relative;

            @include breakpoint($screen-md) {
                margin: 0 4rem;
                margin-top: -5rem;
            }
        }

        &--final {
            margin-bottom: 4rem;

            @include breakpoint($screen-md) {
                margin-bottom: 5rem;
            }
        }
    }
}
