.callout__container--bg-primary-alternate {
    background-color: $truth-primary-alternate;
    padding: 1rem;
    margin-bottom: 16px;
    border-radius: $border-radius-callout;
    color: $color-text-light;

    figure {
        text-align: center;
        margin-bottom: 0px;
        img {
            margin-bottom: 1rem;
        }
        figcaption {
            text-align: left;
            font-size: $text-size-sm;
            font-style: normal;
            margin-top: 0;
        }

        blockquote {
            color: $color-text-light;
            background-color: inherit;
            text-align: left;
            margin: 0;
            padding: 0;

            p {
                margin: 0;
                font-size: $text-size-sm;
                font-weight: 400;
                line-height: $line-height-base;
            }
        }
    }
}
