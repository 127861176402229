#hero-block-section {
    width: calc(100% + 2rem);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-sm) {
        width: calc(100% + 8rem);
    }
}

.hero-block-container {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 0;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(transparent, #321749b2);
    }

    @include breakpoint($screen-md) {
        background-size: 130% auto;

        &:after {
            width: 60%;
            height: 100%;
            background: linear-gradient(to left, transparent, #32174980);
        }
    }
}

.hero-block {
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    width: calc(100vw - 2rem);
    max-width: $desktop-max-width;
    h1 {
        color: $glowing-sand;
        font-size: $text-size-hero-lg;
        line-height: 100%;
        max-width: 800px;

        position: relative;
        z-index: 1;

        p {
            color: $glowing-sand;
            font-size: $text-size-lg;
            line-height: 130%;
        }
    }
    @include breakpoint($screen-md) {
        margin-bottom: 5rem;
        width: calc(100vw - 8rem);
        h1 {
            font-size: $text-size-hero-xl;
            line-height: 100%;
            max-width: 475px;
        }
    }
}
