.onboarding-page {
    margin-left: auto;

    .rich-text {
        display: flex;
        flex-direction: column;

        p {
            margin-top: 0;
        }
    }

    .richtext-image {
        &.right {
            align-self: flex-end;
        }

        &.full-width {
            width: 100%;
            align-self: stretch;
        }
    }

    .btn-container {
        margin: 2rem auto;
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }

    .form-wrapper {
        max-width: 369px;
        margin: auto;

        > img {
            display: block;
            margin: auto;
        }

        @include breakpoint($screen-sm) {
            max-width: 536px;
        }

        .field-wrapper > div {
            margin: auto;
            max-width: 325px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: normal;
            gap: 0.5rem;
        }

        &.has_gt5_choices .field-wrapper > div {
            @include breakpoint($screen-sm) {
                max-width: 536px;
            }
        }
    }

    // checkbox and radio button
    label {
        &:has(input[type="checkbox"]),
        &:has(input[type="radio"]) {
            position: relative;
            display: flex;
            vertical-align: middle;
            box-shadow: $md-elevation-2dp;
            border-radius: 4px;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-size: $text-size-sm;
            color: $ex-black;
            width: 156px;
            height: 100px;
            padding: 0 10px;
            font-weight: 400;
        }

        &:has(input[type="checkbox"]:checked),
        &:has(input[type="radio"]:checked) {
            background-color: $input-checked-color;
            color: $white;
        }

        // only apply this style on devices that hover
        @media (hover: hover) {
            &:has(input[type="checkbox"]:hover),
            &:has(input[type="radio"]:hover) {
                background-color: $input-checked-color;
                color: $white;
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        appearance: none;
        display: inline-block;
        position: absolute;
        text-align: center;
        border-radius: 12px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        right: 0.5rem;
        top: 0.5rem;
    }

    input[type="checkbox"]:checked:after,
    input[type="radio"]:checked:after {
        content: " ";
        background: transparent center center
            svg-url($icon-inline-check-material, $input-checked-color, "", 24, 24) no-repeat;
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
        color: $input-checked-color;
        background-color: white;
    }
}
