/* ==========================================================================
   Blockquotes and testimonials
   ========================================================================== */

blockquote {
    margin: 0;
    text-align: center;
    background-color: $blockquote-background-color;

    p {
        font-size: $text-size-lg;
        font-weight: 300;
        line-height: 1.75;
        margin-top: -1.5rem;
    }

    footer {
        font-size: $text-size-sm;
        text-transform: uppercase;
        margin-top: 30px;
    }

    img {
        border-radius: 50%;
        border: 1px solid $grayMedium;
        height: 40px;
        width: 40px;
        margin-right: 0.5rem;
    }

    @include breakpoint($screen-sm) {
        p {
            font-size: $text-size-xl;
            line-height: 1.5;
        }
    }

    &.icon-wrapper {
        padding-top: 8rem;
        color: $blockquote-text-color;

        &:before {
            content: "\201C";
            font-family: "Bookman Old Style", Bookman, "Goudy Old Style", Garamond, "Hoefler Text",
                "Bitstream Charter", Georgia, serif;
            font-size: 2.813rem;
            font-weight: 700;
            line-height: 1.1;
        }

        img {
            border: 2px solid $teal;
            background: $teal;
            height: 60px;
            left: 50%;
            margin-left: -30px;
            position: absolute;
            top: 2rem;
            width: 60px;
        }
    }
}

.sample {
    text-align: left;
    background-color: $blackberry-10;
    padding: 1rem;

    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin-top: inherit;
    }
}
