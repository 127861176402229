.quitdate__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    label {
        font-size: $text-size-md;
        color: $blackberry-text;
    }

    & img {
        height: 74px; // 4.625em
        width: 74px; // 4.625em
        align-self: center;

        @media (min-width: $screen-sm) {
            margin-bottom: 20px;
        }
    }
}

.quitdate__datepicker {
    // color for placeholder, before quit date is set
    color: $input-color-placeholder;
    width: 330px;
    height: 45px;
    font-size: 20px;
    // same padding as form control
    padding: 0.8125rem 0.8125rem;

    &::-webkit-calendar-picker-indicator {
        width: 24px;
        height: 26px;
        background-image: svg-url($icon-inline-event, $truth-primary-variant, "", 26, 24);
    }

    &.existing-date::-webkit-calendar-picker-indicator {
        width: 24px;
        height: 26px;
        background-image: svg-url($icon-inline-pencil, $quit-date-widget-text-color, "", 26, 24);
    }

    &.apple-webkit {
        input[type="date"]:invalid:after {
            color: #aaa;
            content: attr(placeholder);
        }

        &::after {
            width: 24px;
            height: 26px;
            content: svg-url($icon-inline-event, $truth-primary-variant, "", 26, 24);
        }

        &.existing-date::after {
            width: 24px;
            height: 26px;
            content: svg-url($icon-inline-pencil, $quit-date-widget-text-color, "", 26, 24);
        }
    }

    // make date input less wide on the version on the plan page
    &.plan-page {
        color: $quit-date-widget-text-color;
        width: auto;
        min-width: 170px;

        &.existing-date {
            border: none;
            background-color: $quit-date-widget-bg;
        }

        &,
        &:focus,
        &:active {
            border: $quit-date-border;
            border-radius: $border-radius-standard;
        }

        // sets color of 'placeholder' text in JS based on state
        .quitdate-set {
            color: $quit-date-widget-text-color;
        }
    }
}

// on the onboarding widget
.quitdate-set {
    color: $color-text;
}
