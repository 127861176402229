.icon-list-section {
    .rich-text {
        p {
            margin-top: 0;
        }
    }
}

.icon-list {
    display: flex;
    flex-direction: column; 
    gap: 2rem;
}

.icon-item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    .icon-item__image {
        display: flex;
        align-items: flex-start;
        img {
            width: 100px;
            min-height: 100px;
            @include breakpoint($screen-md) {
                width: 177px;
                min-height: 177px;
            }
        }
    }
    .icon-item__text {
        flex: 1;
    }

    @include breakpoint($screen-md) {
        gap: 4rem;
        align-items: center;
    }
}
