%video-wrapper {
    background-color: rgba($blackberry-10, 0.3);
    margin: 2rem 0;
    padding: 1rem;

    @include breakpoint($screen-sm) {
        padding: 1rem 2.5rem 2.5rem;
    }
}

%video-list {
    @include clearfix;

    list-style: none;
    margin: 0 -0.5rem;
    padding: 0;

    a {
        color: $teal;
        display: block;
        font-size: $text-size-sm;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
    }
    li {
        display: block;
        float: left;
        min-height: 195px;
        padding: 0.5rem 0.75rem 0 0.75rem;
        width: 50%;
    }
    img {
        display: block;
        height: auto;
        margin: 0 auto 0.375rem;
        width: 100%;
    }

    @include breakpoint(340px) {
        li {
            min-height: 220px;
        }
    }
    @include breakpoint($screen-xs) {
        li {
            min-height: 230px;
            padding: 0 0.5rem 1rem 0.5rem;
            width: 33.333333333%;
        }
    }
    @include breakpoint($screen-sm) {
        li {
            min-height: 215px;
            width: 25%;
        }
    }
    @include breakpoint($screen-md) {
        li {
            min-height: 225px;
            width: 20%;
        }
    }
}

.video-frame,
div.responsive-object {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin: 2rem auto 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.video-caption,
div.responsive-object + p i {
    @extend %caption;
}
