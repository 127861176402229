%default {
    display: flex;
    flex-direction: column;
    padding-left: 1rem; //16px
    padding-right: 1rem; //16px
    color: $heading-color-dark;

    //heading
    h1 {
        font-size: 2.375rem; //38px
        font-weight: $font-weight-medium;
        color: $heading-color-dark;
        line-height: 3rem; //48px
    }

    //subheading large
    h2 {
        color: $heading-color-dark;
        font-weight: $font-weight-medium;
        font-size: 1rem; //16px

        @include breakpoint($screen-md) {
            font-size: 1.25rem; //20px
        }
    }

    //subheading medium
    h3 {
        color: $heading-color-dark;
        font-weight: $font-weight-medium;
        font-size: 0.875rem; //14px

        @include breakpoint($screen-md) {
            font-size: 1rem; //16px
        }
    }

    p {
        font-weight: $font-weight-normal;
    }
}

.no-introduction {
    margin-bottom: 48px;
}

.hero-container {
    display: flex;
    flex-direction: column;
    background-color: $page-hero-bg-color;
    margin-bottom: 2rem; //32px

    &.light {
        background-color: $sand;
        h1 {
            color: $heading-color;
            margin: 2rem auto 1rem;
            font-size: $text-size-xxl;
        }
        p {
            font-size: $text-size-md;
            line-height: 1.25rem;
            color: $color-text;
            margin: 0 auto;
            max-width: 536px;
        }
    }
    @include breakpoint($screen-md) {
        flex-direction: row;
        margin-bottom: 3rem; //48px
    }

    &--content {
        @extend %default;

        @include breakpoint($screen-md) {
            width: 50%;

            div {
                width: 30.125rem; //482px
                margin: 0 auto 2.375rem auto; //38px
            }

            h1 {
                font-size: 3rem; //48px
                margin-top: 6.25rem; //100px
            }
        }
    }

    &--content--full-width {
        @extend %default;
        text-align: center;

        .rich-text {
            margin-bottom: 3.375rem; //54px
        }

        @include breakpoint($screen-md) {
            width: 73.125rem; //1170px
            margin: auto;

            h1 {
                font-size: 3rem; //48px
                line-height: 3.625rem;
                margin-top: 4.5rem; //72px
            }

            .rich-text {
                margin-bottom: 3.75rem; //60px
            }
        }
    }

    &--img-wrapper {
        height: 16.375rem;

        @include breakpoint($screen-md) {
            width: 50%;
            height: auto;
        }
    }

    &__breadcrumbs {
        margin-bottom: 3rem; //48px

        ul {
            display: flex;
            margin-left: 0;
            padding-left: 0;
            color: $white;
            font-size: 0.75rem;
            font-weight: $font-weight-normal;
        }

        li {
            display: none;
            color: $white;
            max-width: 18%;
            width: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-last-child(2) {
                display: inline-block;
                max-width: 50%;

                &:before {
                    content: "<";
                    margin-right: 0.2em; //3.2px
                }
            }

            a {
                text-decoration: none;
                color: $white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @include breakpoint($screen-sm) {
            width: 29.625rem; //474px
            margin: auto;

            ul {
                max-width: 75%;
                margin-top: 0.875rem; //14px
            }

            li {
                display: inline-block;
                overflow: hidden;

                &:nth-last-child(2) {
                    display: inline-block;
                    max-width: 18%;

                    &:before {
                        content: "/";
                    }
                }

                &:before {
                    content: "/";
                    color: $white;
                    margin-right: 0.2em; //3.2px
                }
                &:first-child:before {
                    content: none;
                }
                &:last-child {
                    color: $white;
                    max-width: 100%;
                }

                a {
                    text-decoration: none;
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__breadcrumbs--full-width {
        margin-bottom: 3rem; //48px

        ul {
            display: flex;
            margin-left: 0;
            padding-left: 0;
            color: $white;
            font-size: 0.75rem;
            font-weight: $font-weight-normal;
        }

        li {
            display: none;
            color: $white;
            max-width: 18%;
            width: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-last-child(2) {
                display: inline-block;
                max-width: 50%;

                &:before {
                    content: "<";
                    margin-right: 0.2em; //3.2px
                }
            }

            a {
                text-decoration: none;
                color: $white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @include breakpoint($screen-sm) {
            margin: 0;

            ul {
                max-width: 75%;
                margin-top: 0.875rem; //14px
            }

            li {
                display: inline-block;
                overflow: hidden;

                &:nth-last-child(2) {
                    display: inline-block;
                    max-width: 18%;

                    &:before {
                        content: "/";
                    }
                }

                &:before {
                    content: "/";
                    color: $white;
                    margin-right: 0.2em; //3.2px
                }
                &:first-child:before {
                    content: none;
                }
                &:last-child {
                    color: $white;
                    max-width: 100%;
                }

                a {
                    text-decoration: none;
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
