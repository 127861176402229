$activity-circle-width: 19px;
$activity-circle-margin: 8px;
$activity-circles-container-width: ($activity-circle-width + $activity-circle-margin + 4) * 5;

.points_program.complete_required_activities {
    // Hide bullets
    ul.progress_report,
    ul.activity_circles_container,
    li.activity_complete,
    li.activity_incomplete {
        list-style-type: none;
    }
    ul.progress_report,
    ul.activity_circles_container {
        padding-left: 0px;
    }
    li.activity_complete,
    li.activity_incomplete,
    li.activity_section {
        display: inline-block;
        width: 49%;
    }
    li.activity_section {
        float: left;
        padding-right: 16px;
    }
    li.activity_section p {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    li.activity_section h3 {
        margin-bottom: 8px;
    }
    ul.activity_circles_container {
        width: $activity-circles-container-width;
        margin-top: 8px;
    }
    li.activity_complete,
    li.activity_incomplete {
        width: $activity-circle-width;
        height: $activity-circle-width;
        opacity: 1;
        border-radius: 50%;
        margin-right: $activity-circle-margin;
        line-height: 2em;
    }
    li.activity_complete {
        background: $activity-circle-complete 0% 0% no-repeat padding-box;
    }
    li.activity_incomplete {
        background: $activity-circle-incomplete 0% 0% no-repeat padding-box;
    }
    .activity_heading a {
        color: $blackberry;
        margin-bottom: 8px;
        &::after {
            content: svg-url($icon-inline-arrow-forward, $blackberry, "", 20, 20);
            height: 20px;
            width: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
            margin-right: -40px;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .points_program.complete_required_activities {
        margin-left: 0px;
        li.activity_section {
            float: none;
            display: block;
            width: 100%;
        }
    }
}
