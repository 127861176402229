figure {
    margin: 0 0 1rem;
}

figcaption {
    color: inherit;
    // we dont use this font-style hardly anywhere, consider removing in the future so we don't have to override it
    font-style: italic;
    margin-top: 0.5rem;
}

.block-example_image {
    display: flex;
    justify-content: center;
}
