ul.warning-list {
    margin: 2rem 0 0;
    padding: 0;
    list-style: none;

    li {
        font-size: $text-size-lg;
        line-height: 1.5rem;
        font-weight: 300;
        padding-left: 40px;
        padding-bottom: 2rem;
        display: flex;

        img,
        svg {
            width: 100px;
            min-width: 100px;
            height: 80px;
            padding-right: 20px;
        }

        span {
            display: flex;
            align-items: center;
        }

        strong {
            font-weight: 300;
        }
    }

    @include breakpoint($screen-sm) {
        margin: 4rem 0 0;
        display: flex;
        flex-flow: row wrap;

        li {
            font-size: 1.25rem;
            line-height: 1.75rem;
            width: 50%;
            padding-left: 20px;
            padding-right: 20px;
            flex-direction: row;
            min-height: 100px;
        }
    }
}
