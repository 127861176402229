#trigger-slider {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @include breakpoint($screen-sm) {
        text-align: left;
    }

    li {
        display: inline-block;
        margin: 0;

        @include breakpoint(480px) {
            margin: 0.5rem;
        }
    }

    li span {
        float: left;
        display: block;
        width: 20px;
    }
}

#trigger-slider .trigger-container {
    position: relative;

    img {
        float: left;
    }

    h1 {
        background: $truth-tertiary;
        position: absolute;
        top: 143px;
        color: $white;
        width: 140px;
        height: 50px;
        font-weight: 400;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 0.5em 0;
        text-align: center;
        text-transform: uppercase;
        font-size: $text-size-sm;
        line-height: 1.25em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#trigger-slider li.has-trigger .doesnt-have-trigger-state {
    display: none;
}

#trigger-slider .has-trigger-state {
    display: none;
}

#trigger-slider li .doesnt-have-trigger-state {
    display: block;
    position: absolute;
    z-index: 44;
    width: 140px;
    height: 203px;

    button {
        min-width: 100%;
        height: 100%;
        border-radius: 10px;
        background: transparent right 10px top 10px svg-url($icon-inline-plus2, $white, "")
            no-repeat;
        transition: background-color 0.2s ease-in-out;
        border: none;
        font-size: 0;
        color: transparent;

        &:hover {
            background-color: rgba($teal-80, 0.5);
        }
    }
}

#trigger-slider li.has-trigger .has-trigger-state {
    display: block;
    position: absolute;
    z-index: 44;
    width: 140px;
    height: 203px;

    button {
        display: none;
    }

    a {
        display: block;
        width: 100%;
        height: 153px;
        background: rgba($teal-80, 0.8) center
            svg-url($icon-inline-check-material, $white, "", 70, 70) no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        transition: background 0.2s ease-in-out;

        &:hover {
            background: rgba($teal-80, 0.5) center
                svg-url($icon-inline-ui-error-mark, $white, "", 80, 80) no-repeat;

            &:before {
                content: "Remove";
                text-indent: 0;
            }
        }
    }
}
