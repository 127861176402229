.mobile-phone-login-page {
    width: 555px;
    max-width: 100%;

    #legal_birth_date {
        margin-bottom: 20px;
    }

    .main-color {
        p {
            color: $color-text;
        }
        color: $color-text;
    }
    #otp-form {
        > span.errorMsg {
            margin-top: 24px;
        }

        > div:nth-child(3) {
            margin-top: 24px;
        }

        // Phone Number label
        label[for="phone_number"] {
            margin-top: 32px;
        }
        // 'Update' link
        > a {
            padding-left: 20px;
        }
        // OTP-Token label
        label[for="otp_token"] {
            margin-top: 24px;
        }
        // Date of Birth label
        label[for="legal_birth_date"] {
            margin-top: 24px;
        }
    }
    .rich-text p {
        color: $color-text;
        margin: 25px 0px;
    }
    .btn {
        margin-top: 24px;
    }
}
