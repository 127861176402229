/* Global Variables */
:root {
    --font-family: "Jeko", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
        Arial, sans-serif;
}

/**
* ==================================================
*		Colors
* ==================================================
*/

/* Primary Brand Colors

Primary color should make up most of the color on a page
we have a couple options available, and also variations of
the color

We're making variations of the color by adding either 'tints'
'shades' (https://maketintsandshades.com/)

So a variation with 'light' is adding tint to a color, 'dark'
is adding a shade.

We're generally trying to follow material design guidelines:
https://m2.material.io/design/color/the-color-system.html#color-usage-and-palettes

But we have several different colors that have been given to us that
are still identified as potential 'primary' colors

'variant': a color that plays the same role but is a different shade (could be lighter or
darker but so far we've just had darker)

'alternate': a color that plays the same role but is just another option


*/
$truth-purple-light-80: #e9d3fc;
$truth-purple: #b56bf5;
$truth-primary: $truth-purple;
$truth-purple-dark-10: #9854d4;

$truth-deep-purple-light-80: #d6d1db;
$truth-deep-purple-light-70: #c2b9c8;
$truth-deep-purple-light-30: #705d80;
$truth-deep-purple-light-10: #5b456d;
$truth-deep-purple: #321749;
$truth-primary-variant: $truth-deep-purple;

$truth-teal-light-40: #6ac4c4;
$truth-teal: #079c9c;
$truth-primary-alternate: $truth-teal;
$truth-teal-dark-20: #067d7d; // potential for hyperlinks

$truth-teal-dark: #062828; // not currently intended for web use

// this is a primary color according to docs but can be thought of more as a
// background/surface color
$glowing-sand: #fff7e5;
$glowing-sand-dark-10: #bfb49e;

// Secondary Brand Colors
// thse should be used more sparingly as accents and colors, most often we use them for buttons
$truth-green: #d4ed43;
$truth-secondary: $truth-green;
$truth-green-dark-10: #bfd53c; // potential hover/focus for truth-green
$truth-orange: #ff7900;
$truth-secondary-alternate: $truth-orange;

// Tertiary Brand Color
$truth-teal-mid-light-20: #3d6565; // potential hover/focus for text only buttons
$truth-teal-mid: #0c3e3e;
$truth-tertiary: $truth-teal-mid;
$truth-lime-green: #d4ed43;
$truth-secondary-alternate-two: $truth-lime-green;
$truth-light-lime-green: #ddf169;

/* Color Swaps: here for some parts of the code that just have colors directly applied we 'swap' them out
for newer colors in the redesign
*/

$blackberry: $truth-deep-purple; // for color blocking and text
$teal: $truth-teal;
$truth-teal: $truth-teal; // redefined as new shade above
$dark-teal: $truth-teal;
$forest: $truth-tertiary; // secondary clickable elements
$sand: $glowing-sand; // sometimes background, sometimes text color
// truth-orange is unchanged
$green: $truth-green; // green aka truth green, accent color

// application/system colors

$activity-circle-complete: $truth-purple;
$activity-circle-incomplete: $truth-purple-light-80;
$activity-section-bg: $truth-tertiary;
$activity-section-star: $truth-secondary;

$link-color: $truth-teal-dark-20;
$truth-background-primary: $truth-deep-purple;

$color-text: $truth-deep-purple;
$color-text-light: $glowing-sand;

// blockquote
$blockquote-background-color: $truth-tertiary;
$blockquote-text-color: $color-text-light;

// buttons
$btn-height: 57px;
$btn-height-navigation: 50px;

$btn-primary-color: $truth-purple;
$btn-primary-hover-color: $truth-purple-dark-10;
$btn-primary-text-color: $glowing-sand;
$btn-primary-focus-color: $truth-deep-purple;

$btn-primary-alternative-color: $truth-primary-alternate;
$btn-primary-alternative-hover-color: $truth-teal-dark-20;
$btn-primary-alternative-text-color: $glowing-sand;
$btn-primary-alternative-focus-color: $truth-deep-purple;

$btn-primary-navigation-color: $truth-purple;
$btn-primary-navigation-hover-color: $truth-purple-dark-10;
$btn-primary-navigation-text-color: $truth-deep-purple;

$btn-secondary-color: transparent;
$btn-secondary-hover-color: $truth-deep-purple-light-10;
$btn-secondary-text-color: $truth-deep-purple;
$btn-secondary-border-color: $truth-deep-purple;

$btn-secondary-navigation-color: transparent;
$btn-secondary-navigation-hover-color: $truth-purple-light-80;
$btn-secondary-navigation-text-color: $glowing-sand;
$btn-secondary-navigation-border-color: $glowing-sand;

$btn-tertiary-color: $truth-secondary-alternate-two;
$btn-tertiary-hover-color: $truth-light-lime-green;
$btn-tertiary-text-color: $truth-deep-purple;

$btn-danger-color: $truth-deep-purple;
$btn-danger-hover-color: $truth-deep-purple-light-10;
$btn-danger-text-color: $glowing-sand;
$btn-danger-focus-color: $truth-purple;

$btn-text-color: transparent;
$btn-text-hover-color: $link-color;
$btn-text-text-color: $truth-deep-purple;
$btn-text-border-color: transparent;

$btn-text-danger-color: transparent;
$btn-text-danger-hover-color: $shouty-red;
$btn-text-danger-text-color: $truth-deep-purple;
$btn-text-danger-border-color: transparent;

$heading-color: $truth-deep-purple;
$heading-color-dark: $glowing-sand;

$color-success: $moss;

$input-checked-color: $truth-purple;
$input-color: $color-text;
$input-color-placeholder: $truth-deep-purple-light-70;

// Mobile CTA Form
$input-color-disabled: #9e9d9f;
$input-background-disabled: #ececec;

// Navigation
$nav-bg-color: $truth-primary-variant;
$nav-foreground-color: $glowing-sand;
$nav-font-size: $text-size-sm;
$primary-nav-active-color: $truth-teal-light-40;
$secondary-nav-text-color: $truth-tertiary;
$secondary-nav-fill: $truth-tertiary;
$secondary-nav-active-color: $truth-teal-mid-light-20;

// Registration
$reg-heading-text-color: $glowing-sand;
$reg-header-color: $truth-deep-purple;
$form-label-text: $truth-deep-purple-light-30;

// icons
$icon-box-fill: $truth-primary-variant;
$icon-box-list-background-color: $glowing-sand;

// widgets ( generally widgets can be thought of as user interactive, but this isn't perfect. We sometimes use
// these colors on non-interactive items too)
$widget-background-color-light: $glowing-sand;
$page-hero-bg-color: $truth-deep-purple;
$quit-date-widget-bg: $widget-background-color-light;
$quit-date-widget-text-color: $color-text;
// border radius for the quit date container
$quit-date-border-radius: 1.7rem;
$quit-date-border: 1.6px solid $truth-deep-purple;

$background-color-light: $glowing-sand;

$onboarding_splash_page_text_color: $color-text;
$onboarding_splash_page_bg_color: $background-color-light;

// quit band block and links
$quit-band-bg: $background-color-light;
$quit-band-heading-color: $color-text;
$quit-band-text-color: $color-text;
$quit-link-bg: $background-color-light;
$quit-link-border-color: $truth-deep-purple;
$quit-link-border: 1.6px solid $quit-link-border-color;
$quit-link-border-radius: 1.7rem;

// read more block
$read-more-background-color: $widget-background-color-light;
$read-more-link-background-color: $truth-primary-variant;
$read-more-link-background-color--hover: lighten($read-more-link-background-color, 10%);

// Tables

$zebra-row-line-background: $widget-background-color-light;

// Borders

$border-radius-rg: 1rem;

/* Responsive Navigation Header */
// Header height
$nav-height-redesign: 110px;
// Logo size
$nav-logo-height-mobile: 44px;
$nav-logo-height-desktop: 64px;
$nav-logo-width-mobile: 56px;
$nav-logo-width-desktop: 82px;

// Footer
$site-footer-bg: $truth-deep-purple;

// Vision and Reason
$vandr-bg-color: $sand;
$vandr-widget-empty-heading-mb: 14px;
$vandr-widget-empty-heading-mt: 0;
$vandr-widget-empty-border: 1.5px solid $truth-primary-variant;
$vandr-widget-empty-border-radius: 20px;
$vandr-widget-empty-pl-sm: 30px;
$vandr-widget-bg-color: $glowing-sand;
$vandr-widget-border: 1.5px solid $truth-primary-variant;
$vandr-widget-border-radius: 20px;
$vandr-widget-before-height: 58px;
$vandr-widget-before-margin-left: -29px;
$vandr-widget-before-top: -29px;
$vandr-widget-before-width: 58px;
$vandr-form-textarea-overflow: hidden;
$vandr-form-textarea-resize: none;

$ib-vision-margin-top: 3rem;
$ib-vision-before-bg: $truth-primary-alternate
    url("/static/explan-frontend/img/icons-color/compass.svg") 50% 50% no-repeat;
$ib-vision-before-bg-size: auto 50px;
$vision-textarea-border-color: $truth-primary-variant;
$vision-textarea-border-radius: 20px;
$vision-textarea-border-style: solid;
$vision-textarea-border-width: 1.5px;

$ib-reason-li-top: 2rem;
$ib-reason-margin-top: 0;
$ib-reason-before-bg: $truth-primary
    url("/static/explan-frontend/img/icons-color/lightbulb-color.svg") 50% 50% / auto 40px no-repeat;
$ib-reason-sliderbtn-margin: -3.5rem;

// Modals
$dialog-border-radius: 20px;
$popup-border-radius: $dialog-border-radius;
$popup-backdrop-opacity: 0.4 !important;

$check-in-popup-border-top: initial;

// Wallop Slider
$wallops-pagination-dots-inactive: transparent;
