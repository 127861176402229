.sponsored-users-cta,
.enterprise-cta {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    max-width: $desktop-max-width;
    padding: 3rem 1rem;
    width: 100%;
    @include breakpoint($screen-sm) {
        padding: 5rem 0;
    }
}

.sponsored-users-cta-text,
.enterprise-cta-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2rem;
    h3 {
        font-size: 1.875rem;
        font-weight: $font-weight-bold;
        line-height: 130%;
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 1.25rem;
    }
}

.sponsored-users-cta-image,
.enterprise-cta-image {
    display: none;
    flex: 1;
    @include breakpoint($screen-sm) {
        display: block;
    }
    img {
        border-radius: $border-radius-rg;
    }
}
