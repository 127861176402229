$slide_animation_transition-mobile: 500ms 250ms ease-in-out both;
$slide_animation_transition-desktop: 1500ms 250ms ease-in-out both;
/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/*
  This is the top-level selector

  It should be relative positioned
  to allow the children to be positioned absolutely
  relative to this
 */
.Wallop,
.Wallop-mobile {
    position: relative;
}

/*
  This element groups all the items, but not the buttons

  It's a recommendation but it's very likely
  you'll want to hide any overflow from the items
  Especially when doing animations such as scale
 */
.Wallop-list {
    position: relative;
    overflow: hidden;
    transition: height 0.5s;
}

/*
  This is the item element

  By default, they are all hidden and
  positioned absolute
  I recommend always having .Wallop-item--current
  in your markup by default (probably on the first element)
 */
.Wallop-item {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

/*
  This is the current item element

  All we do here, is make it visible again reset
  the position to static. Could also be relative
 */
.Wallop-item--current {
    visibility: visible;
    position: relative;
}
/**
 * wallop--slide.css
 *
 * @fileoverview Slide animation for wallop
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/* the 10ms animation-delay fixed some weird rendering issues with iPhone */
.Wallop--slide .Wallop-item--showPrevious {
    -webkit-animation: slideFromLeft $slide_animation_transition-mobile;
    -moz-animation: slideFromLeft $slide_animation_transition-mobile;
    -ms-animation: slideFromLeft $slide_animation_transition-mobile;
    animation: slideFromLeft $slide_animation_transition-mobile;

    @include breakpoint($screen-md) {
        -webkit-animation: slideFromLeft $slide_animation_transition-desktop;
        -moz-animation: slideFromLeft $slide_animation_transition-desktop;
        -ms-animation: slideFromLeft $slide_animation_transition-desktop;
        animation: slideFromLeft $slide_animation_transition-desktop;
    }
}

.Wallop--slide .Wallop-item--showNext {
    -webkit-animation: slideFromRight $slide_animation_transition-mobile;
    -moz-animation: slideFromRight $slide_animation_transition-mobile;
    -ms-animation: slideFromRight $slide_animation_transition-mobile;
    animation: slideFromRight $slide_animation_transition-mobile;

    @include breakpoint($screen-md) {
        -webkit-animation: slideFromRight $slide_animation_transition-desktop;
        -moz-animation: slideFromRight $slide_animation_transition-desktop;
        -ms-animation: slideFromRight $slide_animation_transition-desktop;
        animation: slideFromRight $slide_animation_transition-desktop;
    }
}

.Wallop--slide .Wallop-item--hidePrevious,
.Wallop--slide .Wallop-item--hideNext {
    visibility: visible;
}

.Wallop--slide .Wallop-item--hidePrevious {
    -webkit-animation: slideToLeft $slide_animation_transition-mobile;
    -moz-animation: slideToLeft $slide_animation_transition-mobile;
    -ms-animation: slideToLeft $slide_animation_transition-mobile;
    animation: slideToLeft $slide_animation_transition-mobile;

    @include breakpoint($screen-md) {
        -webkit-animation: slideToLeft $slide_animation_transition-desktop;
        -moz-animation: slideToLeft $slide_animation_transition-desktop;
        -ms-animation: slideToLeft $slide_animation_transition-desktop;
        animation: slideToLeft $slide_animation_transition-desktop;
    }
}

.Wallop--slide .Wallop-item--hideNext {
    -webkit-animation: slideToRight $slide_animation_transition-mobile;
    -moz-animation: slideToRight $slide_animation_transition-mobile;
    -ms-animation: slideToRight $slide_animation_transition-mobile;
    animation: slideToRight $slide_animation_transition-mobile;

    @include breakpoint($screen-md) {
        -webkit-animation: slideToRight $slide_animation_transition-desktop;
        -moz-animation: slideToRight $slide_animation_transition-desktop;
        -ms-animation: slideToRight $slide_animation_transition-desktop;
        animation: slideToRight $slide_animation_transition-desktop;
    }
}

@-webkit-keyframes slideFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-moz-keyframes slideFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-ms-keyframes slideFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideFromLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-webkit-keyframes slideFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@-moz-keyframes slideFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@-ms-keyframes slideFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideFromRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@-webkit-keyframes slideToLeft {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-moz-keyframes slideToLeft {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-ms-keyframes slideToLeft {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideToLeft {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
        -ms-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@-webkit-keyframes slideToRight {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@-moz-keyframes slideToRight {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@-ms-keyframes slideToRight {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideToRight {
    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

/**
 * wallop--vertical-slide.css
 *
 * @fileoverview Verical slide animation for wallop
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/* the 10ms animation-delay fixed some weird rendering issues with iPhone */
.Wallop--vertical-slide .Wallop-list {
    position: relative;
    overflow: hidden;
}

.Wallop--vertical-slide .Wallop-item--showNext {
    -webkit-animation: slideFromTop $slide_animation_transition-mobile;
    -moz-animation: slideFromTop $slide_animation_transition-mobile;
    -ms-animation: slideFromTop $slide_animation_transition-mobile;
    animation: slideFromTop $slide_animation_transition-mobile;
}

.Wallop--vertical-slide .Wallop-item--showPrevious {
    -webkit-animation: slideFromBottom $slide_animation_transition-mobile;
    -moz-animation: slideFromBottom $slide_animation_transition-mobile;
    -ms-animation: slideFromBottom $slide_animation_transition-mobile;
    animation: slideFromBottom $slide_animation_transition-mobile;
}

.Wallop--vertical-slide .Wallop-item--hidePrevious,
.Wallop--vertical-slide .Wallop-item--hideNext {
    visibility: visible;
}

.Wallop--vertical-slide .Wallop-item--hidePrevious {
    -webkit-animation: slideToBottom $slide_animation_transition-mobile;
    -moz-animation: slideToBottom $slide_animation_transition-mobile;
    -ms-animation: slideToBottom $slide_animation_transition-mobile;
    animation: slideToBottom $slide_animation_transition-mobile;
}

.Wallop--vertical-slide .Wallop-item--hideNext {
    -webkit-animation: slideToTop $slide_animation_transition-mobile;
    -moz-animation: slideToTop $slide_animation_transition-mobile;
    -ms-animation: slideToTop $slide_animation_transition-mobile;
    animation: slideToTop $slide_animation_transition-mobile;
}

@-webkit-keyframes slideFromTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-moz-keyframes slideFromTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-ms-keyframes slideFromTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slideFromTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@-webkit-keyframes slideFromBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-moz-keyframes slideFromBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-ms-keyframes slideFromBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideFromBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-webkit-keyframes slideToTop {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
}

@-moz-keyframes slideToTop {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
}

@-ms-keyframes slideToTop {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
}

@keyframes slideToTop {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
}

@-webkit-keyframes slideToBottom {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}

@-moz-keyframes slideToBottom {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}

@-ms-keyframes slideToBottom {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}

@keyframes slideToBottom {
    99% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}

/**
 * wallop--fade.css
 *
 * @fileoverview Fade animation for wallop
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

.Wallop--fade .Wallop-item--hidePrevious,
.Wallop--fade .Wallop-item--hideNext {
    visibility: visible;
    -webkit-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    -moz-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    -ms-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/*
  In order to fade out properly we need to make sure
  that the item that is going to be the previous one
  has a higer z-index that the next one
 */
.Wallop--fade .Wallop-item--hidePrevious,
.Wallop--fade .Wallop-item--hideNext {
    z-index: 2;
}
.Wallop--fade .Wallop-item--showPrevious,
.Wallop--fade .Wallop-item--showNext {
    z-index: 1;
}

/*==========  FADE ANIMATIONS  ==========*/
@-webkit-keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@-moz-keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@-ms-keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
