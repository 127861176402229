// Open Sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i");

@font-face {
    font-family: "Jeko";
    src:
        url("/static/fonts/JekoVariable[wght].woff2") format("woff2 supports variations"),
        url("/static/fonts/JekoVariable[wght].woff2") format("woff2-variations");
    font-weight: 100 1000;
    font-display: swap;
}

@font-face {
    font-family: "Jeko";
    src:
        url("/static/fonts/JekoVariableItalic[wght].woff2") format("woff2 supports variations"),
        url("/static/fonts/JekoVariableItalic[wght].woff2") format("woff2-variations");
    font-weight: 100 1000;
    font-style: italic;
    font-display: swap;
}
