// Forms
// ==========================================================================
form {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

// note: using a placeholder here so we can extend in reskin_legacy
// a workaround because we cannot extend nested selectors
%form-check-inline-li {
    display: inline-block;

    @include breakpoint($screen-sm) {
        margin-right: 1rem;
    }
}

ul.form-check-inline {
    li {
        @extend %form-check-inline-li;
    }
}

%form-control {
    display: block;
    width: 100%;
    padding: 0.8125rem 0.8125rem;
    font-size: $text-size-md;
    line-height: 1.35;
    background-color: $input-bg;
    color: $input-color;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-standard;
    transition: all 0.1s linear;

    &:focus {
        border-color: $input-focus-border-color;
    }

    &:disabled,
    &.inactive {
        background-color: $color-disabled;
        border-color: $color-border-disabled;
        box-shadow: none;
        color: $input-color;
        pointer-events: none;
        opacity: 0.5;

        &:hover,
        &:active,
        &:focus {
            background-color: $color-disabled;
            border-color: $color-border-disabled;
        }
    }
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $input-color-placeholder;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $input-color-placeholder;
    opacity: 1;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $input-color-placeholder;
    opacity: 1;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $input-color-placeholder;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $input-color-placeholder;
}

.form-group {
    margin-bottom: 1rem;
}

/* Input fields */

input[type="text"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="email"],
textarea {
    @extend %form-control;
}

/* Select/Dropdowns */

select {
    @extend %form-control;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
        display: none;
    }
    background: $input-bg right 10px center svg-url($icon-inline-drop-down-arrow, $truth-primary)
        no-repeat;
}

// Radios and Checkboxes
// ==========================================================================

input[type="radio"],
input[type="checkbox"] {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap; /* https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe */
}

// temp reset for styles on onboarding form
input[name="quit_journey_status"],
input[name="secondary_products"],
input[name="flavors"] {
    position: initial;
    top: initial;
    overflow: initial;
    clip: initial;
    width: initial;
    height: initial;
    white-space: initial;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
    font-weight: 400;
    font-size: $text-size-md;
    display: block;
    padding: 0.75em;
    padding-left: 3.5em;
    border: 1px solid $blackberry-10;
    width: 100%;

    @include breakpoint($screen-sm) {
        max-width: calc(100% - 2em);
        border: none;
        width: auto;
        padding-left: 2em;
    }
}

fieldset > div input[type="radio"] + label {
    margin: -1em 0;
}

input[type="radio"]:hover + label:before,
input[type="checkbox"]:hover + label:before,
input[type="radio"]:focus + label:before,
input[type="checkbox"]:focus + label:before {
    border-color: $input-checked-color;
}

input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
    content: "";
    border: 1px solid $input-border-color;
    border-radius: $border-radius-standard;
    background-color: $input-bg;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 1.75em;
    height: 1.75em;
    margin-left: -2.5em;
    margin-top: -0.25em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 0.1s ease-out;

    @include breakpoint($screen-sm) {
        margin-left: -1.5em;
        margin-top: 0.15em;
        width: 1.25em;
        height: 1.25em;
    }
}

input[type="radio"] + label:before {
    border-radius: 100%;
}

input[type="radio"]:disabled + label:before,
input[type="checkbox"]:disabled + label:before {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
    color: $sheetrock-gray;
}

// boxshadow inset is used here to make it look like a radio button
input[type="radio"]:checked + label:before {
    background-color: $input-checked-color;
    border-color: $input-checked-color;
    box-shadow: inset 0 0 0 0.15em rgba(255, 255, 255, 0.95);
}

input[type="checkbox"] + label:before {
    content: "\002713";
    text-align: center;
    color: rgba(0, 0, 255, 0);
    line-height: 1.75;
    font-size: 1.25rem;

    @include breakpoint($screen-sm) {
        line-height: 1.15;
        font-size: 1rem;
    }
}

input[type="checkbox"]:checked + label:before {
    color: $white;
    background-color: $input-checked-color;
    border-color: $input-checked-color;
}

input[type="checkbox"]:disabled + label:before {
    color: rgba(255, 255, 255, 0);
}

input[type="checkbox"]:checked:disabled + label:before {
    color: $x11-gray;
}

@media screen and (-ms-high-contrast: active) {
    input[type="checkbox"] + label:before {
        content: " ";
    }
    input[type="checkbox"]:checked + label:before {
        content: "\002713";
    }
    input[type="radio"] + label:before {
        content: " ";
    }
    input[type="radio"]:checked + label:before {
        content: "\00d7";
        line-height: 0.75;
        background-color: transparent;
        box-shadow: none;
    }
}

// Loading
// ==========================================================================
.loading {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.85);

    img {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
    }
    &--container {
        @extend .loading;
        position: absolute;
        border-radius: inherit; // match parent border-radius
        img {
            position: absolute;
        }
    }
}

// Validation
// ==========================================================================

.alert-success {
    color: $color-success;
}

.alert-success input,
input.alert-success,
.alert-success select,
select.alert-success {
    border-color: $color-success;
    background: $white-smoke-3 right 10px center
        svg-url($icon-inline-check-material, $color-success) no-repeat;
    padding: 0.8125rem 2.5rem 0.8125rem 0.8125rem;
}

.alert-error {
    color: $color-error-text;
}

.alert-error input,
input.alert-error,
.alert-error select,
select.alert-error {
    border-color: $color-error;
    padding: 0.8125rem 2.5rem 0.8125rem 0.8125rem;
    background: $white right 10px center svg-url($icon-inline-ui-error-mark, $input-checked-color)
        no-repeat;
}

.alert-error textarea,
textarea.alert-error {
    border-color: $color-error;
    padding: 0.8125rem 2.5rem 0.8125rem 0.8125rem;
    background: $white;
}

.validation-message {
    font-size: $text-size-md;
}

.validation-message-big {
    font-size: $text-size-md;
    font-weight: 700;
}

#profile_lang_update_label {
    background-color: $snowbird-white;
    padding-top: 20px;
    padding-bottom: 20px;
}
