#breadcrumb {
    display: block;
    margin-left: 0;
    padding-left: 0;

    li {
        display: none;
        color: $teal;
        max-width: 18%;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-last-child(2) {
            display: inline-block;
            max-width: 50%;

            &:before {
                content: "<";
                margin-right: 0.2em;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include breakpoint($screen-sm) {
        li {
            display: inline-block;
            max-width: 18%;

            &:nth-last-child(2) {
                display: inline-block;
                max-width: 18%;

                &:before {
                    content: "/";
                }
            }

            &:before {
                content: "/";
                color: $teal;
                margin-right: 0.2em;
            }
            &:first-child:before {
                content: none;
            }
            &:last-child {
                color: $color-text;
                max-width: 30%;
            }
        }
    }
}
