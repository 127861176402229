$color-feature: $blackberry-80;
$color-social: $teal;
$color-page-arrow: $grayLight2;

// mapping the fill colors to css variables so they can be effectively overridden
// this can be done simply with css variables but not sass ones
.logo-truth {
    --ex-logo-primary: #a01635;
    --ex-logo-secondary: #b4b5b4;
}

.logo-truth {
    height: 65px;
    width: 100%;
}

.login-partner-logo {
    width: 4rem;
    height: 100%;
    margin-right: 0.25rem;
}

.icon-navigation {
    height: 42px;
    width: 42px;
}

.icon-gear {
    height: 18px;
    width: 18px;
    fill: $green;
}

.icon-social {
    fill: $color-social;
}

.icon-mobile {
    fill: $color-social;
}

.icon-error {
    fill: $color-error;
}

.icon-section-error {
    path {
        fill: $white;
    }

    path:first-child {
        fill: $color-error;
    }
}

.icon-success {
    fill: $color-success;
}

.icon-section-success {
    path {
        fill: $white;
    }

    path:first-child {
        fill: $color-success;
    }
}

.icon-page-arrow {
    fill: $color-page-arrow;
}

.icon-arrow-down {
    fill: $color-social;
}

.icon-arrow-dropdown {
    fill: $color-social;
}
