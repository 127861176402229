/* ==========================================================================
	 Global
	 ========================================================================== */
:root {
    font-size: $text-size-base;
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%; // 3
    -webkit-text-size-adjust: 100%; // 3
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
body {
    background-color: $color-background;
    color: $color-text;
    font-family: $font-body;
    line-height: $line-height-base;
    font-weight: 400;
    margin: 0;
    // overflow-x: hidden;
}

input,
select,
textarea,
button {
    font-family: inherit;
}

// Overwriting some $font-serif declarations to $font-sans
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: inherit;
    color: $heading-color;
}

h1,
.h1 {
    font-size: 1.563rem;
}
h2,
.h2 {
    font-size: 1.25rem;
    font-weight: 600;
}
h3,
.h3 {
    font-size: 1.063rem;
    font-weight: 600;
}

label {
    color: $ex-label-text-color;
    display: block;
    font-size: $text-size-md;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

%caption {
    color: $grayMedium;
    font-style: italic;
}

%warning-text {
    color: $teal;
    margin-bottom: 1.5rem;
    margin-top: 3rem;

    h3 {
        color: $teal;
        font-size: 0.625rem;
        margin-bottom: 0.25rem;
    }
    p {
        font-size: 0.625rem !important;
        margin: 0.25rem 0;

        em {
            font-style: normal;
        }
    }
}
