/* the points progress bar currently only appears on the UserDashboard page */

@keyframes growProgressBar {
    0%,
    33% {
        --pointsPercentage: 0;
    }

    100% {
        --pointsPercentage: var(--value);
    }
}

@property --pointsPercentage {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
}

figure[role="progressbar"] {
    margin-top: 20px;
    margin-bottom: 20px;
    --size: 12rem;
    --fg: #{$forest};
    --bg: #{$platinum-darker};
    --pointsPercentage: var(--value);
    animation: growProgressBar ease-out 2s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    align-content: center;
    background:
    /* radial gradient makes a circle, inner color is white, transparent is the outer edge to make it less jagged.
       using both together has the affect of 'cutting out' the middle of the pie made by the conic-gradient. Neat!  */
        radial-gradient(circle, white 60%, transparent 60.5%),
        /*
    first value is green, the second is a darker green from the mocks, the last has extra percent to make it less jagged
    we can play with either values according to how we want the design to work
    */
            conic-gradient(
                var(--fg) calc(var(--pointsPercentage) * 39.5%),
                $dark-slate-gray calc(var(--pointsPercentage) * 99.9%),
                var(--bg) calc(var(--pointsPercentage) * 100.2%)
            );
    font-size: 24px;
}

figure[role="progressbar"]::before {
    counter-reset: percentage var(--value);
}

/* these aren't quite 'components', but it feels better to keep them close to their
related styles for now */

#points-progress-section {
    margin-bottom: 3rem;

    // light text weight for the numbers per design spec
    #points-total {
        font-weight: 300;
    }

    #points-label {
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}
