#cse .gsc-control-cse {
    font-family: $font-body;
    padding: 0;

    .gsc-table-result {
        font-family: $font-body;
    }

    .gsc-completion-container {
        font-family: $font-body;
    }

    .gsc-completion-title,
    .gsc-adBlock .gsc-ad a,
    .gsc-adBlockVertical .gsc-ad a,
    .gsc-results .gsc-trailing-more-results,
    .gsc-results .gsc-trailing-more-results *,
    .gs-result .gs-title,
    .gs-result .gs-title *,
    div.gs-results-attribution a,
    .gs-spelling a,
    .gsc-label-result-url-title {
        color: $teal;
        text-decoration: none;
    }

    .gsc-adBlock .gsc-ad cite,
    .gsc-adBlockVertical .gsc-ad cite,
    .gs-result a.gs-visibleUrl,
    .gs-result .gs-visibleUrl,
    .gs-result a.gs-clusterUrl,
    .gs-result .gs-clusterUrl,
    .gs-videoResult a.gs-publisher,
    .gs-videoResult .gs-publisher,
    .gsc-label-result-url {
        color: $link-color;
    }

    .gsc-clear-button,
    .gsc-resultsHeader {
        display: none;
    }

    table {
        margin-bottom: 0;

        tr {
            border-bottom: none;
        }

        td {
            padding-left: 0;
            padding-right: 0;

            &.gsc-table-cell-thumbnail {
                padding-right: 1rem;
            }
        }
    }

    input.gsc-input {
        @extend %form-control;
    }

    table.gsc-search-box {
        height: 1px;

        tr {
            height: 100%;
        }

        td {
            padding: 1rem;
            height: 100%;

            &.gsc-input {
                padding: 0 1rem 0 0;
            }

            &.gsc-search-button {
                padding: 0;

                button {
                    min-width: unset;
                    height: 100%;
                    background-color: $truth-deep-purple;
                    margin: 0;
                }
            }
        }
    }

    .gsc-result-info {
        padding-left: 0;
        margin: 0;
    }

    .gsc-above-wrapper-area {
        table {
            width: auto;
        }

        td {
            padding: 0;
        }
    }
}
