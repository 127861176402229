.registration-intro {
    @extend .row;

    @include breakpoint($screen-sm) {
        display: flex;
    }
}

.registration-intro-text {
    @extend .col-md-5;
    @extend .col-sm-6;
    padding: 0 2rem;

    @include breakpoint($screen-sm) {
        background-color: $forest;
        margin: 2rem 0 0;
        padding: 1rem 2rem;

        h1 {
            width: 80%;
        }
    }
}

.registration-quotes {
    @extend .col-md-7;
    @extend .col-sm-6;

    .Wallop-slider .wallop-next-prev {
        top: 0;
        margin-top: 12rem;
    }

    @include breakpoint($screen-sm) {
        blockquote p {
            font-size: $text-size-md;
        }
    }

    .Wallop-pagination.dots {
        top: 0;
    }
}

.employer-plan-preform .sponsor-hidden,
.standard-form .sponsor-hidden,
.employer-plan-preform li.sponsor-hidden {
    padding: 0;
    margin-bottom: 0;
}

li.formField {
    position: relative;

    .tooltip,
    .toggle-password {
        position: absolute;
        top: 0;
        right: 0px;
        width: 80px;
        height: 25px;

        span {
            display: inline-block;
            height: 25px;
            line-height: 25px;
            font-size: 11px;
            color: $teal;

            &:last-of-type {
                float: right;
            }
        }

        svg {
            width: 24px;
            fill: $teal;
        }
    }

    .tooltip {
        height: 20px;
        width: 18px;

        svg {
            vertical-align: top;
        }
    }

    .toggle-password.active,
    .toggle-password:hover {
        svg {
            fill: $teal;
        }
    }

    @include breakpoint($screen-md) {
        &.healthplan label {
            white-space: nowrap;
        }
    }

    sup {
        font-weight: normal;
    }
}

#reg-buttons {
    padding: 1rem 0 3rem;
    text-align: center;

    &.signup-btn {
        padding-bottom: 24px;
    }
}

#hipaa-release-container,
#hipaa-release-download-container {
    display: none;
}

#hipaa-container {
    border: 2px solid $blackberry-10;

    .scrolling-box {
        height: 200px;
        overflow-y: scroll;
    }
}

#hipaa-release,
#hipaa-download-container,
.dialog-content {
    padding: 0 15px;
}

.hipaa-checkbox-holder,
#hipaa-release-download-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.hipaa-cta {
    font-size: larger;
}

#hipaa-container > .scrolling-box {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3);
}

#hipaa-container > .scrolling-box::-webkit-scrollbar {
    width: 6px;
    background-color: $white-smoke-2;
}

#hipaa-container > .scrolling-box::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
}

#hipaa-container > .scrolling-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $white-smoke-2;
    border-radius: 10px;
}

li.hipaaReleaseAgreed label {
    max-width: 80vw;
}

#hipaa-checkbox-text p {
    margin: 0 1em;
}

#hipaa-unaccepted-warning-container {
    border: solid 1px $tangerine-yellow;
    border-left-width: 14px;
    margin-left: -13px;
    padding: 0.25rem 0.75rem;
}

.confirm-hipaa {
    padding: 0.25rem 0.75rem;

    #hipaa-release-container {
        display: block;

        .scrolling-box {
            height: 400px;
        }
    }

    h1 {
        margin-top: 85px;
        text-align: center;
    }

    p.decline-text {
        @include breakpoint($screen-md) {
            width: 24em;
            margin: 0 auto;
        }
    }
}

#hipaa-release-download-container {
    margin-bottom: 20px;

    a {
        text-decoration: underline;
    }

    a:hover {
        font-weight: bolder;
    }
}

// Rules for Promotional California Overlay
@media (min-width: 992px) {
    #facebox .body {
        width: 750px !important;
    }
}

.primaryPopupMessageBlock {
    background-color: $floral-white;
    padding: 1em 1em 3em 1em;
    margin: 1em 0 1em 0;
    font-weight: 300;
    line-height: 1.75;
}

.secondaryPopupMessageBlock {
    background-color: $snow;
    margin-top: 16px;
    padding: 1em;
    text-align: left;
    font-size: 0.9em;
}

// Main new registration container
.create_new_profile_cnt {
    background-color: $sand;

    input[type="text"] {
        box-shadow: none;
        border-radius: 3px;
        border: 1px solid $grayMedium;
    }

    input[type="radio"] + label {
        display: block;
        border: none;
    }

    input[type="checkbox"] + label {
        display: block;
        border: none;
    }

    label {
        color: $color-text;
    }

    .description {
        color: $form-label-text;
    }

    .legalBirthDate {
        input {
            max-width: 269px;
        }
    }

    .error {
        input[type="text"] {
            background-image: none;
        }
    }

    .error-panel {
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px $blackShadow;
        border: 1px solid $color-error;
        border-left-width: 14px;
        padding: 1rem;
        color: $color-text;
        /* error panel margin needs to be 24px adjust
    if header texts margin changed. */
        margin-top: 9px;
        margin-bottom: 24px;
    }

    .warning-panel {
        border: 1px solid $color-warning;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px $blackShadow;
        border: 1px solid $color-warning;
        border-left-width: 14px;
        padding: 1rem;
        color: $color-text;
    }

    .container-background {
        background-color: $forest;
        height: 635px;
        width: 100%;
        position: absolute;
        z-index: 1;

        @include breakpoint($screen-sm) {
            background: url(/static/img/bg-signup-page.svg) no-repeat left center;
            background-size: cover;
        }
    }

    form {
        legend {
            color: $heading-color;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.3rem;
            margin-bottom: 1rem;
        }

        .form-container {
            max-width: 760px;
        }

        // TODO: replace "*" with exact elem.
        .form-header * {
            color: $reg-heading-text-color;
        }

        .custom-signup > .form-header {
            h1 {
                color: $heading-color;
            }

            h2,
            h3,
            p {
                color: $color-text;
            }
        }

        .custom-signup .intro {
            margin-bottom: 1.25rem;

            @include breakpoint($screen-sm) {
                // Align logo and the text in front of it
                display: flex;
                align-items: center;
            }

            img {
                /*
         * Limit width to avoid the logo to occupy the whole width
         * in the small screens and closer match to design
         */
                max-width: 100px;
            }
        }

        .employer-plan-preform__item {
            &.legalfirstname,
            &.legallastname,
            &.payermemberid {
                width: 100%;

                @include breakpoint($screen-md) {
                    width: 50%;
                }
            }
        }

        ul#account-info {
            padding: 0;
        }

        @include breakpoint($screen-sm) {
            input[type="radio"] + label,
            input[type="checkbox"] + label {
                padding-left: 1.5rem;
            }

            .zipcode input {
                max-width: 269px;
            }
        }

        .form-body {
            margin: 2rem auto;
        }
    }
}

.gender_options_vertical {
    li.gender div.options label {
        display: block;
        margin-bottom: 0;
    }
}

#study-dialog {
    ul {
        list-style: unset;
        padding-inline-start: 40px;
    }

    button {
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
    }
}

.create-profile-header {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $reg-header-color;
    margin-left: auto;
    margin-right: auto;
}

.create-profile-header--custom-signup {
    height: initial;
    flex-direction: column;
    @include breakpoint($screen-sm) {
        flex-direction: row;
        h2 {
            max-width: 37.5rem;
        }
    }
    // custom signup the bg color is split
    background-color: initial;
}

// fix a bit of spacing not needed on the reg page
.bg-color--white > .brand-container {
    margin-top: initial;
}

// this is the brand for the payer
.custom-signup-brand__container {
    max-width: $screen-sm;
    display: flex;
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.25rem;
    padding-top: 3.125rem;
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint($screen-sm) {
        padding-left: initial;
        padding-right: initial;
        padding-bottom: 3.25rem;
        padding-top: 3.25rem;
    }
}

.custom-signup__logo {
    margin-right: 15px;
    // payer logo widths vary; we want to keep them sized to the design
    // while not altering older logos that may be more narrow
    min-width: 100px;
    max-width: 160px;
}

.header-item {
    display: flex;
    height: 30vh;
    @include breakpoint($screen-sm) {
        padding-top: 7.5rem;
        padding-bottom: 10rem;
    }
    align-items: center;
    width: 100%;
    justify-content: center;
}

.form-header {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

// applies to the custom signup header
.header-item .form-header {
    text-align: left;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    @include breakpoint($screen-sm) {
        margin: initial;
        margin-left: auto;
        margin-right: 3.25rem;
    }
}
