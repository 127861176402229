.author-bar {
    background-color: $glowing-sand;
    padding: 3rem 0;
}

.author-box {
    align-items: center;
    background-color: $white;
    border: 1.5px solid $truth-deep-purple;
    border-radius: 20px;
    column-gap: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 306px;
    padding: 23px 42px 19px 29px;
    row-gap: 14px;
    @media screen and (min-width: 620px) {
        flex-wrap: nowrap;
        gap: 25px;
        max-width: 587px;
        padding: 31px 60px;
    }
}

.author-box__image {
    order: 1;
    @media screen and (min-width: 620px) {
        flex-grow: 0;
        flex-shrink: 0;
    }
    img {
        border: 1px solid $truth-orange;
        border-radius: 50%;
        display: block;
        height: 100px;
        width: 100px;
    }
}
.author-box__affiliation {
    flex-grow: 0;
    order: 2;
    width: 110px;
    @media screen and (min-width: 620px) {
        order: 3;
    }
}
.author-box__details {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 140%;
    gap: 8px;
    order: 3;
    width: 100%;
    @media screen and (min-width: 620px) {
        flex-grow: 1;
        order: 2;
        width: auto;
    }
    p {
        margin: 0;
    }
    .author-details__name {
        color: $truth-teal;
        font-weight: 700;
    }
    .author-details__appointments {
        color: $truth-deep-purple;
        font-weight: 600;
    }
    .author-details__date {
        color: $truth-deep-purple;
        font-weight: 400;
    }
}
