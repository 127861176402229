// these are used on article pages and post-login pages
%my-quit-plan-content-box-default {
    background: $white;
    margin-bottom: 1rem;
    padding: 1rem;
    position: relative;

    @include breakpoint($bp-md) {
        padding: 1.25rem 4.75rem;
        text-align: left;
    }
}
%checkbox-shared {
    border: 1.5px solid;
    border-color: $grayMedium;
    border-radius: 4px;
    height: 1.75rem;
    width: 1.75rem;
}
.check-symbol {
    @extend %checkbox-shared;
    display: inline-block;
    position: relative;
    margin-top: 5px;
    left: 40%;
}
.my-quit-band {
    margin-bottom: 0.375rem;
    padding: 3rem 0 2rem 0;

    &.red {
        background-color: $truth-background-primary;
    }
    &.yellow {
        background-color: $teal;
    }
    &.blue {
        background-color: $teal;
    }
    &.teal {
        background-color: $dark-teal;
    }

    h3,
    h4 {
        color: $quit-band-heading-color;
        margin-top: 0;
    }

    a {
        text-decoration: none;
        color: $quit-band-text-color;
    }
    &.bg-unset {
        margin-bottom: 0;
        background-color: $quit-band-bg;
    }
}

#exp_recommendations_section {
    background-color: $quit-band-primary-color;
    padding: 3rem 0 2rem 0;
    margin-bottom: 0;

    h2 {
        color: white;
    }

    .exp-recommendation,
    .exp-swap-all-container {
        @extend %my-quit-plan-content-box-default;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 1.25rem;

        &.read {
            background-color: $blackberry-10;
        }

        h3 {
            color: $btn-primary-text;
            margin-bottom: 0.25rem;
        }

        p {
            color: $btn-primary-text;
        }
        .btn-cnt {
            display: flex;
            justify-content: flex-end;
        }
        .btn {
            min-width: auto;
            padding: 0.5rem 1rem;
            margin-left: 0.5rem;

            &.btn-outline {
                color: $activity-section-bg;
                background-color: white;
                border: 1px solid $activity-section-bg;
            }
        }

        @media (max-width: $screen-sm) {
            a.btn {
                min-height: auto;
            }
        }
    }

    .exp-swap-all-container.hide {
        display: none;
    }
}

.my-quit-link {
    display: block;
    min-height: 116px;
    @extend %my-quit-plan-content-box-default;
    @include clearfix;

    span.title,
    span.desc {
        display: block;
    }

    span.title {
        font-weight: 600;
        margin-bottom: 0.25rem;
        transition: color ease-in-out 0.2s;
    }

    .icon-feature {
        height: 75px;
        width: 75px;
        margin-bottom: 1rem;
    }

    @include breakpoint($bp-md) {
        padding: 1.25rem 1.25rem 1.25rem 9rem;

        p,
        span.title,
        span.desc {
            max-width: 80%;
        }

        .icon-feature {
            position: absolute;
            left: 2rem;
            top: 1.25rem;
            height: 80px;
            width: 80px;
            margin-bottom: 0;
        }
    }
}

// This could probably belong in it's own component file.
// Consider it tech debt as we should overhaul css if/when designs change
.block-mqp_intro .richtext-image.left {
    float: left;
}

#resources_section {
    margin-bottom: 2rem;
}

#resources_section a {
    background-color: $quit-resources-section-bg !important;
    text-decoration: none;
    text-align: center;

    &:hover {
        filter: brightness(0.9);
    }
}

#recommendations_section.my-quit-band {
    background-color: $quit-band-primary-color;

    &.quit-date-in-the-past {
        background-color: $quit-date-in-the-past-color;
    }
    &.quit-date-today {
        background-color: $quit-date-today-color;
    }
    &.quit-date-in-the-future {
        background-color: $quit-date-in-the-future-color;
    }
}

// links listed here have no icons, reset to 'default' (my-quit-plan-content-box-default)
#recommendations_section a {
    @extend %my-quit-plan-content-box-default;

    background: $white right 0.2rem center svg-url($icon-inline-arrow-right, $teal, "", 35, 35)
        no-repeat;

    &:hover {
        background-color: rgba($white, 0.9);
        text-decoration: none;
    }
    &:visited {
        color: $grayMedium2;
        background-color: $blackberry-10;

        .community-resource-tile--text h4 {
            color: $grayMedium2;
        }
    }

    @include breakpoint($bp-md) {
        padding: 1.25rem 2rem;
    }
}

#recommendations_section > div > header,
#exp_recommendations_section > div > header {
    color: white;
}

#recommendations_header svg {
    fill: white;
    height: 5rem;
    width: 5rem;
    margin: auto;
    display: block;
    @include breakpoint($screen-sm) {
        margin-left: 1rem;
    }
}

// this is here so on mobile the chevrons can appear without blocking text
// naming is an example of BEM style CSS, not that we use it broadly but we could
.resource-tile--text {
    margin-right: 2rem;
    text-align: left;
}

.community-resource-tile {
    a {
        display: flex;
        flex-direction: column;
    }

    .community-resource-tile--image {
        flex-shrink: 0;
        text-align: left;
        margin-bottom: 0.3rem;

        img {
            width: 80px;
            border: 2px solid $teal;
            border-radius: 50%;
        }
    }

    .community-resource-tile--text {
        margin-right: 2rem; // Margin on the right for the chevron.

        h4 {
            color: $teal;
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
        }

        .logo {
            vertical-align: baseline;
            height: 0.6rem;
            width: 1.2rem;
        }
    }

    @include breakpoint($bp-md) {
        a {
            flex-direction: row;
        }

        .community-resource-tile--image {
            text-align: center;
            margin: auto 1.5rem auto 0;
            padding-bottom: 0;
        }
    }
}

// tighten up the text layout just for this section
// feel free to refactor if/when we end up with some more
// broadly reusable spacing
#recommendations_header h2,
#recommendations_header h3,
#recommendations_header p {
    margin-bottom: 0.25rem;
}

#recommendations_header {
    margin-bottom: 1.5rem;

    p {
        font-size: 1.15rem;
    }
}

#recommendations_header h3,
#recommendations_header p {
    margin-top: 0.25rem;
}

.my-quit-link {
    // links should have a visual indication that they go somewhere
    background: $quit-link-bg right 0.2rem center
        svg-url($icon-inline-arrow-right, $teal, "", 35, 35) no-repeat;
    border: $quit-link-border;
    border-radius: $quit-link-border-radius;
    text-align: left;
    &:hover,
    &:active {
        background-color: rgba($white, 0.9);
        text-decoration: none;
    }

    &.track {
        padding: 1.25rem 4.688rem;

        &:before {
            @extend %checkbox-shared;
            content: "";
            display: block;
            position: absolute;
            margin-left: 0;
            top: 1.5rem;
            left: 2rem;
        }
        &.complete:before {
            background: $teal center center svg-url($icon-inline-check-mark-sm, $white, "", 15, 15)
                no-repeat;
            border-color: $teal;
        }
    }
    // Simple class is used to apply styling updates for 2024 redesign
    // Includes filled purple icon arrow "button", purple border, padding changes, and hover state
    &.simple {
        background-image: none;
        margin-bottom: 1.5rem;
        padding: 1.25rem 4rem 1.25rem 1.25rem;
        transition: border-color ease-in-out 0.2s;
        &:after {
            content: " ";
            background-color: $truth-primary;
            background-image: svg-url($icon-inline-arrow-next, $white, "");
            background-position: 14px 10px;
            background-repeat: no-repeat;
            background-size: 27%;
            display: block;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint($bp-md) {
                right: 2rem;
            }
        }
        &:hover,
        &:active {
            background-color: transparent;
            border-color: $truth-primary;

            span.title {
                color: $truth-primary;
            }
        }
        span.title {
            @extend h3;
            font-weight: 700;
        }
    }

    @include breakpoint($bp-sm) {
        &.no-track {
            padding: 1.25rem 1.25rem 1.25rem;
        }
    }

    @include breakpoint($bp-md) {
        &.no-arrow {
            background-image: none;
        }

        &.track {
            padding: 1.25rem 4.688rem;

            span.title,
            span.desc {
                max-width: 85%;
            }

            &:before {
                margin-left: 0;
                top: 1.5rem;
                left: 2rem;
            }
        }
        &.no-track {
            padding: 1.25rem 1.25rem 1.25rem 4.688rem;
        }
        &.simple {
            padding-right: 5.5rem;
            padding-left: 3.5em;
        }
    }

    @include breakpoint($bp-lg) {
        &.track,
        &.no-track {
            span.title,
            span.desc {
                max-width: 83.5%;
            }
        }
    }
}

.my-quit-content-box {
    @extend %my-quit-plan-content-box-default;

    h4 {
        margin: 0 0 0.5rem;
        font-weight: 600;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 0.5rem 0;
        }

        a {
            background-color: rgba($blackberry-10, 0.25);
            display: block;
            padding: 1rem;

            &:hover,
            &:active {
                background-color: $blackberry-10;
            }
        }
    }

    @include breakpoint($bp-md) {
        ul {
            padding-left: 2rem;

            a {
                background-color: transparent;
                padding: 0;

                &:hover,
                &:active {
                    background-color: transparent;
                }
            }
        }
    }
}

.my-quit-link-subcontent {
    margin-top: -1rem;
    @extend %my-quit-plan-content-box-default;

    @include breakpoint($bp-md) {
        padding-top: 0;
    }
}

.my-quit-link-article,
.my-quit-testimonial {
    border-bottom: 1px solid $blackberry-10;
    padding: 1rem;
    position: relative;
    @include clearfix;

    a:hover {
        text-decoration: underline;
    }

    &:last-of-type {
        border-bottom: none;
    }

    .author-img {
        margin-bottom: 0.5rem;
    }

    .intro {
        overflow: hidden;
        margin: 0.25rem 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .quote,
    .quote + div.rich-text p {
        font-size: $text-size-lg;
        font-weight: 400;
        margin: 0 0 1rem 0;
    }

    .intro,
    .author,
    .date,
    .age {
        font-size: $text-size-sm;
    }

    .author,
    .date,
    .age {
        text-transform: uppercase;
    }

    .divider {
        color: $blackberry-80;
    }

    .date,
    .age {
        font-weight: 300;
    }

    @include breakpoint($bp-md) {
        padding: 0.5rem 0 1rem 5.5rem;
        margin: 0 4rem 0 0;

        .quote,
        .quote + div.rich-text p {
            font-size: $text-size-sm;
            margin: 0 0 0.5rem 0;
        }
        .author-img {
            left: 0;
            position: absolute;
            top: 1rem;
        }
    }
}

.my-quit-testimonial {
    background-color: mix($white, $teal, 95%);
    margin-top: 2rem;
    padding-top: 3rem;
    text-align: center;

    &:before {
        background-color: $teal;
        background-size: 33px;
        border-radius: 50%;
        color: $white;
        content: "\201C";
        display: block;
        font-family: "Bookman Old Style";
        font-size: 2.813rem;
        font-weight: 700;
        height: 33px;
        left: 50%;
        line-height: 1.1;
        margin-left: -16px;
        position: absolute;
        text-align: center;
        top: -15px;
        width: 33px;
    }

    @include breakpoint($bp-md) {
        background-color: transparent;
        margin-top: 0;
        padding-top: 0.5rem;
        text-align: left;

        &:before {
            display: none;
        }
    }
}

.author-img {
    border: 2px solid $quit-link-border-color;
    border-radius: 50%;
    display: block;
    height: 60px;
    width: 60px;
}

.read-more-title {
    padding-top: 2rem;
}

.my-quit-read-more {
    margin-bottom: 2rem;

    @include breakpoint($bp-md) {
        .article-container {
            max-width: 50%;
        }
    }

    .article {
        background-color: $read-more-background-color;
        position: relative;
        height: 100%;
        margin-bottom: 1rem;

        div[class^="col"] a {
            font-weight: 600;
        }
    }

    .content {
        padding: 1rem;
    }

    .article-img {
        padding-left: 20px;
        padding-top: 0.5rem;

        span {
            display: block;
            border-radius: 50%;
            position: relative;
            padding-top: 100%;
        }
        img {
            border: 2px solid $truth-primary-variant;
            border-radius: 50%;
            left: 50%;
            margin-left: -50%;
            position: absolute;
            top: 0;
            height: 100%;
            width: auto;
        }
        svg {
            fill: $teal;
            height: auto;
            max-height: 115px;
            max-width: 115px;
            width: 100%;
        }
    }

    .author-date {
        margin-top: 0;
    }

    .author,
    .date {
        font-size: $text-size-sm;
        text-transform: uppercase;
    }

    .date {
        font-weight: 300;
    }
    .divider {
        color: $blackberry-80;
    }

    .read-more {
        a {
            background: $read-more-link-background-color right 20px center
                svg-url($icon-inline-arrow-right, $white, "", 28, 28) no-repeat;
            color: $white;
            display: block;
            padding: 0.5rem 1rem;
            text-decoration: none;

            &:hover,
            &:active {
                background-color: $read-more-link-background-color--hover;
            }
        }
    }

    @include breakpoint($bp-md) {
        display: flex;
        padding: 0 0 2rem 0;

        & > .col-md-6 {
            flex: 1;
        }

        .read-more {
            bottom: 0;
            left: 0;
            position: absolute;
            width: 100%;
        }

        .desc,
        .desc + div.rich-text p {
            font-size: 0.938rem;
        }

        .article {
            margin-bottom: 0;
            padding-bottom: 2.5rem;
        }
    }

    @include breakpoint($bp-lg) {
        & > [class^="col-"]:nth-child(odd) .article {
            margin-right: 1.5rem;
        }
        & > [class^="col-"]:nth-child(even) .article {
            margin-left: 1.5rem;
        }
    }
}

.live-coaching-support-meet-our-coaches {
    @extend .my-quit-read-more;
    margin-bottom: 2rem;
    padding: 0;
}

.live-coaching-support-meet-our-coaches .article-img {
    margin-bottom: 20px;
}
