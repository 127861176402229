.experts-content {

    .brand-container {
        display: block;
        max-height: fit-content;
        max-width: fit-content;
        background-color: $white;
        border: 1.5px solid $truth-primary-variant;
        padding: 20px;
        border-radius: 20px;
        align-items: left;
        margin-left: unset;
        margin-right: unset;

        .brand-container__logo {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }

        .brand-container__text {
            font-size: $text-size-lg; 
        }
    }


    display: flex;
    flex-direction: column;
    gap: 2rem;
    // including layout styles for brand-container specific to homepage.
    .brand-container {
        flex-basis: 100%;
        width: 100%
    }
    @include breakpoint($screen-md) {
        flex-direction: row;
        gap: 4rem;
        .brand-container {
            flex-basis: 50%;
            width: 50%
        }
    }
}

.experts-quote-and-image {
    flex-basis: 100%;
    width: 100%;
    .experts-image {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        border: 1.5px solid $truth-primary-variant;
    }
    .experts-quote {
        p:first-of-type {
            font-size: 1.25rem;
        }
    }
    @include breakpoint($screen-md) {
        flex-basis: 50%;
        width: 50%;
    }
}
