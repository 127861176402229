%trigger-tracker-tool {
    margin: 2rem 0;

    h2 {
        margin: 0;
        padding: 0.5rem 0;
        text-align: center;
    }

    .header,
    .exercise-row {
        @include clearfix;
    }

    .header {
        border-bottom: 1px solid $blackberry-80;
    }

    .exercise_answer {
        display: block;
    }

    textarea,
    .removeLink {
        display: none;
    }

    #addNewData textarea {
        display: block;
    }

    .exercise-row {
        padding: 1rem 0;

        &:nth-child(even) {
            background-color: $zebra-row-line-background;
        }

        &.state-editable {
            .exercise_answer,
            .updateLink {
                display: none;
            }

            textarea {
                display: block;
            }

            .removeLink {
                display: inline-block;
            }
        }

        input.triggerName {
            display: none;
            margin-bottom: 0;
        }

        .lcol,
        .rcol {
            padding: 0 1rem;
            width: 100%;
        }
    }

    .state-ro,
    .header {
        .lcol,
        .rcol {
            padding: 0 1rem;
            float: left;
        }
        .lcol {
            width: 40%;
        }
        .rcol {
            @include clearfix;
            width: 60%;
        }
    }

    .triggerLabel {
        margin-top: 1rem;
    }

    .other-label {
        font-size: $text-size-xs;
        font-style: italic;
    }

    textarea {
        @extend %form-control;
    }

    .submissionRow {
        text-align: center;
        padding: 1.5rem;

        a {
            display: block;
            margin-bottom: 1.5rem;
        }
    }

    .answer {
        @include clearfix;
    }

    .answer-controls {
        @include clearfix;
        padding: 0 1rem;
        margin-left: 40%;
        width: 60%;
    }

    .triggerName,
    .exercise_answer {
        margin: 0;
    }

    .triggerName {
        font-weight: bold;
        margin-bottom: 0.25rem;
    }
    .answer-right {
        text-align: right;
    }

    @include breakpoint($screen-sm) {
        .triggerName {
            font-weight: normal;
            margin-bottom: 0;
        }

        .state-ro,
        .header {
            .lcol,
            .rcol {
                padding: 0 1.5rem;
                width: 50%;
            }
        }

        .exercise-row {
            .lcol,
            .rcol {
                float: left;
                padding: 0 1.5rem;
                width: 50%;
            }
        }

        .answer-controls {
            padding: 0.25rem 1.5rem 0;
            margin-left: 50%;
            width: 50%;
        }

        .triggerSelectLabel {
            border: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            white-space: nowrap;
        }
    }

    @include breakpoint($screen-md) {
        .submissionRow {
            padding: 2.5rem;
        }
    }
}

// Beat Your Smoking Triggers - Completed - beat-your-smoking-triggers-completed.php
.exercise-thanks-list {
    list-style: none;
    padding: 0;
    li.exercise {
        padding: 1rem 0;
    }
    p {
        margin: 0;
    }
    p span,
    p span.label-trigger,
    p span.label-how {
        display: inline-block;
    }

    .label-trigger,
    .label-how {
        @extend label;
    }

    .label {
        font-weight: normal;
    }

    @include breakpoint($screen-sm) {
        padding: 0 40px;

        p {
            display: flex;
        }

        span.label-trigger,
        span.label-how {
            width: 220px;
            flex: 220px 0;
        }

        span.label {
            flex: 1;
        }
    }
}

// Beat Your Smoking Triggers - Completed - beat-your-smoking-triggers-completed.php
#whats-next-block {
    border-top: 2px solid $blackberry-10;
    padding: 2em 0 0;
    width: 100%;

    .whats-next-block,
    .whats-next-sub-block {
        @extend .container;
    }

    .whats-next-sub-block {
        margin-bottom: 2rem;
    }

    .whats-next-sub-block:last-of-type {
        margin-bottom: 4rem;
    }
}

.preheadlineblock {
    @extend .col-md-6;
}
