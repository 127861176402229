%topic-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $desktop-max-width;
    margin: auto;
}

.topic-page {
    background-color: $background-color-light;

    .topic-page__header {
        width: fit-content;
        margin: auto;
        border-bottom: 1.6px solid $truth-deep-purple;
        margin: 0 1rem 2rem 1rem;

        @include breakpoint($screen-sm) {
            max-width: $desktop-max-width;
            margin: 0 auto 4rem auto;
        }
    }

    .topic-section {
        @extend %topic-section;

        @include breakpoint($screen-sm) {
            margin-bottom: 2rem;
            gap: 4rem;
            flex-direction: row;
            align-items: baseline;
        }

        &__body-content {
            border-bottom: 1.6px solid $truth-deep-purple;
            margin-bottom: 2rem;

            @include breakpoint($screen-sm) {
                margin-bottom: 4rem;
            }
        }
    }

    &__body {
        @extend %topic-section;
        align-items: center;
        padding: 0 1rem;
        gap: 2rem;

        .topic-article {
            border-bottom: 1.6px solid $truth-deep-purple;
            padding-bottom: 1.5rem;
            width: 100%;

            @include breakpoint($screen-sm) {
                padding-bottom: 4rem;
            }
        }
    }

    &__body-content {
        @extend %topic-section;
    }

    &__heading,
    &__footer {
        @extend %topic-section;
        padding: 1.5rem;

        @include breakpoint($screen-sm) {
            gap: 4rem;
            flex-direction: row;
        }
    }

    &__heading {
        margin: 0 1rem 1rem;
        width: auto;

        @include breakpoint($screen-sm) {
            margin: auto;
        }
    }
}

.section-heading {
    /* the layout on desktop for each section should be
    a 'left rail' of content that is defined as
    heading content, with an optional 'body' content
    and then list of child pages as the 'right rail'*/

    display: flex;
    flex-direction: column;
    flex: 1;

    .topic-section__heading {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include breakpoint($screen-sm) {
            gap: 4rem;
        }
    }

    @include breakpoint($screen-sm) {
        flex: 1;
    }
}

.section-content {
    @include breakpoint($screen-sm) {
        flex: 2;
    }
}
