// Registration - using existing markup from old registration page, use extend method
// to share styles used for updated markup
// https://www.becomeanex.org/create-profile.php
// ==========================================================================

.standard-form {
    .formField.error {
        @extend .alert-error;
    }

    // validation-message is just a text size thing atm
    .errorMessages,
    .errorlist {
        @extend .validation-message;
    }

    .checkboxErrorMessages {
        position: relative;
        top: -1rem;
        padding-left: 0.5rem;
    }

    li.formField {
        margin-bottom: 2rem;
    }
}

.options {
    input[type="checkbox"] + label,
    input[type="radio"] + label,
    input,
    label {
        @extend %form-check-inline-li;
    }
}

.employer-plan-preform {
    @extend .row;

    & > label {
        @extend .col-xs-12;
        font-size: $text-size-lg;
    }

    & > li {
        margin-bottom: 2rem;
    }

    .checkbox-holder {
        margin-top: 1rem;
    }

    @include breakpoint($screen-sm) {
        .checkbox-holder {
            margin-top: 0;
        }
    }
}

.employer-plan-preform__item {
    @extend .col-md-4;

    &.healthplan {
        @extend .col-md-5;
    }

    &.zip {
        @extend .col-md-3;
    }

    &.legalfirstname {
        @extend .col-md-3;
    }

    &.legalbirthdate {
        @extend .col-md-2;
    }

    &.payermemberid {
        @extend .col-md-3;
    }

    &.selffirstname {
        @extend .col-md-3;
    }

    &.selfbirthdate {
        @extend .col-md-2;
    }
}

ul#account-info {
    display: grid;
    @media (min-width: $screen-sm-min) {
        display: -ms-grid;
    }
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    padding: 0 1rem;
    li {
        display: inline-block;
        float: none;
        &:nth-child(odd) {
            // support for ie10, use grid-column-gap for later versions
            margin-right: 0.875rem;
            -ms-grid-column: 1;
        }
        &:nth-child(even) {
            // support for ie10, use grid-column-gap for later versions
            margin-left: 0.875rem;
            -ms-grid-column: 2;
        }
        &:nth-child(n + 1):nth-child(-n + 2) {
            -ms-grid-row: 1;
        }
        &:nth-child(n + 3):nth-child(-n + 4) {
            -ms-grid-row: 2;
        }
        &:nth-child(n + 5):nth-child(-n + 6) {
            -ms-grid-row: 3;
        }
    }
    @media (max-width: $screen-sm) {
        grid-template-columns: 1fr;
        li {
            display: block;
            &:nth-child(odd) {
                // support for ie10, use grid-column-gap for later versions
                margin-right: 0;
            }
            &:nth-child(even) {
                // support for ie10, use grid-column-gap for later versions
                margin-left: 0;
            }
        }
    }
}

ul.account-info-direct {
    -ms-grid-rows: 150px 130px 130px;
}

ul.account-info-non-direct {
    -ms-grid-rows: 150px;
}

div#communications-section {
    background: $grayWhite;
    @media (min-width: $screen-md) {
        background-color: $grayWhite;
        background-position:
            left 2rem top 3.25rem,
            left 2rem top 9.25rem;
        background-repeat: no-repeat;
        background-image: svg-url($icon-inline-email, $blackberry-80, "", 115, 115),
            svg-url($icon-inline-text-message, $blackberry-80, "", 115, 115);
        &.do-not-text {
            background-image: svg-url($icon-inline-email, $blackberry-80, "", 115, 115);
        }
        h2 {
            margin-left: -8.5rem;
            margin-bottom: 2rem;
            margin-top: 1.25rem;
        }
    }
    padding: 0.1rem 3rem 1rem 10.5rem;
    @media (max-width: $screen-md) {
        padding: 0.1rem 2rem 1rem 2rem;
    }
    label {
        font-weight: normal;
    }

    #err_CommunicationsConsent div.rich-text {
        padding-bottom: 0.5rem;
    }
}

#communications-section > ul > .formField label .rich-text {
    font-weight: initial;
    ul {
        list-style: inside;
    }
}

@media (max-width: $screen-sm) {
    // for the communications consent, we overload the checkbox form element to also have disclaimer text
    // but we want this to still appear grouped with the checkbox element like other form elements
    .checkbox-with-consent {
        border: 1px solid $blackberry-10;

        @include breakpoint($screen-sm) {
            border: none;
        }
    }

    // emailOptin and mobileOptin also used on edit-profile
    li.emailOptin.formField,
    li.mobileOptin.formField {
        margin-bottom: 0.1rem;
    }

    // bottom of the label, before the longer description
    #communications-section > ul:nth-child(2) > li.emailOptin.formField > label {
        border-bottom: none;
    }
    #communications-section > ul:nth-child(2) > li.emailOptin.formField > label::before {
        margin-top: 0.2em;
        margin-left: -2em;
    }

    // bottom of the label, before the longer description
    #communications-section > ul:nth-child(2) > li.mobileOptin.formField > label {
        border-bottom: none;
    }

    #communications-section > ul:nth-child(2) > li.mobileOptin.formField > label:before {
        margin-top: 0.2em;
        margin-left: -2em;
    }

    // bottom of phone consent
    #communications-section > ul:nth-child(2) > li:nth-child(4) {
        border-bottom: none;
    }

    .communicationsConsent {
        border: 1px solid $blackberry-10;
        border-top: none;
    }
}

#communications-section > ul:nth-child(2) > li.mobileOptin.formField > label {
    font-weight: 700;
}

#communications-section > ul:nth-child(2) > li.emailOptin.formField > label {
    font-weight: 700;
}

ul#mobile-info {
    display: grid;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    @media (min-width: $screen-sm-min) {
        display: -ms-grid;
    }
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 150px 130px;
    li {
        display: inline-block;
        float: none;
        &:nth-child(odd) {
            // support for ie10, use grid-column-gap for later versions
            margin-right: 0.875rem;
            -ms-grid-column: 1;
        }
        &:nth-child(even) {
            // support for ie10, use grid-column-gap for later versions
            margin-left: 0.875rem;
            -ms-grid-column: 2;
        }
        &:nth-child(n + 1):nth-child(-n + 2) {
            -ms-grid-row: 1;
        }
        &:nth-child(n + 3):nth-child(-n + 4) {
            -ms-grid-row: 2;
        }
    }
    @media (max-width: $screen-sm) {
        border: 1px solid $blackberry-10;
        border-top: none;
        grid-template-columns: 1fr;
        li {
            display: block;
            &:nth-child(odd) {
                // support for ie10, use grid-column-gap for later versions
                margin-right: 0;
            }
            &:nth-child(even) {
                // support for ie10, use grid-column-gap for later versions
                margin-left: 0;
            }
        }
    }
}

ul#consent-section {
    padding: 0rem 1.75rem;
    @media (max-width: $screen-md) {
        padding: 0.1rem 2rem 0rem;
    }
}

.fieldset-display {
    display: contents;
}

.formField .options {
    margin-bottom: 1.5rem;
}

.formField .description {
    font-size: $text-size-sm;
}

// this is used for the email and mobile consent fields and nowhere else at the moment
.communicationsConsent div.rich-text {
    margin-top: -16px;
    margin-left: 1rem;
}

// Wagtail's rich text widget wraps content in p tag by default, but we don't want the default margins here
.communicationsConsent div.rich-text p {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

// the margin-bottom for li.formField above is generally good, but in this section we don't want a margin
#communications-section > ul > li {
    margin-bottom: 0;
}

ul#account-info li.formField.password2 {
    @include breakpoint($screen-md) {
        margin-bottom: 4rem;
    }
}

ul.terms-captcha li {
    margin-left: 1rem;
    &.captcha {
        margin-bottom: 2rem;
    }
}

.ui-helper-hidden-accessible {
    display: none;
}
