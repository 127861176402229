.onboarding_splash_page {
    background-color: $onboarding_splash_page_bg_color;
    color: $color-text;
    font-size: $text-size-sm;
    width: 100%;
    height: 100vh;

    h1 {
        color: $onboarding_splash_page_text_color;
        font-weight: $font-weight-normal;
        font-size: $text-size-xxl;
        line-height: 100%;
        margin-bottom: 1rem;
        margin-top: 1rem;
        @include breakpoint($screen-md) {
            margin-bottom: 2rem;
        }
    }

    .row {
        text-align: center;
        margin-top: 8rem;

        @include breakpoint($screen-md) {
            margin-top: 6rem;
        }
    }

    .rich-text {
        max-width: 570px;
        margin: auto;
        margin-bottom: 2.75rem;
        p {
            font-size: $text-size-lg;
        }
        p,
        h1,
        h2,
        h3 {
            color: $onboarding_splash_page_text_color;
        }

        @media (max-width: $screen-sm) {
            max-width: 262px;
        }
    }
}
