.icon-wrapper {
    margin: 2rem 0;
    padding: 1rem;
    position: relative;
    text-align: center;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -15px;
        left: 50%;
        margin-left: -16px;
        width: 33px;
        height: 33px;
        background-size: 33px;
        background-color: $truth-primary-alternate;
        border-radius: 50%;
        color: $white;
        font-size: $text-size-lg;
        line-height: 34px;
        text-align: center;
    }

    &.ib-reminder:before {
        background: $truth-primary-alternate 4px 4px
            svg-url($icon-inline-bell-reminder, $white, transparent) no-repeat;
    }
    &.ib-reason {
        background-color: $vandr-widget-bg-color;
        border: $vandr-widget-border;
        border-radius: $vandr-widget-border-radius;
        margin-top: $ib-reason-margin-top;
    }
    &.ib-reason:before {
        background: $ib-reason-before-bg;
        height: $vandr-widget-before-height;
        margin-left: $vandr-widget-before-margin-left;
        top: $vandr-widget-before-top;
        width: $vandr-widget-before-width;
    }
    &.ib-vision {
        background-color: $vandr-widget-bg-color;
        border: $vandr-widget-border;
        border-radius: $vandr-widget-border-radius;
        margin-top: $ib-vision-margin-top;
    }
    &.ib-vision:before {
        background: $ib-vision-before-bg;
        background-size: $ib-vision-before-bg-size;
        height: $vandr-widget-before-height;
        margin-left: $vandr-widget-before-margin-left;
        top: $vandr-widget-before-top;
        width: $vandr-widget-before-width;
    }
    &.ib-stats {
        text-align: center;
        font-size: 0.8125rem;

        &:before {
            background: $truth-primary-alternate 6px 4px
                svg-url($icon-inline-bar-graph, $white, "", 28, 28) no-repeat;
        }

        b, /* Wagtail RichText uses <b> instead of <strong> */
		strong {
            color: $blackberry-80;
            font-size: 1.375rem;
            display: block;
            margin-top: 0.75rem;
        }
    }
    @include breakpoint($screen-sm) {
        padding: 1.5rem 2rem 2rem;

        p {
            margin: 0 auto;
            max-width: 80%;
        }
    }
}
