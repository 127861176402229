// Sometimes we might want to write classes that can be used more like modern css utilities but may not be mixins
.bg-color--white {
    background-color: $white;
}

.bg-color--teal {
    background-color: $teal;
}

.bg-color--teal-20 {
    background-color: $teal-20;
}

.width--100 {
    max-width: 100%;
}

.nowrap {
    white-space: nowrap;
}

.no-bottom-border {
    border-bottom: none;
}
