// grid items are stacked in 1 column on small mobile devices
// this var sets the min width to display the 2 column grid
$grid-two-col-min: 600px;

body.homepage .mobile-signup,
.mobile-signup {
    background: $white;
    border: 1.6px solid $truth-deep-purple;
    border-radius: $border-radius-rg;
    display: grid;
    grid-template-areas:
        "heading"
        "text"
        "form";
    grid-template-rows: auto 1fr;
    padding: 1rem;
    position: relative;

    @include breakpoint($grid-two-col-min) {
        column-gap: 2rem;
        grid-template-columns: auto 37%;
    }
    @include breakpoint($screen-md) {
        padding: 3rem;
    }
    // heading width is confined to a single column
    &--heading-single-column {
        padding: 2rem 1rem;
        @include breakpoint($grid-two-col-min) {
            grid-template-areas:
                "heading form"
                "text form";
        }
        @include breakpoint($screen-md) {
            padding: 3rem;
        }

        h2 {
            font-size: 2.625rem;
            font-weight: 800;
            line-height: 130%;
            margin: 0 0 1rem 0;
        }
    }
    // heading width spans two columns
    &--heading-two-column {
        padding: 3rem 1rem;
        @include breakpoint($grid-two-col-min) {
            grid-template-areas:
                "heading heading"
                "text form";
        }
        @include breakpoint($screen-md) {
            padding: 4rem;
        }
    }

    &__heading {
        color: $truth-purple;
        grid-area: heading;
        margin-bottom: 2rem;
        // these only apply when the h2 has mutliple child elements
        // e.g. the success icon + heading text span
        display: flex;
        align-items: baseline;
        flex-direction: column;
        gap: 1rem;
        @include breakpoint($grid-two-col-min) {
            align-items: center;
            flex-direction: row;
            gap: 0.5rem;
        }
    }

    &__subheading {
        grid-area: text;
        @include breakpoint($grid-two-col-min) {
            margin-bottom: 0;
        }
        p {
            white-space: pre-wrap;
        }
        *:first-child {
            margin-top: 0;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }

    &__form {
        grid-area: form;

        form {
            margin-top: 2rem;
            @include breakpoint($screen-md) {
                margin-top: 0;
            }
        }
    }

    .icon {
        display: flex;

        svg {
            height: 2.5rem;
            width: 2.5rem;
            fill: $truth-primary;
        }
    }
    .social-icons {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
    }
    .icon-social-link {
        display: flex;
        width: 2.125rem;
        height: 2.125rem;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        fill: $white;
        border-radius: 3.75rem;
        background: $truth-deep-purple;
        &--tiktok {
            svg {
                width: 1.01563rem;
                height: 1.25rem;
            }
        }
        &--instagram {
            svg {
                width: 1.375rem;
                height: 1.375rem;
            }
        }
        &--youtube {
            svg {
                width: 1.25rem;
                height: 0.875rem;
            }
        }

        svg {
            flex-shrink: 0;
        }
    }
}
.form {
    &__button-wrapper {
        align-items: flex-end;
        column-gap: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1.5em;
        row-gap: 1rem;
        @include breakpoint($grid-two-col-min) {
            column-gap: 1.5rem;
        }
        &--justify-end {
            @include breakpoint($grid-two-col-min) {
                justify-content: flex-end;
            }
        }
    }
}
.formField {
    // possible future global form styles
    &__label {
        background: $white;
        font-size: 0.875rem;
        font-weight: 600;
        margin: 0;
        padding: 0rem 0.25rem;
        position: absolute;
        transform: translate(0.75rem, -50%);
        z-index: 1;
        &--disabled {
            color: $input-color-disabled;
        }
        &--checkbox {
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.25rem;
            letter-spacing: 0.0375rem;
        }
    }
    &__input {
        &[type="tel"] {
            display: flex;
            border: 1px solid $truth-deep-purple;
            border-radius: 0.5rem;
            &:disabled {
                background-color: $input-background-disabled;
                border: 1px solid $input-color-disabled;
            }
        }
    }
    &__checkbox[type="checkbox"] {
        // needed to override form-controls.scss
        & + label {
            border: 0;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.0375rem;
            max-width: unset;
            padding: 2rem 0 0 3rem;
            @include breakpoint($grid-two-col-min) {
                padding: 0.75rem 0 0 2rem;
            }
        }
        & + label:before {
            border-color: $truth-purple;
            margin-left: -3rem;
            margin-top: 0;
            @include breakpoint($grid-two-col-min) {
                margin-left: -2rem;
            }
        }
    }
    &__description {
        font-size: 0.625rem;
        line-height: 130%; /* 0.8125rem */
        margin: 1rem auto 2rem;
        @include breakpoint($grid-two-col-min) {
            margin: 1rem auto;
        }
        &--padding-left {
            @include breakpoint($grid-two-col-min) {
                padding-left: 2rem;
            }
        }
    }

    &--error {
        input,
        input[type="checkbox"] + label:before {
            border-color: $color-error !important;
        }
    }
}
