.create-new-profile-navbar {
    padding: 0.5rem 0 0.5rem;
    background-color: $nav-bg-color;

    @include breakpoint($screen-sm) {
        padding: 1rem 0 1rem;
    }

    .container {
        display: flex;
        flex-direction: row;

        // Empty div for centering logo on desktop.
        div:first-child {
            display: none;
            flex: 1;

            @include breakpoint($screen-sm) {
                display: block;
            }
        }

        .logo-container {
            flex: 0;

            a {
                display: block;

                .logo-truth {
                    width: $nav-logo-width-mobile;
                    height: $nav-logo-height-mobile;
                    --ex-logo-primary: #{$nav-foreground-color};
                    --ex-logo-secondary: #{$nav-foreground-color};

                    @include breakpoint($screen-sm) {
                        height: $nav-logo-height-desktop;
                        width: $nav-logo-width-desktop;
                    }
                }
            }
        }

        nav {
            flex: 1;
            display: flex;
            justify-content: end;

            ul {
                display: flex;
                flex-direction: row;
                margin: auto 0 auto 0;
                padding: 0;

                li {
                    list-style: none;
                    margin: 0 0.5rem;
                }

                a {
                    padding: 1rem 0.5rem;
                    font-size: $nav-font-size;
                    font-weight: 700;
                    text-decoration: none;
                    color: $nav-foreground-color;
                }

                a {
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: underline;
                        text-decoration-thickness: 4px;
                        text-decoration-color: $truth-primary;
                        text-underline-offset: 0.375rem;
                    }
                }
                .language-icon {
                    fill: $nav-foreground-color;
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
