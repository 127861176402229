.captioned-image-block {
    text-align: center;

    .captioned-image {
        padding: 1rem 2rem 0;
        margin-top: 1rem;

        img {
            width: 100%;
        }
        p {
            font-weight: 600;
        }
    }
    .cta-btn-wrapper {
        text-align: inherit;
    }
}
