/************************************************************************************/
//Extend old classes that should be deprecated once we can rewrite all the HTML markup
/************************************************************************************/
.validation-invalid-email {
    @extend .alert-error;
    @extend .validation-message;
    display: none;
}

.validation-used-email {
    @extend .alert-error;
    display: none;
}

.invalid {
    @extend .alert-error;
}

.errorMsg {
    @extend .alert-error;
}

p.error,
.error-text {
    @extend .alert-error;
}

.label {
    @extend label;
}

.standard-form span.errorMessages,
.errorlist {
    @extend .alert-error;
}

.contactUsInfo span.errorMessages {
    display: none;
}

.standard-form li.formField.emailOptin,
.standard-form li.formField.mobileOptin {
    @include breakpoint($screen-sm) {
        margin-bottom: 0;
    }
}

#editProfileForm div.rich-text {
    margin-top: -16px;
}

#editProfileForm .mobileOptin input[type="checkbox"] + label {
    padding-left: 2.5rem;
}

#editProfileForm .emailOptin input[type="checkbox"] + label {
    background: transparent left 3.5rem top 0
        svg-url($icon-inline-send-email, $color-success, "", 45, 45) no-repeat;
    padding-left: 6.5rem;

    &:before {
        margin-left: -5.5rem;
    }
    @include breakpoint($screen-sm) {
        background-position-x: 2.5rem;
        background-position-y: -0.25rem;
        padding-left: 5.5rem;
        margin-bottom: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        &:before {
            margin-left: -4.5rem;
        }
    }
}

#editProfileForm .mobileOptin {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $blackberry-80;

    input[type="checkbox"] + label {
        background: transparent left 3.5rem top
            svg-url($icon-inline-text-message, $color-success, "", 45, 45) no-repeat;
        padding-left: 6.5rem;

        &:before {
            margin-left: -5.5rem;
        }
        @include breakpoint($screen-sm) {
            background-position-x: 2.5rem;
            padding-left: 5.5rem;

            &:before {
                margin-left: -4.5rem;
            }
        }
    }
}

.mobileNumber {
    margin-bottom: 1rem;

    input {
        display: inline-block;
        margin-right: 0.25rem;

        &[name="mobileNumber1"],
        &[name="mobileNumber2"] {
            width: 70px;
        }
        &[name="mobileNumber3"] {
            width: 85px;
        }

        @include breakpoint($screen-xs) {
            &[name="mobileNumber1"],
            &[name="mobileNumber2"] {
                width: 80px;
            }
            &[name="mobileNumber3"] {
                width: 95px;
            }
        }
    }
}

.accordion {
    .fieldset {
        margin-bottom: 2rem;
    }

    .accountInfo .formField,
    .personalInfo .formField,
    .smokingHabits .formField,
    .mobileNumber.formField,
    .mobileCarrierID.formField {
        @extend .col-md-6;
        margin-bottom: 1rem;

        @include breakpoint($screen-md) {
            margin-bottom: 2.5rem;
        }
    }

    @include breakpoint($screen-md) {
        .gender.formField,
        .hundredCigarettes.formField {
            margin-bottom: 0;
        }
        .forgot-password.formField {
            padding-top: 3rem;
        }
        .forgot-password.formField a {
            padding-left: 0.5rem;
        }
    }

    .incomplete {
        height: 0;
        overflow: hidden;
        width: 0;
    }
    span.errorMessages span,
    .complete .incomplete {
        display: none;
    }

    .inline-form-element {
        @include clearfix;

        label {
            float: left;
        }
    }
}

.edit-profile-bttns {
    display: block;
    margin-bottom: 3rem;
    text-align: center;
}

.percentCompleteBlurb {
    color: $color-success;
    font-weight: 700;
    margin-bottom: 2rem;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px 0;
    margin: 0 0 10px 25px;
    list-style: none;
    background-color: $white;
    border-color: $sheetrock-gray;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
}

.ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: 400;
    line-height: 18px;
    color: $warehouse-gray;
    white-space: nowrap;
    text-decoration: none;
}

.ui-state-hover,
.ui-state-active {
    color: $white;
    text-decoration: none;
    background-color: $old-time-blue;
    border-radius: 0;
    background-image: none;
}
