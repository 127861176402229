/* ==========================================================================
   Home page - Wave Navigation
   ========================================================================== */
.wave-navigation {
    padding-bottom: 1rem;
    background: $teal;
    color: $white;
    display: flex;
    flex-wrap: wrap;

    h3 {
        color: $white;
        font-size: 14pt;
        font-weight: 700;

        span {
            display: block;
            margin-bottom: 1rem;
        }
    }
    a {
        color: $white;
        text-decoration: none;
    }
    .container {
        &.slides {
            order: 0;
            min-width: 100%;
        }
        &.slide-pagination {
            order: 1;
        }
    }

    .Wallop-item {
        min-height: 160px;
        width: 100%;
    }

    .items {
        float: none;
    }

    .wave-slider-container {
        min-height: 80px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0.5rem 0;
        }
    }

    .wave-text-nav {
        margin-bottom: 2.5rem;
    }

    .wave-pagination {
        .col-sm-2 {
            width: auto;
            padding: 0;
        }
        div {
            display: inline-block;
            margin: 0 0.25rem;

            a {
                background-color: rgba($white, 0.2);
                border: 1px solid $white;
                border-radius: 50%;
                display: block;
                height: 10px;
                width: 10px;

                span {
                    display: none;
                }
            }

            &.active a {
                background-color: $white;
            }
        }
    }

    @include breakpoint($screen-xs) {
        .Wallop-item {
            min-height: 130px;
        }
    }
    @include breakpoint($screen-sm) {
        .Wallop-item {
            min-height: 110px;
        }
    }
    @include breakpoint($screen-lg) {
        padding-bottom: 0;
        padding-top: 1rem;
        margin-top: 2rem;

        .Wallop-item {
            min-height: 0;
        }
        .container {
            &.slides {
                order: 1;

                .Wallop-list {
                    overflow: visible;
                }
            }
            &.slide-pagination {
                order: 0;
            }
        }
        h3 {
            font-size: 18pt;
            font-weight: 400;
            margin: 0 auto;
            max-width: 75%;
            span {
                display: none;
            }
        }
        .items {
            border-bottom: solid 2px transparent;
            min-height: 80px;
            float: left;
        }
        .wave-pagination {
            .col-sm-2 {
                width: 16.66667%;
                padding: 0.9rem;
                &:first-child {
                    margin-left: 8.33333%;
                }
            }
            div {
                display: block;
                margin: 0;

                &.active {
                    a:before {
                        left: 0;
                        width: 100%;
                    }
                }

                a {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    display: inline-block;
                    height: 65px;
                    padding-bottom: 1rem;
                    position: relative;
                    width: auto;
                    font-size: 14pt;
                    font-weight: 700;

                    &:before {
                        background: $white;
                        bottom: 0;
                        content: "";
                        left: 50%;
                        height: 4px;
                        position: absolute;
                        transition-property: all;
                        transition-duration: 0.3s;
                        transition-timing-function: ease-out;
                        width: 0;
                        z-index: 100;
                    }

                    span {
                        display: inline-block;
                    }

                    &:hover {
                        &:before {
                            left: 0;
                            width: 100%;
                        }
                    }
                }

                &.active a {
                    background-color: transparent;
                }
            }
        }
        .wave-slider-container ul {
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
    }
}
