table {
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    width: 100%;
    margin-bottom: 2rem;

    thead {
        background: $blackberry-10;
        font-size: $text-size-xs;
    }

    th {
        font-weight: 600;
        padding: 0.25rem;
    }

    tr {
        border-bottom: 1px solid $space-metal-gray;
    }

    td {
        vertical-align: top;
        padding: 0.5rem;
    }

    @include breakpoint($screen-sm) {
        thead {
            font-size: $text-size-sm;
        }

        th {
            padding: 0.5rem 1rem;
        }

        td {
            padding: 1rem;
        }
    }
}

dt {
    float: left;
    font-weight: 600;
}

dd {
    float: left;
    margin: 0 20px 0 10px;
}
.ex-table {
    table {
        caption {
            font-size: 1.25rem;
            font-weight: 600;
            text-align: left;
            margin-bottom: 1.875rem;
            margin-top: 0.6rem;
        }

        thead {
            background-color: $forest;
            color: white;

            th {
                vertical-align: bottom;
                font-size: $text-size-xs;
                @include breakpoint($screen-md) {
                    font-size: $text-size-sm;
                }
                font-weight: 600;
                height: 70px;
                padding: 0.5rem 0.39rem;
            }
        }

        tbody {
            td {
                font-size: $text-size-xs;
                padding: 0.5rem 0.39rem;
                text-align: left;
                white-space: normal;
                &:nth-child(1) {
                    @include breakpoint($screen-md) {
                        white-space: nowrap;
                    }
                }
                p {
                    margin: 0;
                }
            }
        }
    }
}
