%faq-tool {
    margin-bottom: 2rem;

    h2.settings {
        margin-top: 3rem;
    }

    table {
        margin-bottom: 0;
    }

    tr {
        border: none;
    }

    td {
        padding: 0.5rem 0;
    }

    .answer {
        background-color: rgba($blackberry-10, 0.3);
        padding: 0.5rem 2rem;
    }
}
