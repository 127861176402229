%button-default {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: $border-radius-button;
    white-space: nowrap;

    &:disabled {
        cursor: default;
    }

    @include breakpoint($screen-sm) {
        align-self: start;
    }
}

@mixin button(
    $type,
    $background-color,
    $hover-color,
    $color: currentColor,
    $border-color: transparent,
    $focus-color: null
) {
    @extend %button-default;

    background-color: $background-color;
    color: $color;
    border: 1px solid $border-color;
    transition: all 0.2s ease-in-out;

    // Header navigation styles
    @if str-index($type, "navigation") {
        height: $btn-height-navigation;
        font: {
            size: $text-size-md;
            weight: 700;
        }
    } @else {
        height: $btn-height;
        font: {
            size: $text-size-lg;
            weight: 800;
        }
    }

    // Text buttons do not take side padding.
    @if str-index($type, "text") {
        padding: 0;
    } @else {
        padding: 0 24px;
    }

    &:hover,
    &:active {
        text-decoration: none;

        @if str-index($type, "secondary") {
            color: $hover-color;
            border-color: $hover-color;
            background-color: transparent;
        } @else if str-index($type, "text") {
            color: $hover-color;
            background-color: transparent;
        } @else {
            background-color: $hover-color;
        }
    }

    &:focus {
        // NOTE: `currentColor` does not register as type "color" and it breaks rgba().
        // So, defaulting to $truth-deep-purple for $focus-color

        @if (not $focus-color and type-of($color) == color) {
            $focus-color: $color;
        } @else if (not $focus-color and type-of($color) != color) {
            $focus-color: $truth-deep-purple;
        }

        @if str-index($type, "secondary") {
            background-color: rgba($focus-color, 0.1);
        } @else if str-index($type, "text") {
            background-color: transparent;
            border-color: transparent;
        } @else {
            border-color: $focus-color;
        }
    }

    &:disabled {
        opacity: 0.5;
        color: $color;
        pointer-events: none;

        @if str-index($type, "secondary") {
            background-color: rgba($color-disabled, 0.1);
        } @else if str-index($type, "text") {
            background-color: transparent;
            border-color: transparent;
        } @else {
            border-color: $color-disabled;
        }
    }

    &.actual-width {
        width: auto;
    }

    @if str-index($type, "tertiary") {
        background-image: svg-url($icon-inline-arrow-next, $color, "", 14, 14);
        background-position: calc(100% - 20px) 50%;
        background-repeat: no-repeat;
        background-size: 14px 14px;
        padding-right: 40px;
    }
}

button,
input[type="button"],
.btn-primary {
    @include button(
        $type: primary,
        $background-color: $btn-primary-color,
        $hover-color: $btn-primary-hover-color,
        $color: $btn-primary-text-color,
        $focus-color: $btn-primary-focus-color
    );
}

.btn-primary--alternative {
    @include button(
        $type: primary,
        $background-color: $btn-primary-alternative-color,
        $hover-color: $btn-primary-alternative-hover-color,
        $color: $btn-primary-alternative-text-color,
        $focus-color: $btn-primary-alternative-focus-color
    );
}

.btn-primary--navigation {
    @include button(
        $type: primary-navigation,
        $background-color: $btn-primary-navigation-color,
        $hover-color: $btn-primary-navigation-hover-color,
        $color: $btn-primary-navigation-text-color
    );
}

.btn-secondary {
    @include button(
        $type: secondary,
        $background-color: $btn-secondary-color,
        $hover-color: $btn-secondary-hover-color,
        $color: $btn-secondary-text-color,
        $border-color: $btn-secondary-border-color
    );
}

.btn-secondary--navigation {
    @include button(
        $type: secondary-navigation,
        $background-color: $btn-secondary-navigation-color,
        $hover-color: $btn-secondary-navigation-hover-color,
        $color: $btn-secondary-navigation-text-color,
        $border-color: $btn-secondary-navigation-border-color
    );
}

.btn-tertiary {
    @include button(
        $type: tertiary,
        $background-color: $btn-tertiary-color,
        $hover-color: $btn-tertiary-hover-color,
        $color: $btn-tertiary-text-color
    );
}

.btn-danger {
    @include button(
        $type: danger,
        $background-color: $btn-danger-color,
        $hover-color: $btn-danger-hover-color,
        $color: $btn-danger-text-color,
        $focus-color: $btn-danger-focus-color
    );
}

.btn-tertiary--text {
    @include button(
        $type: text,
        $background-color: $btn-text-color,
        $hover-color: $btn-text-hover-color,
        $color: $btn-text-text-color,
        $focus-color: $btn-text-border-color
    );
}

.btn-tertiary--text--danger {
    @include button(
        $type: text-danger,
        $background-color: $btn-text-danger-color,
        $hover-color: $btn-text-danger-hover-color,
        $color: $btn-text-danger-text-color,
        $focus-color: $btn-text-danger-border-color
    );
}

.btn-read-more {
    @include button(read-more, inherit, inherit, $teal);
    text-transform: uppercase;
    text-align: left;
    text-decoration: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    height: fit-content;
    margin: 0.25rem 0;

    &:hover,
    &:active,
    &:focus {
        color: $teal;
        text-decoration: none;
    }
    svg {
        fill: currentColor;
        width: 24px;
        height: 24px;
        margin-right: 0.25rem;
    }
}

.btn-close-read-more {
    @include button(read-more, inherit, inherit, $teal);
    border: none;
    font-size: 2rem;
    min-width: fit-content;
    padding: 0.25rem;
    line-height: 1;
    &:hover,
    &:active,
    &:focus {
        color: $teal;
    }
    svg {
        fill: currentColor;
        width: 24px;
        height: 24px;
    }
}

// vestigial from using <a>s as buttons
a.btn {
    padding: 0.5em 1.5em;
    cursor: pointer;
    height: auto;

    &:hover {
        text-decoration: none;
    }
    &.btn-outline {
        color: $forest;
        background-color: white;
        border: 1px solid $forest;
        border-radius: 4px;
        font-weight: 700;
        &:hover {
            background-color: $blackberry-5;
        }
        &[disabled] {
            color: $teal-40;
            border-color: $teal-40;
            background-color: $white;
        }
    }
}

a.btn-partner-logo {
    padding: 0.25rem 1rem;
    margin-bottom: 1rem;
    line-height: 2rem;

    & svg,
    & img {
        float: left;
    }
}

@media (max-width: $screen-lg) {
    a.btn-partner-logo {
        padding: 0.25rem 0.5rem;
    }
}

@media (max-width: $screen-sm) {
    // Accessibility
    // On mobile devices ensure touch target height is at least
    // 48pt (iOS) ==> 64px
    .li-accessible {
        min-height: $button-mobile-min-height;
        a {
            height: $button-mobile-min-height;
            padding: 1.3rem;
        }
    }

    a.btn {
        min-height: $button-mobile-min-height;
        padding: 1.25rem;
    }

    a.btn-partner-logo {
        padding: 1rem 1.25rem 1rem;
        height: auto;
    }

    .link-smooth-scroll {
        height: $button-mobile-min-height;
        padding: 1.25rem;
    }
}

.icon-btn,
.icon-btn-sm,
.icon-btn-xs {
    background-color: transparent;
    border: none;
    margin: 0;
    min-width: unset;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
    }

    svg {
        height: 100%;
        width: 100%;
    }
}

.icon-btn-xs {
    height: 25px;
    width: 25px;
}

.icon-btn-sm {
    height: 50px;
    width: 50px;
}

.icon-btn {
    height: 80px;
    width: 80px;
}

.icon-btn--round-primary {
    background-color: $truth-primary;
    border-radius: 50%;
    &:active,
    &:hover,
    &:focus {
        background-color: $truth-primary;
    }
}

a.btn-cta {
    @include button(primary, $white, $white, $green6);
}

p.cta-btn-wrapper {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    @include breakpoint($screen-md) {
        text-align: left;
        margin-bottom: inherit;
    }
}
