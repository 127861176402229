.activity-section {
    background-color: $activity-section-bg;

    h2 {
        color: $white;
        padding: 24px 0;
    }

    table {
        background-color: $white;
        border-radius: 5px;

        .td-truncate {
            white-space: nowrap;
            overflow: hidden;
            max-width: 0;
            width: 100%;
            text-overflow: ellipsis;
        }

        th {
            font-size: 0.875rem;
            padding-top: 32px;
            padding-bottom: 8px;
        }

        td {
            font-size: 0.75rem;
            padding: 8px;
        }

        th:first-child,
        td:first-child {
            padding-left: 32px;
        }

        th:last-child,
        td:last-child {
            padding-right: 32px;
        }

        tr:last-child > td {
            padding-bottom: 32px;
        }

        td:nth-child(3) {
            text-align: right;
        }

        td:nth-child(4) {
            text-align: center;
            padding-top: 6px;
            padding-bottom: 6px;
        }

        td > svg {
            height: 22px;
            width: 22px;
            fill: $activity-section-star;
        }
    }
}
