.quitdate-advice {
    margin-top: 3rem;
}

/*Set Quit Date calendar*/
.set-quit-date-key {
    padding: 0;

    li {
        list-style-type: none;
        margin: 12px;
        float: left;
    }

    span {
        display: block;
        float: left;
        width: 20px;
        height: 20px;
        margin: -2px 4px 0 0;
    }

    .today span {
        background-color: $teal;
        border: 1px $blackberry-80 solid;
    }

    .recommended span {
        background-color: $blackberry-20;
        border: 1px solid $blackberry-80;
    }
    .quit span {
        border: 1px $green solid;
        color: $teal;
        background: $white url(/static/img/calendar-logo-truth.png) 50% 50% no-repeat;
        background-size: 25px auto;
    }

    a {
        color: $black;
        &:hover {
            cursor: default;
        }
    }
}
.set-quit-date-controls {
    li {
        width: 33%;
        float: left;
        margin: 10px 0 0 0;
        padding: 0;
        list-style-type: none;
        text-align: center;

        a {
            font-weight: 700;
        }
    }
}

.set-your-quit-date {
    .scroll-top {
        height: 18px;
    }
    .learning-scroller {
        height: 679px;
    }
    .quit-date-expected-block {
        background-color: $green;
    }
    .qdc-past {
        background-color: $blackberry-10;
        color: $grayDark;
        .fc-day-number {
            opacity: 0.5;
            filter: alpha(opacity=50);
        }
    }
    .qdc-today {
        background-color: $teal;
        color: $white;
    }
    .qdc-future {
        background-color: $white;
    } /*in future, but not just right*/
    .qdc-future-goldilocks {
        background-color: $blackberry-20;
    } /*just right*/
    .qdc-quitdate {
        border: 1px $green solid;
        color: $green;
        background: $white url(/static/explan-frontend/img/calendar-logo-trans.png) 50% 50%
            no-repeat;
    } /*date set*/

    .fc-other-month {
        border-color: $blackberry-80 !important;
        background-color: $white !important;
        background-image: none !important;
        .fc-day-number {
            display: none;
        }
    }
}

li.fc {
    td {
        &:hover {
            cursor: pointer;
        }
        &.fc-other-month:hover {
            cursor: default;
        }
    }
}

/*datepicker (quit calendar)*/
#datepicker-label {
    @include button(
        $type: primary,
        $background-color: $green,
        $hover-color: $light-green,
        $color: $white
    );
    padding: 0.75rem 0.5rem;
    font-weight: 400;
    line-height: 1rem;
    height: auto;
    font-size: $text-size-lg;
    position: relative;
    width: 100%;

    @include breakpoint($screen-xs) {
        display: inline-flex;
        align-items: center;
        padding: 0 0.5rem;
        position: static;
        width: auto;
        margin-bottom: 0;
    }
}

#datepicker-date {
    display: block;
    font-weight: 700;
    font-size: $text-size-xl;
    margin-left: 0.5rem;
    margin-top: 0.5rem;

    @include breakpoint($screen-xs) {
        display: inline-block;
        margin-top: 0;
    }
}

#datepicker {
    display: inline-block;
    width: 0;
    border: 0;
    margin: 0;
    padding: 0;
    height: 1px;
    background: transparent;
}
