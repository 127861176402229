$dropdown-transition-delay: 0.25s;

// Fade In Up

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUp {
    animation-name: fadeInUp;
    backface-visibility: visible !important;
    animation-duration: 1.5s;
    animation-fill-mode: both;
}

.fadeInUp.delay1 {
    animation-delay: 0.25s;
}
.fadeInUp.delay2 {
    animation-delay: 0.5s;
}
.fadeInUp.delay3 {
    animation-delay: 0.75s;
}
.fadeInUp.delay4 {
    animation-delay: 1s;
}

// Fade-in

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0; /* make things invisible upon start */
    animation: fadeIn;
    animation-fill-mode: both;
    animation-duration: 1s;
}
.fadeIn.delay5 {
    animation-delay: 1.5s;
}

// Fade-out

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
