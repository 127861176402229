.testimonials-container {
        display: flex;
        gap: 2rem;
        flex-direction: column;

        @include breakpoint($screen-md) {
            flex-direction: row;
        }
    }

    .testimonials-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include breakpoint($screen-md) {
            width: 50%;
        }
    }

    .testimonial {
        background-color: $light-green;
        background-image: url("/static/explan-frontend/img/icons-color/big-quote.svg");
        background-repeat: no-repeat;
        background-position: 1.5rem 3rem;
        border-radius: $border-radius-rg;
        padding: 5.8rem 1.5rem 1.5rem;
        text-align: left;

        &.truth-primary-alternate {
            background-color: $truth-primary-alternate;
        }
        &.truth-primary {
            background-color: $truth-primary;
        }

        p {
            margin-top: 0;
            font-size: 1rem;
            line-height: 1.5;

            @include breakpoint($screen-md) {
                font-size: 1.25rem;
            }
        }
    }

    .testimonials-icon {
        display: none;

        @include breakpoint($screen-md) {
            display: block;
            margin-top: 2rem;
            width: 177px;
            height: 177px;
        }
    }
