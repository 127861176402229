%stats-block-tool {
    text-align: center;

    h2 {
        text-align: left;
    }

    .most-likely {
        display: block;
        float: left;
        background: $teal;
        color: $white;
        width: 100%;
        margin: 0;
        padding: 0.75rem 1rem;
    }

    @include breakpoint($screen-sm) {
        .most-likely {
            display: flex;
        }
    }
}

%cigarette-tracker-tool {
    position: relative;
    margin-bottom: 3rem;

    .quit-calendar-view-switcher {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: $text-size-sm;
        font-weight: 600;

        span {
            color: $teal;
            border-bottom: 3px solid $teal;
            padding: 0 0.25rem;
            margin: 0 0.5rem;
        }

        a {
            position: relative;
            color: $color-text;
            padding: 0 0.25rem;
            margin: 0 0.5rem;

            &:hover,
            &:focus,
            &:active {
                color: $teal;
                text-decoration: none;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 999;
                left: 50%;
                right: 50%;
                width: 0;
                bottom: -0.2rem;
                background: $teal;
                height: 3px;
                transition-property: all;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;
            }

            &:hover:after,
            &:focus:after,
            &:active:after {
                left: 0;
                right: auto;
                width: 100%;
            }
        }
    }

    .navigator {
        display: flex;
        justify-content: space-around;
        align-items: stretch;
        background: $teal;
        color: $white;
        width: 100%;
        min-height: 46px;

        h2 {
            flex: 1;
            font-size: $text-size-sm;
            @extend %blue-header;
            margin: auto;
        }

        input[type="button"] {
            @include button(
                $type: primary,
                $background-color: $teal,
                $hover-color: $black,
                $color: transparent
            );
            width: 50px;
            flex-shrink: 0;
            height: auto;
            min-width: unset;
            border-radius: unset;
            background: $teal left 10px top 10px svg-url($icon-inline-arrow-left, $white, $white)
                no-repeat;
        }

        h2 + input[type="button"] {
            background: $teal right 10px top 10px svg-url($icon-inline-arrow-right, $white, $white)
                no-repeat;
        }

        @include breakpoint($screen-xs) {
            h2 {
                font-size: $text-size-md;
            }
        }

        @include breakpoint($screen-sm) {
            h2 {
                font-size: 1.25rem;
            }
        }
    }

    .other-trigger-label {
        display: none;
    }

    .cigarette-tracker {
        text-align: center;

        > a {
            display: flex;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }

        input:focus-visible,
        input:focus,
        input:active {
            border-color: $cigarette-tracker-input-focus-border-color;
        }
    }

    .cell-count input {
        width: 60px;
    }

    .cell-trigger {
        p {
            font-size: $text-size-xs;
            margin: 0.25rem 0;
            font-style: italic;
        }
    }

    #cigarette-tracker-week-view {
        th:not(:first-of-type) {
            text-align: center;
        }
        td:not(:first-of-type) {
            text-align: center;
            vertical-align: middle;
        }
    }

    #day-table {
        thead {
            display: none;
        }

        table,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        td {
            border: none;
            position: relative;
            padding-left: 35%;

            &:before {
                content: "";
                position: absolute;
                top: 6px;
                left: 6px;
                width: 30%;
                padding-right: 10px;
                white-space: nowrap;
                font-weight: 700;
            }
            &.cell-time:before {
                content: "Time";
            }
            &.cell-trigger:before {
                content: "Trigger";
            }
            &.cell-count:before {
                content: "Count";
            }
            &.cell-urgelevel:before {
                content: "Urge Level";
            }
            &:last-of-type {
                text-transform: capitalize;
                a {
                    display: inline;
                }
            }
        }

        @include breakpoint($screen-sm) {
            thead {
                display: table-header-group;
            }

            table {
                display: table;
            }

            tbody {
                display: table-row-group;
            }

            tr {
                display: table-row;
            }

            th,
            td {
                display: table-cell;
            }

            tr.edit-row td:last-of-type a {
                display: inline-flex;
            }

            td {
                border-bottom: none;
                position: relative;
                padding-left: 1rem;

                &:before {
                    content: "";
                    position: relative;
                    top: 6px;
                    left: 6px;
                    width: auto;
                    padding-right: 10px;
                    white-space: nowrap;
                    font-weight: 400;
                }
                &.cell-time:before,
                &.cell-trigger:before,
                &.cell-count:before,
                &.cell-urgelevel:before {
                    content: "";
                    display: none;
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
        }
    }
}
