.large-numbers {
    border-top: 1.6px solid $truth-primary-variant;
}

.numbers-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include breakpoint($screen-md) {
        flex-direction: row;
        gap: 4rem;
    }
}

.number {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    .number__callout {
        color: $truth-primary-alternate;
        font-size: 2rem;
        font-weight: 900;
        line-height: 120%;
        margin: 0;
    }
    .number__description {
        margin: 0;
    }
}
