.contactUsInfo {
    clear: both;
    margin-bottom: 2rem;

    @include breakpoint($screen-sm) {
        width: 70%;
    }

    @include breakpoint($screen-md) {
        width: 60%;
    }
}

.isCC {
    label:not(:first-child) {
        float: left;
    }
}
