.friends-response-dialog,
.container .friends-response-dialog {
    margin: 0.5rem 0;

    .dialog-header {
        background-color: $teal;
        color: $white;
        display: none;
        font-size: $text-size-xl;
        padding: 0.75rem 0;
    }

    .dialog-content {
        background-color: #f9fcfb;

        .response {
            border-bottom: 1px solid $bakers-glaze-white;

            &:nth-child(even) {
                background-color: $cotton-balls-white;
            }
        }
    }

    .friend-says,
    .friend-says + div.rich-text p {
        font-size: $text-size-xl;
    }

    .tell-them,
    .tell-them + div.rich-text p {
        font-style: italic;
    }

    .friend-says,
    .tell-them {
        span {
            display: block;
            font-size: $text-size-md;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 0.25rem;
        }
    }

    @include breakpoint($screen-sm) {
        margin: 2rem 0;

        .friend-says,
        .tell-them {
            span {
                display: none;
            }
        }
        .dialog-header {
            display: block;
        }

        .dialog-content {
            .row {
                align-items: center;
                display: flex;
                justify-content: center;
            }
            .response {
                padding: 1rem 0;
            }
        }
    }

    @include breakpoint(max-width $screen-sm) {
        .friend-says + div.rich-text {
            margin-top: -20px;
        }
        .tell-them + div.rich-text {
            margin-top: -13px;
        }
    }
}
