// default text areas are larger; make this one match
// snakex/ExPlanFrontend/src/stylesheets/_components/_quit-reasons.scss
#dialog textarea {
    border-color: $vision-textarea-border-color;
    border-radius: $vision-textarea-border-radius;
    border-style: $vision-textarea-border-style;
    border-width: $vision-textarea-border-width;
    height: 130px;
    overflow: $vandr-form-textarea-overflow;
    resize: $vandr-form-textarea-resize;
}

.introduction--text--margin-bottom {
    margin-bottom: 12vh;
}
