.accordion {
    h2 {
        @extend %blue-header;
        text-align: left;
        cursor: pointer;

        span {
            @extend .container;
            display: block;
            padding-left: 50px;
            background: $teal right center svg-url($icon-inline-down-arrow, $white, "", 50, 50)
                no-repeat;
        }
    }

    &.on h2 span {
        background: $teal right center svg-url($icon-inline-up-arrow, $white, "", 50, 50) no-repeat;
    }

    &.completeSection {
        h2 span {
            &::before {
                content: " ";
                background: transparent right center
                    svg-url($icon-inline-check-material, $color-success, "", 35, 35) no-repeat;
                height: 35px;
                width: 35px;
                position: absolute;
                margin-left: -40px;
                margin-top: -3px;
            }
        }
    }

    &.incompleteSection {
        h2 span {
            &::before {
                content: " ";
                background: transparent right center
                    svg-url($icon-inline-ui-error-mark, $color-error, "", 35, 35) no-repeat;
                height: 35px;
                width: 35px;
                position: absolute;
                margin-left: -40px;
                margin-top: -3px;
            }
        }
    }

    .fieldset {
        @extend .container;
    }
}

.edit-profile-wrapper #content {
    > h2,
    > p,
    .percentCompleteBlurb,
    .accountLockedBlurb {
        @extend .container;
    }
}
