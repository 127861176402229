/*
  Mad*Pow: copied from ex-plan/sass/responsive-retrofit-plan/_anything-slider.scss
*/
/*
  AnythingSlider v1.8+ Default theme
  By Chris Coyier: http://css-tricks.com
  with major improvements by Doug Neiner: http://pixelgraphics.us/
  based on work by Remy Sharp: http://jqueryfordesigners.com/
*/

/*****************************
  SET DEFAULT DIMENSIONS HERE
 *****************************/
/* change the ID & dimensions to match your slider */
.slider,
.singleSlider {
    list-style: none; /* Prevent FOUC (see FAQ page) and keep things readable if javascript is disabled */
    overflow-y: auto;
    overflow-x: hidden;
}

/******************
  SET STYLING HERE
 ******************
 =================================
 Default state (no keyboard focus)
 ==================================*/
/* Overall Wrapper */
.anythingSlider-tier2 {
    margin: 0 auto; /* 45px right & left padding for the arrows, 28px @ bottom for navigation */
    padding: 0 45px 28px 45px;
}
/* slider window - top & bottom borders, default state */
.anythingSlider-tier2 .anythingWindow {
    border-top: 3px solid $html-gray;
    border-bottom: 3px solid $html-gray;
}
/* Navigation buttons + start/stop button, default state */
.anythingSlider-tier2 .anythingControls a {
    /* top shadow */
    background: $html-gray center -288px repeat-x;
    color: $black;
    border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
}
/* Make sure navigation text is visible */
.anythingSlider-tier2 .anythingControls a span {
    visibility: visible;
}
/* Navigation current button, default state */
.anythingSlider-tier2 .anythingControls a.cur {
    background: $duct-tape-gray;
    color: $black;
}
/* start-stop button, stopped, default state */
.anythingSlider-tier2 .anythingControls a.start-stop {
    background-color: $forest-green;
    color: $cubicle-gray;
}
/* start-stop button, playing, default state */
.anythingSlider-tier2 .anythingControls a.start-stop.playing {
    background-color: $rpg-health-red;
}
/* start-stop button, default hovered text color (when visible) */
/* hide nav/start-stop background image shadow on hover - makes the button appear to come forward */
.anythingSlider-tier2 .anythingControls a.start-stop:hover,
.anythingSlider-tier2 .anythingControls a.start-stop.hover,
.anythingSlider-tier2 .anythingControls a.start-stop .anythingControls ul a:hover {
    background-image: none;
    color: $cubicle-gray;
}

/*
 =================================
 Active State (has keyboard focus)
 =================================
*/
/* slider window - top & bottom borders, active state */
.anythingSlider-tier2.activeSlider .anythingWindow {
    border: none;
}
/* Navigation buttons, active state */
.anythingSlider-tier2.activeSlider .anythingControls a {
    /* background image = top shadow */
    background-color: $sad-olive-green;
}
/* Navigation current & hovered button, active state */
.anythingSlider-tier2.activeSlider .anythingControls a.cur,
.anythingSlider-tier2.activeSlider .anythingControls a:hover {
    /* background image removed */
    background: $sad-olive-green;
}
/* start-stop button, stopped, active state */
.anythingSlider-tier2.activeSlider .anythingControls a.start-stop {
    background-color: $windows-95-green;
    color: $white;
}
/* start-stop button, playing, active state */
.anythingSlider-tier2.activeSlider .anythingControls a.start-stop.playing {
    background-color: $shouty-red;
    color: $white;
}
/* start-stop button, active slider hovered text color (when visible) */
.anythingSlider-tier2.activeSlider .start-stop:hover,
.anythingSlider-tier2.activeSlider .start-stop.hover {
    color: $white;
}
/************************
  NAVIGATION POSITIONING
 ************************/
/* Navigation Arrows */
.anythingSlider-tier2 .arrow {
    top: 50%;
    position: absolute;
    display: block;
}
.anythingSlider-tier2 .arrow a {
    display: block;
    width: 45px;
    height: 140px;
    margin: -70px 0 0 0; /* half height of image */
    text-align: center;
    outline: 0;
    background: no-repeat;
}
/* back arrow */
.anythingSlider-tier2 .back {
    left: 0;
}
.anythingSlider-tier2 .back a {
    background-position: left top;
}
.anythingSlider-tier2 .back a:hover,
.anythingSlider-tier2 .back a.hover {
    background-position: left -140px;
}
/* forward arrow */
.anythingSlider-tier2 .forward {
    right: 0;
}
.anythingSlider-tier2 .forward a {
    background-position: right top;
}
.anythingSlider-tier2 .forward a:hover,
.anythingSlider-tier2 .forward a.hover {
    background-position: right -140px;
}
/* Navigation Links */
.anythingSlider-tier2 .anythingControls {
    outline: 0;
    display: none;
}
.anythingSlider-tier2 .anythingControls ul {
    margin: 0;
    padding: 0;
    float: left;
}
.anythingSlider-tier2 .anythingControls ul li {
    display: inline;
}
.anythingSlider-tier2 .anythingControls ul a {
    font:
        11px/18px Georgia,
        Serif;
    display: inline-block;
    text-decoration: none;
    padding: 2px 8px;
    height: 18px;
    margin: 0 5px 0 0;
    text-align: center;
    outline: 0;
}
/* navigationSize window */
.anythingSlider-tier2 .anythingControls .anythingNavWindow {
    overflow: hidden;
    float: left;
}
/* Autoplay Start/Stop button */
.anythingSlider-tier2 .anythingControls .start-stop {
    padding: 2px 5px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    float: right;
    z-index: 100;
    outline: 0;
}
/***********************
  COMMON SLIDER STYLING
 ***********************/
/* Overall Wrapper */
.anythingSlider {
    display: block;
    overflow: visible !important;
    position: relative;
}
/* anythingSlider viewport window */
.anythingSlider .anythingWindow {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}
/* anythingSlider base (original element) */
.anythingSlider .anythingBase {
    background: transparent;
    list-style: none;
    position: absolute;
    overflow: visible !important;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
// Navigation arrow text; indent moved to span inside "a", for IE7;
//   apparently, a negative text-indent on an "a" link moves the link as well as the text
.anythingSlider .arrow span {
    display: block;
    visibility: hidden;
}
/* disabled arrows, hide or reduce opacity: opacity: .5; filter: alpha(opacity=50); */
.anythingSlider .arrow.disabled {
    display: none;
}
/* all panels inside the slider; horizontal mode */
.anythingSlider .panel {
    background: transparent;
    display: block;
    overflow: hidden;
    float: left;
    padding: 0;
    margin: 0;
}
/* vertical mode */
.anythingSlider .vertical .panel {
    float: none;
}
/* fade mode */
.anythingSlider .fade .panel {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
/* fade mode active page - visible & on top */
.anythingSlider .fade .activePage {
    z-index: 0;
}
/***********************
  RTL STYLING
 ***********************/
/* slider autoplay right-to-left, reverse order of nav links to look better */
.anythingSlider.rtl .anythingWindow {
    direction: ltr;
    unicode-bidi: bidi-override;
}
.anythingSlider.rtl .anythingControls ul {
    float: left;
} /* move nav link group to left */
.anythingSlider.rtl .anythingControls ul a {
    float: right;
} /* reverse order of nav links */
.anythingSlider.rtl .start-stop {
    /* float: right; */
} /* move start/stop button - in case you want to switch sides */

/* probably not necessary, but added just in case */
.anythingSlider,
.anythingSlider .anythingWindow,
.anythingSlider .anythingControls ul a,
.anythingSlider .arrow a,
.anythingSlider .start-stop {
    transition-duration: 0;
    -o-transition-duration: 0;
    -moz-transition-duration: 0;
    -webkit-transition-duration: 0;
}

/**
* bfc
**/
.tab-content .anythingSlider-tier2 {
    height: auto !important;
}
.anythingSlider-tier2 {
    position: relative;
    margin: 12px 0;
    padding: 0 20px;
    .anythingWindow {
        border-width: 1px;
    }
    &.activeSlider {
        .anythingWindow {
            // border-color: $lapis-blue;
            // background: $white;
        }
    }
    .arrow {
        top: 0;
        margin-top: 0;
        background-image: none;
        // background: $lapis-blue;
        width: 20px;
        a {
            margin-top: 0;
            background: transparent;
            color: $white;
            width: 20px;
            &:hover {
                color: $blackberry-80;
            }
        }
    }
    .back {
        left: 0px;
        border-radius: 6px 0 0 6px;
    }
    .forward {
        right: 0px;
        border-radius: 0 6px 6px 0;
    }

    //min nav stacks, goes mobile
    // @media only screen and (max-width: 820px) {
    //   height: auto !important;
    //   width: auto !important;
    //   padding: 0 !important;
    //   .anythingWindow{
    //     width: auto !important;
    //     height: auto !important;
    //     border: 1px solid $lapis-blue;
    //   }
    //   .anythingBase{
    //     position: static !important;
    //     width: auto !important;
    //     height: auto !important;
    //   }
    //   .panel{
    //     width: 100% !important;
    //     height: auto !important;
    //     img{
    //       max-width: 40% !important;
    //       height: auto !important;
    //     }
    //   }
    //   .arrow { display: none !important; }
    // }
}
.anythingSlider .arrow span {
    visibility: visible;
    position: relative;
    top: 40%;
    font-size: 2em;
}
.anythingSlider .arrow.disabled {
    display: block;
}
.anythingSlider .arrow.disabled a {
    display: none;
}
.anythingSlider .panel .container img {
    margin: 0 12px 20px 12px;
    padding: 0;
}
.anythingSlider .panel .container p {
    margin: 0 12px 4px 12px;
    padding: 0;
    line-height: 1.2;
    height: 100px;
}
.anythingSlider .panel .container .why,
.anythingSlider .panel .container .why p {
    height: 100px;
    @media only screen and (max-width: 820px) {
        //min nav stacks, goes mobile
        height: auto;
    }
}
.anythingSlider .panel .container .why-full {
    display: none;
}
.facebox-content .why {
    display: none;
}

@media only screen and (max-width: 820px) {
    //min nav stacks, goes mobile
    .anythingSlider .panel .container .why {
        display: none;
    }
    .anythingSlider .panel .container .why-full {
        display: block;
        padding: 4px 12px;
    }
    .facebox-content .why {
        display: none;
    }
}
.anythingSlider .panel .container h1 {
    font-size: 16px;
    margin: 4px 12px 0 12px;
    padding: 0;
    line-height: 1.1;
}
.anythingSlider #understanding-slip-ups-warning-signs .panel .container h1 {
    display: none;
}
.anythingSlider #understanding-slip-ups-warning-signs .why {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    color: $matte-red-lipstick;
}
.anythingSlider #understanding-slip-ups-warning-signs .why strong {
    color: $conspiracy-gray;
}
.anythingSlider .panel button {
    margin-top: 0;
}

// ul.slider, ul.slider li {width:282px; height:140px;}
// .anythingSlider-tier2 .arrow,
// .anythingSlider-tier2 .arrow a{height: 140px;}
// .anythingSlider-tier2 .anythingWindow{height: 138px;}
/*height accomodates top and bottom borders*/

.tabs ul.slider,
.tabs ul.slider li {
    width: 310px;
    height: 136px;
}
.tabs .anythingSlider-tier2 .arrow,
.tabs .anythingSlider-tier2 .arrow a {
    height: 136px;
}
.tabs .anythingSlider-tier2 .anythingWindow {
    height: 134px;
} /*height accomodates top and bottom borders*/

#common-trigger-slider ul.slider,
#common-trigger-slider ul.slider li {
    height: 112px;
}
#common-trigger-slider .anythingSlider-tier2 .arrow,
#common-trigger-slider .anythingSlider-tier2 .arrow a {
    height: 112px;
}
#common-trigger-slider .anythingSlider-tier2 .anythingWindow {
    height: 110px;
} /*height accomodates top and bottom borders*/

#tab-slippery-situations {
    ul.singleSlider,
    ul.singleSlider li {
        width: 544px;
        height: 120px;
    }
    .anythingSlider-tier2 {
        .anythingSlider-tier2 .arrow,
        .anythingSlider-tier2 .arrow a {
            height: 120px;
        }
        .anythingSlider-tier2 .anythingWindow {
            height: 118px;
        } /*height accomodates top and bottom borders*/
    }
    .anythingSlider {
        .panel .container {
            padding: 10px 0 0 10px;
        }
        .panel .container img {
            margin-right: 20px;
        }
    }

    .why {
        display: none !important;
    }
    .why-full {
        display: block !important;
        padding-right: 20px;
    }
}

#quit-reasons .panel .container img {
    margin-top: 14px;
}
