#facebox,
#language-chooser {
    left: 0;
    position: absolute;
    top: 0;
    text-align: left;
    z-index: 9999;

    .popup {
        background: $white;
        border-radius: $popup-border-radius;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
        position: relative;
    }

    table {
        border-collapse: collapse;

        tr {
            border-bottom: none;
        }
        td {
            border-bottom: 0;
            padding: 0;
        }
    }

    .button {
        width: 100%;
    }

    .body {
        padding: 2rem 2rem 1rem;
        width: 85vw;
    }

    .close {
        background: $white;
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 2px;
        height: 30px;
        width: 30px;

        svg {
            fill: $teal;
        }
    }

    .loading {
        text-align: center;
    }
    .image {
        text-align: center;
    }

    @include breakpoint($screen-sm) {
        .body {
            width: 450px;
        }
        .button {
            width: auto;
        }
    }
    @include breakpoint($screen-md) {
        .body {
            width: 600px;
        }
    }
}

.facebox-content {
    & > h1,
    & > h2,
    & > form > h2 {
        margin-top: 0;
    }
}

#facebox_overlay {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.facebox_hide {
    z-index: -100;
}

.facebox_overlayBG {
    background-color: $black;
    opacity: $popup-backdrop-opacity;
    z-index: 99;
}

.ex-modal-content {
    display: none;
}

.ex-modal-buttons {
    text-align: center;

    a,
    button {
        margin-bottom: 1rem;
        display: block;
    }
    button {
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint($screen-xs) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        button {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.lang-update:before {
    min-width: 18px;
}

@media (max-width: 767px) {
    .lang-update:before {
        min-width: 32px;
    }
}

.show-en,
.show-es {
    display: none;
}

#language-chooser-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    display: none;
}

#language-chooser {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;

    width: 95vw;
    max-width: 750px;

    table {
        margin-bottom: 0;
    }

    button {
        display: block;
        margin: 20px auto 0;
    }

    .label-content {
        display: block;
        margin-left: 16px;
    }

    .lang-update {
        background-color: $snowbird-white;
        display: flex;
        align-items: center;
    }

    @media (max-width: 340px) {
        .body {
            width: 95vw;
            padding: 1rem 1rem 0.5rem;
        }
    }

    .lang-update:before {
        margin-left: -22px;
    }

    @media (max-width: 767px) {
        .lang-update:before {
            margin-left: -42px;
        }
    }
}

dialog[open] {
    animation: dialogFadeIn 400ms ease-out normal;
}

#dialog-content > .container,
.ex-dialog-content > .container {
    max-width: 46rem;
}

// don't think this actually works atm
dialog[close] {
    animation: dialogFadeIn 400ms ease-out normal reverse;
}

@keyframes dialogFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// styles largely copied from facebox
#dialog,
.ex-dialog {
    background: $white;
    border-color: rgb(128, 128, 128);
    border-radius: $dialog-border-radius;
    border: none;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
    position: fixed;

    button {
        margin-left: inherit;
        margin-right: inherit;
    }

    .close {
        background: $white;
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 2px;
        height: 30px;
        width: 30px;

        svg {
            fill: $teal;
        }
    }
    &::backdrop {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
/* can't find a good place to put page specific styles in the current css layout; temporary til we can
make styles consistent for modals, buttons */
#form_VisionForm > span > button {
    margin-left: inherit;
    margin-right: inherit;
}

.ex-read-more-modal-header {
    display: flex;
    justify-content: flex-end;
}

.ex-read-more-dialog {
    position: fixed;
    border: none;
    border-radius: 4px;
    box-shadow: 0 14px 28px #00000026;
    width: 90vw;
    max-width: 650px;
    padding: 0;

    .ex-read-more-container {
        padding: 0.5rem 1rem 2rem;
        background-color: white;

        h2:first-of-type {
            margin-top: 0;
        }
    }
}
