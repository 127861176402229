#blip-partnership-section {
    // mobile-first
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 1.25rem;

    .blip-cta-logo img {
        margin: 0px auto 40px;
        display: block;
        width: 260px;
    }

    @include breakpoint($screen-md) {
        .blip-cta-subheading {
            div,
            p {
                display: inline-block;
                vertical-align: middle;
            }

            display: flex;
            flex-direction: row;
            gap: 4rem;
            // align-content: center
        }

        .blip-cta-logo img {
            max-width: 500px;
        }
    }
}
