.accordion-block {
    .accordion-block__icon {
        flex-shrink: 0;
        height: 24px;
        width: 24px;
        position: relative;
        top: -1.6px;
        svg {
            width: 10.6px;
            height: 5.79px;
            fill: $truth-deep-purple;
            transform: translateX(50%);
            position: relative;
            top: 1px;
            left: 1px;
        }
    }

    .accordion-block__title {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        line-height: 22.4px;

        p {
            flex-grow: 1;
            margin-top: 0;
        }

        cursor: pointer;
    }

    .rich-text {
        margin-bottom: 16px;
        *:first-child {
            margin-top: 0;
        }
    }
}

.accordion-block__title {
    font-size: 24px;

    @include breakpoint($screen-md) {
        font-size: 30px;
    }
}

.accordion-block {
    border-bottom: 1px solid transparentize($truth-deep-purple, 0.8);
    padding-top: 16px;
}

.accordion-block[open] .accordion-block__icon {
    transform: rotate(180deg); // Ensure the icon rotates when the accordion is open
}

.accordion-section {
    max-width: 624px;
}

.accordion-section .accordion-block:last-of-type {
    .rich-text {
        margin-bottom: 20px;
    }
}

.back-to-top,
.expand-all {
    // buttons.scss has some styles set here we need to unset
    align-items: center;
    background: unset;
    border: unset;
    display: inline-flex;
    padding: unset;
    color: $truth-deep-purple;
    font-size: 12px;
    font-weight: 700;
    height: unset;
    justify-content: center;
    line-height: 130%;
    margin: 16px 0;
    min-width: unset;
    text-align: left;
    text-transform: unset;

    &:hover,
    &:focus,
    &:active {
        // buttons.scss has some styles set here we need to unset
        background: unset;
    }

    .back-to-top__icon {
        display: inline-block;
        height: 24px;
        width: 24px;
    }

    svg {
        fill: $truth-purple;
        height: 15px;
        position: relative;
        left: 4.5px;
        top: 4.5px;
        width: 15px;
    }
}
