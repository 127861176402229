.dismissible-message-banner.success,
.dismissible-message-banner.site-updates {
    background-color: $seashell-green;
    color: $light-forest-green;
    button.icon-btn-xs {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
        width: 44px;
        height: 44px;
    }
    svg {
        fill: $light-forest-green;
        width: 30px;
        height: 30px;
    }
    .col-xs-1 > svg {
        margin: 1.15rem;
    }
    .col-xs-1.left-icon > svg {
        width: 40px;
        height: 40px;
    }
    h2 {
        color: $light-forest-green;
        font-weight: bold;
        margin-bottom: -0.5rem;
    }
}

@media screen and (width: 280px) and (max-height: 653px) {
    .dismissible-message-banner.success,
    .dismissible-message-banner.site-updates {
        button.icon-btn-xs {
            margin-left: -1.5rem;
        }
    }
}

@media screen and (max-width: 520px) {
    .dismissible-message-banner.success,
    .dismissible-message-banner.site-updates {
        .col-xs-1 > svg {
            margin-left: 0px;
        }
        p {
            margin-left: -2rem;
            padding-top: 0.5rem;
            width: 150%;
        }
        h2 {
            padding-left: 1rem;
            padding-top: 2px;
        }
    }
}

@media screen and (min-width: 521px) and (max-width: 720px) {
    .dismissible-message-banner.success,
    .dismissible-message-banner.site-updates {
        .col-xs-1 > svg {
            margin-left: 0px;
        }
        button.icon-btn-xs {
            margin-left: 3.25rem;
        }
        p {
            width: 136%;
        }
    }
}

@media screen and (min-width: 721px) and (max-width: 867px) {
    .dismissible-message-banner.success,
    .dismissible-message-banner.site-updates {
        button.icon-btn-xs {
            margin-left: -1.5rem;
        }
    }
}
