/* ==========================================================================
   Home page - main banner area
   ========================================================================== */

.join-becomeAnEX,
.make-a-change,
.why-it-works,
.wave-navigation,
.cta-get-started {
    text-align: center;
}

.logo-area {
    min-height: 80px;
}

/* ==========================================================================
   Home page - Join BecomeAnEX area
   ========================================================================== */
.join-becomeAnEX {
    h2 {
        margin-bottom: 50px;
    }

    .icon-box {
        padding-bottom: 40px;

        .icon-graphic {
            margin-bottom: 30px;
            svg {
                width: 100%;
                height: auto;
                max-height: 90px;
            }
            .callout {
                height: 0px;
                font-style: italic;
                font-weight: 600;
                color: $orangeDark;
                font-size: $text-size-lg;
            }
        }
    }
}

/* ==========================================================================
   Home page - Why It Works
   ========================================================================== */
.why-it-works {
    .photo {
        padding-bottom: 10px;

        img {
            max-width: 20rem;
            width: 100%;
        }
    }
    .content {
        max-width: 30rem;
        margin: 0 auto;
        padding: 0 5%;
    }

    @include breakpoint($bp-md) {
        .photo img {
            max-width: 100%;
        }
        .content {
            max-width: 100%;
        }
    }
}

/* ==========================================================================
   Home page - Get Started Call to action
   ========================================================================== */
.cta-get-started {
    margin-bottom: 2rem;

    h2 {
        font-size: 25px;
        margin-top: 0px;
    }
}
