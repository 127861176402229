.author-page {
    background-color: $background-color-light;
    &__header {
        border-bottom: 1.6px solid $truth-deep-purple;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 3rem;

        @include breakpoint($screen-md) {
            align-items: flex-end;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 3.4rem;
            padding-bottom: 2.38rem;
        }
    }

    &__title {
        font-size: 3.5rem;
        grid-area: title;
        line-height: 100%; /* 3.5rem */
        margin-bottom: 0.5rem;

        @include breakpoint($screen-md) {
            font-size: 4.375rem;
        }
    }
    &__appointments {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.4;
    }

    &__image {
        grid-area: image;
        text-align: center;
        min-width: 270px;

        img {
            border-radius: 50%;
        }
    }

    &__body {
        display: grid;
        grid-template-areas:
            "bio"
            "affiliation"
            "articles"
            "expertise"
            "credentials";
        grid-template-columns: 1fr;
        margin-bottom: 3rem;
        row-gap: 2rem;

        @include breakpoint($screen-md) {
            gap: 2rem 2rem;

            /* We add a 3rd column in the center to achieve the overlapping "Masonry" look 
               of the content. Increase the percentage, to increase the amount of overlap.
            */
            grid-template-columns: 1fr 10% 1fr;

            /* Default grid template with all content areas */
            grid-template-areas:
                "bio         bio      affiliation"
                "expertise   articles articles"
                "credentials articles articles";

            /* Grid without Expertise, Credentials at top of Row 2 */
            &:not(:has(.author-page__expertise)) {
                grid-template-areas:
                    "bio         bio      affiliation"
                    "credentials articles articles";
            }
            /* Grid without expertise or credentials, Articles in Row 2 left column
               Articles still spans only 2 columns */
            &:not(:has(.author-page__expertise, .author-page__credentials)) {
                grid-template-areas:
                    "bio      bio      affiliation"
                    "articles articles .";
            }
        }
    }

    &__bio {
        grid-area: bio;
    }

    &__affiliation {
        align-items: top;
        display: flex;
        grid-area: affiliation;
        justify-content: center;
        @include breakpoint($screen-md) {
            margin-top: 4rem;
            justify-content: end;
        }
        &--content {
            width: 378px;
            text-align: center;
        }
    }

    &__expertise {
        grid-area: expertise;
    }

    &__articles {
        grid-area: articles;
        h2 {
            margin-top: 0;
        }
        &--list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__credentials {
        grid-area: credentials;
        &--content {
            border-radius: 1.25rem;
            background-color: $truth-purple;
            grid-area: credentials;
            padding: 1.5rem;
            @include breakpoint($screen-md) {
                max-width: 20rem;
            }
        }

        h2 {
            margin-top: 0;
        }
        a {
            color: $truth-deep-purple;
        }
    }
}
