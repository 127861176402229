/************************************************************************************/
//Extend old classes that should be deprecated once we can rewrite all the HTML markup
/************************************************************************************/

// Video wrapper on "How Nicotine Addiction Works" page
#videoWrapper {
    @extend %video-wrapper;
}

#videoList {
    @extend %video-list;
}

// Warning text are (above footer)
.warning {
    @extend %warning-text;
}

// Show for mobile
.mobile {
    display: block;

    @include breakpoint($screen-sm) {
        display: none;
    }
}

// FAQ Wrapper - quitting-smoking-faq.php
.faq-wrapper {
    @extend %faq-tool;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .answer .rich-text a {
        text-decoration: underline dotted;
    }
}

.preheadlineblock {
    display: none;
}

// Smoking Trigger Tool - beat-your-smoking-triggers.php
#separationForm {
    @extend %trigger-tracker-tool;
}

.exercise-answer {
    display: none;
}

// Cigarette Tracker - track-your-cigarettes.php
#cigarette-tracker {
    @extend %cigarette-tracker-tool;
}

// Stats Block on Cigarette Tracker - track-your-cigarettes.php
#stats-block {
    @extend %stats-block-tool;
}

#supportForm {
    ol {
        padding: 0;
        font-weight: 700;

        textarea,
        input {
            font-weight: 400;
        }

        li {
            margin-bottom: 2rem;
        }
    }
}

// Get Started modal
.testimonials {
    background-color: mix($white, $teal, 95%);
    list-style: none;
    padding: 0;

    .testimonial {
        padding: 1rem 1rem 4rem 1rem;
        position: relative;
        text-align: center;

        &:before {
            content: "\201C";
            background-size: 33px;
            background-color: $teal;
            border-radius: 50%;
            color: $white;
            display: block;
            font-family: "Bookman Old Style";
            font-size: 2.813rem;
            font-weight: 700;
            height: 33px;
            left: 50%;
            line-height: 1.1;
            margin-left: -16px;
            position: absolute;
            text-align: center;
            top: -15px;
            width: 33px;
        }

        span {
            display: block;
            bottom: 0.5rem;
            left: 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        img {
            border: 2px solid $teal;
            border-radius: 50%;
            display: inline-block;
            height: 36px;
            margin-right: 0.75rem;
            width: 36px;
        }

        p.quote {
            font-size: $text-size-md;
            font-weight: 300;
            line-height: 1.75;
            margin-bottom: 0;

            &:before,
            &:after {
                content: '"';
            }
        }

        p.username {
            display: inline-block;
        }
    }
}
p.cta-btn {
    text-align: center;
}
.existingAccount {
    font-size: 0.938rem;
    text-align: center;
}

.whichMedication-form {
    .whichMedication,
    .didUseMedication {
        @include clearfix;
    }

    @include breakpoint($screen-sm) {
        .checkbox_array_container,
        .didUseMedication input + label {
            float: left;
            white-space: nowrap;
            margin-right: 3rem;
        }
    }
}

.unsubscribe-form {
    .footnote {
        font-style: italic;
        margin: 1.5rem 0;
    }
    @include breakpoint($screen-sm) {
        .unsubReasonOther {
            margin-left: 2rem;
        }
    }
    @include breakpoint($screen-md) {
        .unsubReasonOther {
            width: 60%;
        }
    }
}

// LDCT registration page
// this is currently a dead page
body.ldct-regform {
    @extend %page-background-image;
    display: block;

    .partner-logo {
        display: block;
        margin: 2rem auto 4rem;
    }

    .standard-form span.errorMessages {
        padding-top: 0.5rem;
    }

    #errorBox span.errorMsg,
    .standard-form span.errorMessages,
    .standard-form span.errorMessages span {
        display: none;
        &.on {
            display: block;
        }
    }
}

.ldct-wrap {
    background-color: rgba($white, 0.85);
    margin: 1rem;
    padding: 1rem 1.5rem;
    text-align: center;

    li {
        padding: 1rem 0;
        text-align: left;
    }

    label span {
        font-style: italic;
        font-weight: 400;
    }

    @include breakpoint($screen-sm) {
        margin: 2rem auto 0;
        max-width: 650px;
        padding: 2.5rem 3rem;
        width: 100%;

        ul {
            margin: 0 auto;
            width: 80%;
        }
    }

    @include breakpoint($screen-md) {
        margin: 4rem auto 0;
        padding: 2rem 4.5rem 3rem;
    }
}
