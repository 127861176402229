.quit-date-counter {
    background-color: $quit-date-widget-bg;

    h2,
    h3,
    p {
        color: $quit-date-widget-text-color;
    }

    border-radius: $quit-date-border-radius;
    border: $quit-date-border;
    // to avoid layout shifts between states
    min-height: 324px;

    @include breakpoint($screen-sm) {
        min-height: 232px;
    }

    #quitdate__input {
        color: $color-text;

        &.existing-date {
            color: $quit-date-widget-text-color;
        }
    }

    .quit-date-counter-inner {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;

        .icon {
            @extend .col-sm-5;
            padding: 0;
            display: flex;
            justify-content: center;

            svg,
            img:not(.payer-logo) {
                display: block;
                fill: $quit-date-widget-text-color;
                height: 110px;
                width: 100px;
            }

            img.payer-logo {
                display: block;
                background-color: $white;
                padding: 1rem;
                border-radius: 3px;
                margin-top: 0;
                margin-bottom: 1rem;
                max-height: 132px;
                max-width: 132px;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            text-align: center;

            p {
                font-size: 0.8rem;
            }

            h2 {
                margin-top: 0;
                font-weight: 400;
            }

            @include breakpoint($screen-sm) {
                text-align: left;
            }
        }
    }

    header {
        h3 {
            margin: 0;
            margin-right: auto;
        }

        // pencil edit icon
        button.btn {
            margin: 0;
            padding: 0;
            height: 30px;

            svg {
                fill: $quit-date-widget-text-color;
            }
        }
    }
}
