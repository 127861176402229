.vandr {
    background-color: $vandr-bg-color;
}

.icon-feature-wrapper {
    align-items: center;
    background-color: $truth-primary;
    border-radius: $vandr-widget-empty-border-radius;
    display: flex;
    height: 143px;
    justify-content: center;
    margin: 0 auto;
    max-width: 163px;
    img {
        height: 114px;
    }
}

.quit-reason-empty {
    display: none;
    @include clearfix;
    background-color: $widget-background-color-light;
    border: $vandr-widget-empty-border;
    border-radius: $vandr-widget-empty-border-radius;
    margin: 2rem 0;
    padding: 1rem 1rem 2rem 1rem;
    position: relative;

    h3 {
        margin-bottom: $vandr-widget-empty-heading-mb;
        @include breakpoint($screen-sm) {
            margin-top: $vandr-widget-empty-heading-mt;
        }
    }

    .icon-feature {
        fill: $teal;
    }

    .quit-reason-empty-icon {
        @include breakpoint($screen-sm) {
            padding-left: 0;
        }
    }

    .icon-feature-wrapper {
        img {
            height: 95px;
        }
    }

    .why-i-quit-add-link {
        height: auto;
        min-height: $btn-height;
        white-space: normal;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    svg {
        height: 150px;
        width: 100%;
    }

    @include breakpoint($screen-sm) {
        text-align: left;
        padding: 1rem $vandr-widget-empty-pl-sm;
    }
    // Display stacked on onboarding pages for all browser widths
    .form-wrapper & {
        > div {
            float: unset;
            width: unset;
        }
        h3 {
            margin-top: revert;
        }
        .quit-reason-empty-icon {
            padding-left: 15px;
        }
    }
}

.writing {
    padding: 0;
    .icon-wrapper {
        background-color: $widget-background-color-light;
        text-align: left;
        p {
            margin-left: 0;
            font-size: $text-size-lg;
        }
    }
    .edit-vision {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        margin: 0;
        padding: 0;
        height: 30px;

        svg {
            fill: $color-text;
        }
    }
    @include breakpoint($screen-md) {
        padding: 0 2rem;
    }
}

.your-vision-empty {
    @include clearfix;
    margin: 2rem 0;
    padding: 1rem 1rem 2rem 1rem;
    background-color: $widget-background-color-light;
    border: $vandr-widget-empty-border;
    border-radius: $vandr-widget-empty-border-radius;

    h3 {
        margin-bottom: $vandr-widget-empty-heading-mb;
        @include breakpoint($screen-sm) {
            margin-top: $vandr-widget-empty-heading-mt;
        }
    }

    .icon-feature {
        fill: $blackberry-80;
    }

    .your-vision-empty-icon {
        @include breakpoint($screen-sm) {
            padding-left: 0;
        }
    }

    .icon-feature-wrapper {
        align-items: center;
        background-color: $truth-primary-alternate;
    }

    .why-i-quit-add-link {
        height: auto;
        min-height: 40px;
    }

    svg {
        height: 150px;
        width: 100%;
    }

    @include breakpoint($screen-sm) {
        text-align: left;
        padding: 1rem $vandr-widget-empty-pl-sm;
    }
}

.reason-wrapper-slider {
    @extend .icon-wrapper-slider;
    display: none;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding-top: $ib-reason-li-top;
    }

    .why-i-quit-add-link,
    .why-i-quit-edit-link,
    .edit-vision {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        margin: 0;
        padding: 0;
        height: 30px;

        svg {
            fill: $color-text;
        }
    }

    .why-i-quit-edit-link,
    .edit-vision {
        margin-right: 2rem;
    }

    .icon-wrapper {
        text-align: left;
        p {
            margin-left: 0;
        }
    }

    .reason {
        font-size: $text-size-lg;
    }

    .ib-reason {
        background-color: $widget-background-color-light;
    }

    .why,
    .why-full {
        display: none;
    }
}

.Wallop-slider.reason-wrapper-slider {
    .wallop-next-prev {
        margin-top: $ib-reason-sliderbtn-margin;
    }

    .dots {
        background-color: transparent;
    }
}

.personal-reasons-form {
    .blurb {
        margin-bottom: 2rem;
    }

    label {
        font-weight: 400;
        margin-bottom: 1rem;
    }

    textarea {
        height: 130px;
        border-radius: $dialog-border-radius;
        overflow: $vandr-form-textarea-overflow;
        resize: $vandr-form-textarea-resize;
    }

    .ex-modal-buttons {
        margin-top: 2rem;
    }

    .alert-error label {
        color: $color-error;
    }
}
