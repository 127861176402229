#scout-carousel {
    background-color: $blackberry;
    margin-top: 40px;
    padding-top: 40px;

    .sec-title {
        h1,
        h2,
        h3,
        p {
            color: $white;
            max-width: 700px;
        }
    }

    .sec-container {
        display: flex;
        flex-direction: row;
        align-items: end;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .cards {
        display: flex;
        list-style: none;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        margin-bottom: 40px;
        padding: 25px 0;
        gap: 20px;

        &::-webkit-scrollbar {
            background-color: transparent;
            height: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $teal-60;
            height: 2px;
        }

        @include breakpoint($screen-sm) {
            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    .nav-buttons {
        display: none;
        justify-content: flex-end;
        gap: 1rem;

        @include breakpoint($screen-sm) {
            display: flex;
        }

        button {
            all: initial;
            cursor: pointer;
            background-color: $white;
            border-radius: 50%;

            &:disabled {
                background-color: $blackberry-40;
            }

            svg {
                transform: scale(0.5);
                fill: $truth-primary;
                height: 44px;
                width: 44px;
            }
        }
    }

    .card {
        display: flex;
        flex-direction: row;
        flex: 0 0 100%;
        max-width: 344px;
        min-width: 344px; // Prevents card from shrinking on small screens
        margin: 0;
        background: white;
        border-radius: $border-radius-card;
        box-shadow: $md-elevation-1dp;
        scroll-snap-align: start;
        transition: all 0.2s;
        height: 185px;

        @include breakpoint($screen-sm) {
            height: 432px;
            width: 100%;
            flex-direction: column;
        }
    }

    .card--swap-all {
        background-color: $widget-background-color-light;

        .card__image {
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: none;

            svg {
                height: 124px;
                width: 124px;
            }
        }

        h3 {
            font-size: 0.87rem;
            color: $teal;
            font-weight: 700;
            margin-bottom: 4px;
            margin-top: 0;

            @include breakpoint($screen-sm) {
                color: $blackberry;
                font-size: 1rem;
                margin-bottom: 8px;
            }
        }

        p {
            color: $ex-black;
            padding-bottom: 8px;
            margin: 5px;
        }
    }

    .card--swap-all.hide {
        display: none;
    }

    .card:hover {
        box-shadow: $md-elevation-4dp;
    }

    .card__image {
        display: flex;
        justify-content: center;
        align-items: end;
        font-size: 0rem;
        font-weight: 700;
        line-height: 0rem;
        color: $white;
        width: 25%;
        height: 80px;
        margin: 16px 4px 8px 16px;
        object-fit: cover;
        background-size: cover;
        background-position: center;

        @include breakpoint($screen-sm) {
            width: 100%;
            height: 190px;
            margin: 0;
            border: none;
            border-top-left-radius: $border-radius-card;
            border-top-right-radius: $border-radius-card;
            font-size: 0.85rem;
            line-height: 1rem;
            padding-bottom: 24px;

            &.mayo-blog {
                color: $truth-primary-variant;
            }
        }
    }

    .card__image--svg {
        &.mayo-blog {
            background-color: $sand;
        }
        &.community-post {
            background-color: $teal;
        }

        background-repeat: no-repeat;
        background-size: 100% 80%;

        @include breakpoint($screen-sm) {
            background-size: 100% 50%;
            background-position: 50% 36px;
        }
    }

    .card__body {
        display: flex;
        flex-direction: column;
        flex-grow: unset;
        justify-content: space-between;
        padding: 12px;
        width: 70%;

        @include breakpoint($screen-sm) {
            flex-grow: 1;
            width: 100%;
            justify-content: space-between;
            padding: 12px 16px 12px 16px;
        }

        .content {
            display: flex;
            flex-direction: column;
            flex-grow: unset;
            position: relative;

            @include breakpoint($screen-sm) {
                flex-grow: 1;
            }

            .type {
                color: $truth-orange;
                font-size: 0.65rem;
                font-weight: 700;
                letter-spacing: 0.1rem;
                text-transform: uppercase;

                @include breakpoint($screen-sm) {
                    margin-bottom: 4px;
                }
            }

            .username {
                font-size: 10px;

                &.hide {
                    display: none;
                }
            }

            h4 {
                margin: 4px 0;
                color: $truth-primary-variant;
                font-size: 0.85rem;
                font-weight: 700;
                line-height: 0.85rem;

                @include breakpoint($screen-sm) {
                    line-height: 1rem;
                }
            }

            .snippet {
                max-height: 75px;
                margin: 0;
                text-overflow: ellipsis;
                font-size: 0.79rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                @include breakpoint($screen-sm) {
                    max-height: none;
                    -webkit-line-clamp: none;
                    margin-bottom: 4px;
                }
            }

            .status {
                display: flex;
                margin-top: 10px;
                position: absolute;
                top: 80px;
                margin-left: -95px;
                width: 50%;
                overflow-wrap: break-word;

                @include breakpoint($screen-sm) {
                    position: unset;
                    top: 0;
                    margin-left: 0;
                    margin-top: 4px;
                }

                .status-text {
                    margin: auto 0 auto 4px;
                    color: $forest;
                    font-size: 0.7rem;
                    font-weight: 700;
                    letter-spacing: 0.05rem;
                    opacity: 50%;
                }

                svg {
                    height: 22px;
                    width: 22px;
                    fill: $forest;
                    opacity: 50%;
                }
            }

            .status.read {
                .status-text,
                svg {
                    opacity: 100%;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;

            .btn {
                height: 34px;
                min-width: 120px;
                font-size: 0.75rem;
                font-weight: 700;
                letter-spacing: 0.1rem;
                min-height: auto;
                padding: 7px 8px;

                @include breakpoint($screen-sm) {
                    padding: 6px 18px;
                    min-height: unset;
                }
            }

            .swap-button {
                margin-right: 8px;
                min-width: unset;

                @include breakpoint($screen-sm) {
                    margin-right: 0;
                }
            }
        }
    }
}
