.icon-box-list-container {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .dots {
        background-color: $icon-box-list-background-color;
        padding: 1rem;
    }
}
.icon-box-list {
    @include clearfix;
    background-color: $icon-box-list-background-color;
    text-align: center;
    margin: 0 0 1rem;

    h2 {
        font-size: 1.063rem;
    }

    .icon-feature {
        height: 130px;
        padding: 1rem;
        width: 130px;
    }

    @include breakpoint($screen-sm) {
        margin: 0 0 1rem 0;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: left;

        .icon-feature {
            height: auto;
            padding: 1rem;
            width: 100%;
        }
    }
}
