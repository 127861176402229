.program-complete-message {
    background-color: $seashell-green;
    padding: 20px 0 10px 0;

    svg {
        width: 30px;
        height: 30px;
        fill: $light-forest-green;
        margin-bottom: 7px;
        margin-left: 5px;
    }

    h1 {
        color: $light-forest-green;
        font-weight: bold;
        display: inline;
    }
}
