a {
    color: $link-color;
    text-decoration: underline dotted;
    word-wrap: break-word;
    text-underline-offset: 0.15em;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    &.btn {
        text-decoration: none;
    }

    &.inactive {
        color: $grayLight;
        cursor: default;
        &:hover {
            text-decoration: none;
        }
    }
}

.outline {
    outline-style: solid;
    outline-color: $blackberry-80;
}
