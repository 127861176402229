@import "_tools/mixins";

// Image path
$img-path: "../img/";

/* Global Variables */
:root {
    --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
}

/**
* ==================================================
*		Colors
* ==================================================
*/

/* --- Primary Colors (no swaps) -------------------- */

$amaranth: #ab1f40;
$black: #000;
$blackberry-5: #f4f3f6;
$blackberry-10: #e9e7ec;
$blackberry-20: #d6cfda;
$blackberry-40: #aea0b6;
$blackberry-60: #867293;
$blackberry-80: #5e436f;
$blackberry-text: #2a154b;
$blackberry: #36154c;
$blackShadow: #00000029;
$dark-slate-gray: #365d52;
$dark-teal: #6cb6a2;
$ex-black: #090024;
$forest: #004b5e;
$green: #b5cd35;
$light-forest-green: #008055; // in redesign-variables it's overridden as $moss. was #498f50;
$light-green: #d4ed43;
$moss: #008055;
$pastel-yellow: #ffff99;
$sand: #f8f3ef;
$seashell-green: #ecf3ec; // Stays the same in the redesign
$teal-10: #e6f1f3;
$teal-20: #cce4e8;
$teal-40: #99c8d0;
$teal-60: #66adb9;
$teal-80: #3392a2;
$teal: #00778b;
$truth-dark-orange: #fc4403;
$truth-orange-light: #ff9924;
$truth-orange: #ff7900;
$truth-teal: #00778b;
$white: #fff;

/* --- Primary Colors (swaps) -------------------- */
$activity-circle-complete: $blackberry-80;
$activity-circle-incomplete: $blackberry-10;
$activity-section-bg: $forest;
$activity-section-star: $green;

$cigarette-tracker-input-focus-border-color: $teal;
$color-error-text: $amaranth;
$cta-primary-bg-color: $teal;
$ex-label-text-color: $ex-black;
$green6: black;
$heading-color: $blackberry;
$heading-color-dark: $white;
$kalendae-bg: $white;
$local-navigation-button-color: $forest;
$orangeDark: black;
$purple: black;

$quit-band-primary-color: $blackberry;
$quit-band-bg: initial;
$quit-band-heading-color: $white;
$quit-band-text-color: $teal;
$quit-link-bg: $white;
$quit-link-border: initial;
$quit-link-border-color: $teal;
$quit-link-border-radius: initial;

$quit-date-in-the-future-color: $blackberry;
$quit-date-in-the-past-color: $blackberry;
$quit-date-picker-hover: $white; // pointed to $grayDark;
$quit-date-today-color: $blackberry;
$quit-date-widget-bg: $forest;
$quit-date-widget-text-color: $white;
$quit-date-border: 0;
$quit-date-border-radius: initial;

$widget-background-color-light: $blackberry-10;
$page-hero-bg-color: $forest;
$quit-resources-section-bg: $teal-10;
$site-footer-bg: $blackberry;
$site-footer-text: $white;

/* --- Grays -------------------- */
$grayWhite: $blackberry-5;
$grayWhite2: black;
$grayLight: black;
$grayLight2: black;
$grayMedium: black;
$grayMedium2: black;
$grayDark: $white;
$grayDark2: black;
$grayDark3: black;
$platinum: #e3e7e8;
$platinum-darker: #ededed;

/* --- Navigation --- */
$platinum-dark: #e2e5e7;
$snow: #fafbfb;
$anti-flash-white: #f0f2f3;
$white-smoke: #f6f8f8;
$white-smoke-2: #f5f5f5;
$primary-nav-active-color: $teal-40; // when an element has an active or hover state

$secondary-nav-text-color: $teal;
$secondary-nav-fill: $teal;
$secondary-nav-active-color: $forest;

/* ---- Registration ---- */
$floral-white: #fefaf3; // used on california promotional overlay
$metal-gray-blue: #414e5a; // form label text color
$tangerine-yellow: #face00;
$reg-header-color: $teal;
$reg-heading-text-color: $white;

/* ---- Tooltips ---- */
$blue-gray-asphalt: #26323d;

/* ---- Form Controls ---- */
$white-smoke-3: #f8fdfb;
$snowbird-white: $white;
$maximum-blue: #0000ff;
$x11-gray: #bbbbbb;
$old-time-blue: #0088cc;
$warehouse-gray: #555555;

/* ---- Structure ---- */
$pale-cornflower-blue: #b3d4fc;
$maximum-yellow: #ffff00;

/* ---- Layout overrides ---- */
$holstein-white: #fbfcfc;

/* ---- Kalendae / date picker ---- */
$smurf-blue: #1072a5;
$faded-blueberry: #7ea0e2;
$kiddie-pool-cyan: #6dd4fe;
$washed-out-lavender: #c4d4f1;
$electric-blue: #19aefe;
$pastel-green: #6fdf81;
$dried-tar: #333333;
$sheetrock-gray: #cccccc;
$eraser-gray: #999999;

/* ---- Anything Slider ---- */
$html-gray: #777777;
$duct-tape-gray: #888888;
$conspiracy-gray: #666666;
$rpg-health-red: #880000;
$forest-green: #004400;
$windows-95-green: #008800;
$sad-olive-green: #7c9127;
$lapis-blue: #225fa3;
$matte-red-lipstick: #b51e3b;
$cubicle-gray: #dddddd;
$shouty-red: #dd0000;

/* ---- Wallops Slider ---- */
$wallops-pagination-dots-inactive: $widget-background-color-light;

/* ---- Friends response dialogue ---- */
$bakers-glaze-white: #ecf1f1;
$cotton-balls-white: #f2f8f7;

/* ---- Tables ---- */
$space-metal-gray: #3e4f59;
$zebra-row-line-background: rgba($blackberry-10, 0.875);

/* --- Application -------------------- */
$truth-primary: $teal; // the primary vs not is not consistent currently
$truth-primary-variant: $teal;
$truth-background-primary: $blackberry-80;

$truth-primary-alternate: $teal;
$truth-tertiary: $teal;
$link-color: $teal;

$color-text: #444;
$color-text-light: $white;

$color-background: $white;
$form-label-text: $metal-gray-blue;

$color-disabled: $grayLight;
$color-border-disabled: $grayMedium;

$color-success: $moss;
$color-warning: $tangerine-yellow;
$color-error: $amaranth;

// read more block
$read-more-background-color: $white;
$read-more-link-background-color: $teal;
$read-more-link-background-color--hover: darken($read-more-link-background-color, 5%);

// blockquote
$blockquote-background-color: $white;
$blockquote-text-color: $color-text;

// buttons
$btn-primary-color: $green;
$btn-primary-hover-color: $light-green;
$btn-primary-text: $blackberry-text;

$btn-primary-alternative-color: $teal;
$btn-primary-alternative-hover-color: $teal-80;
$btn-primary-alternative-text-color: $white;

$btn-secondary-color: $white;
$btn-secondary-hover-color: $blackberry-5;
$btn-secondary-text-color: $forest;
$btn-secondary-border-color: $forest;

$btn-danger-color: $color-error;
$btn-danger-hover-color: rgba($color-error, 0.8);
$btn-danger-disabled-color: rgba($color-error, 0.4);
$btn-danger-text-color: $white;

$btn-tertiary-text-hover-color: $teal;
$btn-tertiary-text-color: $forest;

$input-color: $ex-black;
$input-bg: $white;
$input-bg-disabled: $grayMedium;
$input-border-color: $blackberry-60;
$input-focus-border-color: $teal;
$input-checked-color: $teal;
$input-color-placeholder: $blackberry-60;
$input-box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.35),
    inset 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

$select-focus-box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(81, 167, 232, 0.5);

// Mobile CTA Form
$input-color-disabled: #9e9d9f;
$input-background-disabled: #ececec;

// icons

$icon-box-fill: $blackberry-80;
$icon-box-list-background-color: rgba($blackberry-10, 0.25);

$background-color-light: $white;
$onboarding_splash_page_text_color: $white;
$onboarding_splash_page_bg_color: $forest;

// design system box shadows

$elevation-3-shadow: 0px 6px 12px #00000026;
$elevation-5-shadow: 0px 10px 20px #00000026;
$elevation-7-shadow: 0px 14px 28px #00000026;

// Material Design box shadows for elevation
// Based on https://material-components.github.io/material-components-web-catalog/#/component/elevation
$md-elevation-1dp:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
$md-elevation-2dp:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
$md-elevation-3dp:
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
$md-elevation-4dp:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
$md-elevation-5dp:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
$md-elevation-6dp:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
$md-elevation-7dp:
    0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14),
    0 2px 16px 1px rgba(0, 0, 0, 0.12);
$md-elevation-8dp:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
$md-elevation-9dp:
    0 5px 6px -3px rgba(0, 0, 0, 0.2),
    0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12);
$md-elevation-10dp:
    0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14),
    0 4px 18px 3px rgba(0, 0, 0, 0.12);
$md-elevation-11dp:
    0 6px 7px -4px rgba(0, 0, 0, 0.2),
    0 11px 15px 1px rgba(0, 0, 0, 0.14),
    0 4px 20px 3px rgba(0, 0, 0, 0.12);
$md-elevation-12dp:
    0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12);
$md-elevation-13dp:
    0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14),
    0 5px 24px 4px rgba(0, 0, 0, 0.12);
$md-elevation-14dp:
    0 7px 9px -4px rgba(0, 0, 0, 0.2),
    0 14px 21px 2px rgba(0, 0, 0, 0.14),
    0 5px 26px 4px rgba(0, 0, 0, 0.12);
$md-elevation-15dp:
    0 8px 9px -5px rgba(0, 0, 0, 0.2),
    0 15px 22px 2px rgba(0, 0, 0, 0.14),
    0 6px 28px 5px rgba(0, 0, 0, 0.12);
$md-elevation-16dp:
    0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
$md-elevation-17dp:
    0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14),
    0 6px 32px 5px rgba(0, 0, 0, 0.12);
$md-elevation-18dp:
    0 9px 11px -5px rgba(0, 0, 0, 0.2),
    0 18px 28px 2px rgba(0, 0, 0, 0.14),
    0 7px 34px 6px rgba(0, 0, 0, 0.12);
$md-elevation-19dp:
    0 9px 12px -6px rgba(0, 0, 0, 0.2),
    0 19px 29px 2px rgba(0, 0, 0, 0.14),
    0 7px 36px 6px rgba(0, 0, 0, 0.12);
$md-elevation-20dp:
    0 10px 13px -6px rgba(0, 0, 0, 0.2),
    0 20px 31px 3px rgba(0, 0, 0, 0.14),
    0 8px 38px 7px rgba(0, 0, 0, 0.12);
$md-elevation-21dp:
    0 10px 13px -6px rgba(0, 0, 0, 0.2),
    0 21px 33px 3px rgba(0, 0, 0, 0.14),
    0 8px 40px 7px rgba(0, 0, 0, 0.12);
$md-elevation-22dp:
    0 10px 14px -6px rgba(0, 0, 0, 0.2),
    0 22px 35px 3px rgba(0, 0, 0, 0.14),
    0 8px 42px 7px rgba(0, 0, 0, 0.12);
$md-elevation-23dp:
    0 11px 14px -7px rgba(0, 0, 0, 0.2),
    0 23px 36px 3px rgba(0, 0, 0, 0.14),
    0 9px 44px 8px rgba(0, 0, 0, 0.12);
$md-elevation-24dp:
    0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);

/**
* ==================================================
*		Spacings
* ==================================================
*/

$spacing-sm: 1em;

$border-radius-standard: 3px;
$border-radius-callout: 4px;
$border-radius-card: 5px;
$border-radius-button: 30px;

/**
* ==================================================
*		Fonts
* ==================================================
*/
$text-size-base: 16px;
$line-height-base: 1.5;

$text-size-xs: 0.75rem; // 12px
$text-size-sm: 0.875rem; // 14px
$text-size-md: 1rem; // 16px
$text-size-lg: 1.125rem; // 18px
$text-size-xl: 1.5rem; // 24px
$text-size-xxl: 2.25rem; // 36px

$text-size-h1: 1.563rem; // this is hardcoded in places

$text-size-hero-lg: 3.5rem;
$text-size-hero-xl: 4.375rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/**
* ==================================================
*		Accessibility Related Variables
* ==================================================
*/
$button-normal-height: 34px;
$button-mobile-min-height: 4rem;
$link-height-mobile: 4rem; // 64px

$font-body: #{var(--font-family)};

/**
* ==================================================
*		Navigation Related Variables
* ==================================================
*/

/* --- Navigation -------------------- */
$nav-bg-color: $blackberry;
$nav-foreground-color: $white;
$nav-height: 60px;
$nav-height-mobile: 60px;
$nav-height-desktop: $nav-height-mobile;
$svg-size: 27px;
$nav-unified-button-height: 40px;
$nav-unified-button-width: $nav-unified-button-height;
$primary-btn-hover: #228796;
$nav-font-size: $text-size-sm;

/* --- Logo -------------------- */
$nav-logo-height-mobile: 27px;
$nav-logo-height-desktop: $nav-logo-height-mobile;
$nav-logo-width-mobile: 53px;
$nav-logo-width-desktop: $nav-logo-width-mobile;

/* --- Vision and Reason -------------------- */
$vandr-bg-color: transparent;
$vandr-widget-empty-border: none;
$vandr-widget-empty-border-radius: 0;
$vandr-widget-empty-heading-mb: revert;
$vandr-widget-empty-heading-mt: revert;
$vandr-widget-empty-pl-sm: 0;
$vandr-widget-bg-color: transparent;
$vandr-widget-border: none;
$vandr-widget-border-radius: 0;
$vandr-widget-before-height: 33px;
$vandr-widget-before-margin-left: -16px;
$vandr-widget-before-top: -15px;
$vandr-widget-before-width: 33px;
$vandr-form-textarea-overflow: auto;
$vandr-form-textarea-resize: auto;

$ib-vision-margin-top: 2rem;
$ib-vision-before-svg: svg-url($icon-inline-crystal-ball, $white, transparent, 28px, 28px);
$ib-vision-before-bg: $blackberry-80 2px 2px $ib-vision-before-svg no-repeat;
$ib-vision-before-bg-size: auto;
$vision-textarea-border-color: $input-border-color;
$vision-textarea-border-radius: $border-radius-standard;
$vision-textarea-border-style: solid;
$vision-textarea-border-width: 1px;

$ib-reason-li-top: initial;
$ib-reason-margin-top: $ib-vision-margin-top;
$ib-reason-before-svg: svg-url($icon-inline-dart-board, $white, transparent, 28px, 28px);
$ib-reason-before-bg: $truth-primary-alternate 2px 2px $ib-reason-before-svg no-repeat;
$ib-reason-sliderbtn-margin: -3rem;

/* --- Modals -------------------- */
$dialog-border-radius: 5px;
$popup-border-radius: $dialog-border-radius;
$popup-backdrop-opacity: inherit;

$check-in-popup-border-top: 0.7ex solid $truth-primary-variant;
