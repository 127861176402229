%banner-default {
    h1 {
        color: $white;
        font-size: 1.125rem;
        float: left;
        max-width: 45%;
        margin: 1em;
    }
    .cta-area {
        padding-bottom: 1rem;
        float: left;
        clear: left;
    }
    .btn {
        font-weight: 700;
    }

    @include breakpoint($screen-sm) {
        .cta-area {
            padding-bottom: 0;
        }
        .logo-area {
            padding-top: 1rem 0 40px 1rem;
            text-align: right;
            float: right;
            img {
                max-width: 65px;
            }
        }
        h1 {
            font-size: 1.563rem;
            max-width: 20em;
            margin: 2em 0 1.25em;
        }
    }
}

%banner-interior {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-color: $grayLight;
    padding: 0 1.25rem 1rem;

    &:after {
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -100%;
        left: 0;
        right: 0;
        height: 100%;
        width: 105%;
        transform-origin: 0;
        transform: rotate(-3.5deg);

        background-color: $white;
        content: "";
    }

    .logo-area {
        float: right;
        margin-top: 0;
        padding: 1.25rem 0 1.25rem 1rem;
        text-align: right;
        min-height: 120px;

        img {
            max-width: 50px;
        }
        @include breakpoint($screen-sm) {
            padding-right: 1rem;
            img {
                max-width: 65px;
            }
        }
    }
    @include breakpoint($screen-xs) {
        padding: 0 1.25rem 2.75rem;
    }
    @include breakpoint($screen-sm) {
        margin-bottom: 1rem;
        padding-bottom: 3.75rem;
        background-position: left;
    }

    @include breakpoint($screen-md) {
        padding-top: 1.25rem;
        padding-bottom: 6%;

        .logo-area {
            padding-bottom: 2.25rem;
        }
    }
    @include breakpoint(1600px) {
        padding-bottom: 10%;

        .logo-area {
            padding-bottom: 2.25rem;
        }
    }
}

%page-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    @include breakpoint($screen-sm) {
        background-position: left top;
    }
}

// Home page banner image
.banner-home,
.banner-registration {
    @extend %banner-default;
    @extend %banner-interior;
    background-size: cover;

    @include breakpoint($screen-xs) {
        background-size: cover;
    }
}
.banner-home,
.banner-registration {
    padding-bottom: 1.5rem;
    @include breakpoint($screen-xs) {
        background-position: center bottom;
    }
    @include breakpoint($screen-sm) {
        min-height: 280px;
    }
    @include breakpoint($screen-md) {
        background-size: cover;
        min-height: 280px;
    }
    @include breakpoint($screen-lg) {
        min-height: 340px;
    }
    h1 {
        text-shadow:
            -1px -1px 0 $color-text,
            1px -1px 0 $color-text,
            -1px 1px 0 $color-text,
            1px 1px 0 $color-text;
    }
}
.banner-registration {
    h1 {
        max-width: 5em;

        @include breakpoint($screen-xs) {
            max-width: 13em;
        }
    }
}
.banner-img {
    @extend %banner-interior;
}

// Corner background images
.corner-graphic {
    background-repeat: no-repeat;
    background-position: top right;
    padding-bottom: 1rem;

    @include breakpoint($screen-sm) {
        padding-bottom: 3rem;
        padding-top: 3rem;
        background-size: 350px 257px;
    }
    @include breakpoint($screen-md) {
        background-size: 500px 367px;
    }
}

.cta-banner {
    background-color: $cta-primary-bg-color;
    margin-top: 2rem;
    padding: 0 0 3rem;
    text-align: center;

    p {
        color: $white;
        font-size: 1.75rem;
        margin-top: 0.5rem;
    }
}

// Background photo strips
.bg-photo-strip {
    background-repeat: no-repeat;
    background-size: cover;
    margin: 3rem 0 0;
    padding: 1rem;

    & + div {
        padding-top: 3rem;
    }
    & + div.icon-list-block {
        margin-top: 0;
    }

    & > .container {
        background-color: rgba($white, 0.85);
        padding: 0.5rem 1.5rem;
    }

    @include breakpoint($screen-md) {
        padding: 4rem 0;

        & > .container {
            padding: 1.5rem 3rem 2rem;
        }
    }
}

.site-wide-notice {
    background: $pastel-yellow;
    .btn {
        gap: 1rem;
        .close_image {
            height: 20px;
            width: 20px;
            transition: all 0.2s;
        }
        &:hover {
            .close_image {
                fill: $truth-teal;
            }
        }
    }
}
